import React from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlineStock } from "react-icons/ai";
import { MdOutlineRealEstateAgent, MdCurrencyRupee } from "react-icons/md";

const SeachBuyLead = () => {
  return (
    <>
      <Navbar />
      <Container className="my-3 p-3 bg-light rounded-lg shadow-sm">
        <Row style={{ gap: "20px" }}>
          <Col>
            <p className="my-3 ">
              <span style={{ fontWeight: "500", color: "#2b5c77" }}>
                Sell for Free{" "}
              </span>
              on India's Largest online Marketplace
            </p>
          </Col>
          <Col className="text-center">
            <AiOutlineStock className="m-auto my-2 display-6" />
            <p style={{ fontWeight: "500", color: "#2b5c77" }}>
              Grow your Business
            </p>
          </Col>
          <Col className="text-center">
            <MdCurrencyRupee className="m-auto my-2 display-6" />
            <p style={{ fontWeight: "500", color: "#2b5c77" }}>Zero Cost</p>
          </Col>
          <Col className="text-center">
            <MdOutlineRealEstateAgent className="m-auto my-2 display-6" />
            <p style={{ fontWeight: "500", color: "#2b5c77" }}>
              Manage Business Easily
            </p>
          </Col>
          <Col className="text-center m-auto rounded-sm">
            <button className="px-4 btn btn-primary">Dashboard</button>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default SeachBuyLead;
