import React, { useEffect } from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import ISObanner from "../images/iso.jpg";

export const BWTISOCertification = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />
      <div
        className="container-fluid contactus-banner gap py-5 mb-4 img-fluid responsive-banner"
        style={{
          backgroundImage: `url(${ISObanner})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center ",
        }}
      >
        <div className="row py-5" style={{ paddingLeft: "55%" }}>
          <div className="col-12 text-center text-light pt-3">
            <h5
              style={{
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              ISO Certification by
            </h5>
          </div>
          <div className="col-12 text-center text-light pb-3 banner-head">
            <h1
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "800 ",
                color: "white",
              }}
            >
              {" "}
              Business World Trade{" "}
            </h1>
          </div>
        </div>
      </div>
      {/* <h3 className="text-center my-3 privacy-policy">
        Bussiness World Trade <br></br>ISO Certification
      </h3> */}
      <div className="privacy-policy-content">
        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "21px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Introduction
          </span>
          <br></br> Business World Trade takes immense pride in our role as a
          prominent player in the B2B industry. As a trusted partner for
          businesses around the globe,<br></br> we understand the significance
          of maintaining the highest standards of quality, efficiency, and
          reliability. In our pursuit of excellence, we are actively seeking{" "}
          <br></br>ISO certification to demonstrate our unwavering commitment to
          meeting international standards.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "21px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            ISO Certification
          </span>
          <br></br>ISO certification serves as a testament to our dedication to
          providing exceptional B2B services and signifies our adherence to
          internationally recognized <br></br>standards. Through ISO
          certification, we showcase our ability to consistently deliver
          superior products, services, and customer experiences, solidifying
          our,<br></br> position as a reliable partner in the global trade
          landscape.
          <p
            style={{
              textAlign: "left",
              color: "grey",
              fontSize: "17px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "rgb(65, 65, 65)",
              }}
            >
              Compliance with ISO Standards:
            </span>
            <br></br>
            At Business World Trade, we have invested significant efforts in
            aligning our operations with the ISO standard.. We have meticulously
            evaluated our business<br></br> processes, identified areas for
            improvement, and implemented stringent systems to ensure strict
            compliance with the ISO requirements. Our commitment to<br></br>{" "}
            adhering to ISO standards enables us to continually enhance our
            operations and meet the evolving needs of our B2B clients.
          </p>
          <p
            style={{
              textAlign: "left",
              color: "grey",
              fontSize: "17px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "rgb(65, 65, 65)",
              }}
            >
              Quality Management:
            </span>
            <br></br>
            Quality management lies at the core of our business philosophy. We
            have implemented a comprehensive framework to monitor, assess, . and
            improve the <br></br>quality of our products and services. By
            aligning with ISO standards, we guarantee that our B2B partners
            receive nothing short of outstanding quality, efficiency, and
            reliability in every aspect of our engagement. Our commitment to
            quality is deeply ingrained in our company culture and is evident in
            our unwaveringpursuit of excellence.
          </p>
          <p
            style={{
              textAlign: "left",
              color: "grey",
              fontSize: "17px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "rgb(65, 65, 65)",
              }}
            >
              Environmental Management (if applicable):
            </span>
            <br></br>
            At Business World Trade, we recognize the importance of
            environmental sustainability in today's business landscape. As part
            of our ISO certification journey, <br></br>
            we have implemented robust environmental management practices to
            minimize our ecological impact. We actively monitor and reduce our
            energy <br></br>consumption, promote responsible waste management,
            and comply with all applicable environmental regulations. By
            incorporating sustainable practices,<br></br> we demonstrate our
            dedication to preserving the environment for future generations.
          </p>
          <p
            style={{
              textAlign: "left",
              color: "grey",
              fontSize: "17px",
            }}
          >
            <span
              style={{
                fontSize: "18px",
                fontWeight: "500",
                color: "rgb(65, 65, 65)",
              }}
            >
              Information Security (if applicable):
            </span>
            <br></br>
            In an era of heightened digital threats, safeguarding sensitive
            information is of paramount importance. At Business World Trade, we
            have established stringent information security management systems
            to protect the confidentiality, integrity, and availability of data
            entrusted to us by our B2B partners. By adhering to ISO standards,
            we demonstrate our commitment to maintaining the highest levels of
            information security, ensuring that our clients' valuable data
            remains secure at all times.
          </p>
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "21px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Conclusion
          </span>
          <br></br>
          Achieving ISO certification as Business World Trade showcases our
          relentless pursuit of excellence, commitment to international
          standards, and dedication <br></br>to our B2B partners. This
          certification serves as a testament to our ability to deliver
          exceptional services, uphold superior quality standards, and operate
          with <br></br>the utmost integrity. By choosing Business World Trade,
          our B2B partners gain the confidence and assurance that they are
          collaborating with a trusted,<br></br> ISO-certified organization that
          continually strives to exceed expectations.
        </p>
      </div>
      <Footer />
    </>
  );
};
