import React from "react";
import { NavLink } from "react-router-dom";

function SellerHeader() {
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="pushmenu"
            href="/menu"
            role="button"
          >
            <i className="fas fa-bars"></i>
          </a>
        </li>
        <li className="nav-item">
          <NavLink
            exact="true"
            to="/"
            className="nav-link"
            activeclassname="active-link"
          >
            Home
          </NavLink>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a
            className="nav-link"
            data-widget="fullscreen"
            href="/menu"
            role="button"
          >
            <i className="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li className="nav-item">
          <button className="btn btn_Log_out">Log Out</button>
        </li>
      </ul>
    </nav>
  );
}

export default SellerHeader;
