import React, { useEffect, useState } from "react";
import "./Footer.css";
import logo from "../images/bwt-dark.png";
import { FaTelegram } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const [formData, setFormData] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState(null);
  const [subCategories, setSubCategories] = useState([]);

  const style = {
    textDecoration: "none",
    color: "#5a5a5a",
    fontWeight: 400,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo.businessworldtrade.com/api/getcategory"
        );
        const allCategories = response.data;

        // Filter only the first five categories
        const filteredCategories = allCategories.slice(0, 5);

        setCategories(filteredCategories);
        // console.log("Fetched Categories:", filteredCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchData();
  }, []);

  const handleClick = (categoryId, categoryName) => {
    navigate(`/category/${categoryId}/${categoryName}`);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/newsletter", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));

    setFormData({});
    console.log(formData);
  };

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        // Check if categoryId is defined
        if (categoryId) {
          const response = await axios.get(
            `https://demo.businessworldtrade.com/api/getcategory/${categoryId}`
          );
          const { category, subcategories } = response.data;

          setCategoryData(category);
          setSubCategories(subcategories);
          // console.log("Category Data:", category);
          // console.log("Subcategories:", subcategories);
        } else {
          // Handle the case where categoryId is not defined
          // You might want to set default category data or display an error message
          console.error("Category ID is undefined.");
        }
      } catch (error) {
        // Handle other errors
        // console.error("Error fetching category data:", error);
      }
    };

    fetchCategoryData();
  }, [categoryId]);

  return (
    <div className="footer bg-light">
      <div className="footer-top-section ">
        <img src={logo} alt="name" style={{ width: "250px", height: "70px" }} />

        <div className="newsletter-section">
          <h5
            style={{
              textAlign: "left",
              fontWeight: "600",
              paddingTop: "5px",
              fontSize: "23px",
            }}
          >
            Newsletter
          </h5>

          <div className="newsletter-section-div">
            <input
              type="email"
              name="email"
              value={formData.email || ""}
              onChange={handleChange}
              placeholder="Email Address"
            />
            <button type="submit" onClick={handleSubmit}>
              <FaTelegram
                style={{ fontSize: "40px", color: "#ff6a00" }}
                className="ms-1"
              />{" "}
            </button>
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-center w-100 ">
        <hr></hr>
      </div>

      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links_div">
            <h4>ABOUT US</h4>
            <Link to="/about-us" style={style}>
              <p>About Us</p>
            </Link>
            <Link to="/career" style={style}>
              <p>Career</p>
            </Link>
            <Link to="/faq" style={style}>
              <p>FAQ</p>
            </Link>
            <Link to="/privacy-policy" style={style}>
              <p>Privacy & Policy</p>
            </Link>
            <Link to="/contact-us" style={style}>
              <p>Contact Us</p>
            </Link>
            <Link to="/blog" style={style}>
              <p>Blog</p>
            </Link>
          </div>

          <div className="sb__footer-links_div">
            <h4>OUR SERVICES</h4>
            <Link to="/advertise-with-us" style={style}>
              <p>Advertise With Us</p>
            </Link>
            <Link to="/loan" style={style}>
              <p>Business Loan</p>
            </Link>
            <Link to="/insurance" style={style}>
              <p>Business Insurance</p>
            </Link>
            <Link to="/iso-certification" style={style}>
              <p>ISO Certification</p>
            </Link>
            <Link to="/e-filling" style={style}>
              <p>E-Filing</p>
            </Link>
            <Link to="/fta" style={style}>
              <p>FTA</p>
            </Link>
          </div>

          {/* // ********************categorirs*********************** */}

          <div className="sb__footer-links_div">
            <h4>CATEGORIES</h4>

            {categories.map((cate) => (
              <div key={cate._id}>
                <li
                  key={cate._id} // Use _id instead of id
                  onClick={() => handleClick(cate._id, cate.name)} // Pass _id and name as parameters
                  className="list-unstyled footer-categories-style"
                  style={{
                    cursor: "pointer",
                    color: "#5a5a5a",
                    margin: "-5px 0px",
                  }}
                >
                  <p> {cate.name}</p>
                </li>
              </div>
            ))}
            <Link to={"/categories"}>
              <div
                className="mb-4 footer-categories-view-all fw-bold"
                style={{ color: "#5a5a5a" }}
              >
                View All
              </div>
            </Link>
          </div>

          <div className="sb__footer-links_div">
            <h4>BUYER</h4>
            <Link to="/post-by-requirement" style={style}>
              <p>Post By Requirement</p>
            </Link>
            <Link to="/search-supplier" style={style}>
              <p>Search Supplier</p>
            </Link>
            <Link to="/request-callback" style={style}>
              <p>Request Callback</p>
            </Link>
          </div>
          <div className="sb__footer-links_div">
            <h4>SELLER</h4>
            <a href="/" style={style}>
              <p>Search Buy Leads</p>
            </a>
            <Link to="/freight-quotes" style={style}>
              <p>Get Frieght Quotes</p>
            </Link>
            <Link to="/logistics" style={style}>
              <p>Logistics</p>
            </Link>
            <Link to="/seller-iso-certification" style={style}>
              <p>ISO Certification</p>
            </Link>
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-center w-100">
        <hr></hr>
      </div>
      <footer className="bg-light text-center text-white">
        <div className="container p-4 pb-0">
          <section className="mb-4">
            <a
              className="btn text-white btn-floating m-1 rounded"
              style={{ backgroundColor: "#3b5998" }}
              href="https://www.facebook.com/infobizbusinessworldtrade?mibextid=LQQJ4d"
              role="button"
            >
              <i className="fab fa-facebook-f fa-beat"></i>
            </a>

            <a
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "#55acee" }}
              href="https://twitter.com/BusinessWorldT2"
              role="button"
            >
              <i className="fab fa-twitter fa-beat"></i>
            </a>

            <a
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "#dd4b39" }}
              href="https://youtube.com/@businessworldtrade5507"
              role="button"
            >
              <i className="fab fa-youtube fa-beat"></i>
            </a>

            <a
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: "#ac2bac" }}
              href="https://instagram.com/businessworldtrade?igshid=MzRIODBiNWFIZA=="
              role="button"
            >
              <i className="fab fa-instagram fa-beat"></i>
            </a>

            <a
              className="btn text-white btn-floating m-1"
              style={{ backgroundColor: " #0082ca" }}
              href="https://www.linkedin.com/company/77342141/admin/"
              role="button"
            >
              <i className="fab fa-linkedin-in fa-beat"></i>
            </a>

            <a
              className="btn text-white btn-floating m-1 beat-on-hover"
              style={{ backgroundColor: "#40E0D0" }}
              href="https://in.pinterest.com/BusinessWorldTrade/_saved/"
              role="button"
            >
              <i
                className="fab fa-pinterest fa-beat"
                style={{ color: "red" }}
              ></i>
            </a>
          </section>
        </div>
      </footer>
      <div className=" d-flex justify-content-center w-100">
        <hr></hr>
      </div>

      <div className="sb__footer-below">
        <div className="sb__footer-copyright" style={{ marginTop: "14px" }}>
          <p>
            @{new Date().getFullYear()} Business World Trade. All rights
            reserved.
          </p>
        </div>

        <div className="sb__footer-below-links" style={{ marginTop: "14px" }}>
          <Link to="./" style={style}>
            <div>
              <p>Terms & Conditions</p>
            </div>
          </Link>
          <Link to="/privacy-policy" style={style}>
            <div>
              <p>Privacy Policy</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
