import React, { useEffect, useState } from "react";
import {
  Box,
  Image,
  Stack,
  Text,
  Button,
  Spacer,
  Flex,
  Select,
  Input,
  Textarea,
  Center,
  AlertIcon,
  Alert,
  IconButton,
  InputRightElement,
  InputLeftElement,
  InputGroup,
  // InputLeftElement,
} from "@chakra-ui/react";
import { DeleteIcon, CheckIcon } from "@chakra-ui/icons";
import { getCategory, AddProductactions } from "../action/productAction";
import { useSelector, useDispatch } from "react-redux";
// import { useState } from "react";
// import { AddProductactions } from "../action/productAction";

const AddProduct = () => {
  const dispatch = useDispatch();
  const [productCategory, setProductCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [lastSubCategory, setLastSubCategory] = useState("");
  const [name, setname] = useState([]);
  const [countryOrigin, setcountryOrigin] = useState([]);
  const [Color, setColor] = useState([]);
  const [packagetype, setpackagetype] = useState([]);
  const [Size, setSize] = useState([]);
  const [Minimum, setMinimum] = useState([]);
  const [category, setcategory] = useState([]);
  const [price, setprice] = useState([]);
  const [short_des, setshort_des] = useState([]);
  const [brief_des, setbrief_des] = useState([]);
  const [fileName, setFileName] = useState([]);

  // const [fileName, setFileName] = useState([]);
  // for text area in a form
  // let [value, setValue] = React.useState("");

  const defaultImage = "https://dummyimage.com/252x252/000/fff";

  const handlePicture = (event) => {
    if (fileName.length >= 4) {
      alert("You can only upload a maximum of 4 images.");
      return;
    }

    setFileName([...fileName, URL.createObjectURL(event.target.files[0])]);
    const newFile = event.target.files[0];
    const newFileName = URL.createObjectURL(newFile);
    setFileName([...fileName, newFileName]);
  };

  // *****************************
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create a new product object with the form field values
    const newProduct = {
      productCategory,
      subCategory,
      subSubCategory,
      lastSubCategory,
      name,
      countryOrigin,
      Color,
      packagetype,
      Size,
      Minimum,
      category,
      price,
      short_des,
      brief_des,
      images: fileName.filter(Boolean),
    };
    console.log(newProduct);

    // Dispatch an action to add the product to the Redux store
    dispatch(AddProductactions());

    // Reset the form fields
    setProductCategory("");
    setSubCategory("");
    setSubSubCategory("");
    setLastSubCategory("");
    setname("");
    setcountryOrigin("");
    setColor("");
    setpackagetype("");
    setSize("");
    setMinimum("");
    setcategory("");
    setprice("");
    setshort_des("");
    setbrief_des("");
    setFileName([]);
  };

  // *****************************

  const categorylists = useSelector((state) => state.products.categorylist);
  useEffect(() => {
    dispatch(getCategory());
  }, [dispatch]);

  console.log(categorylists);

  const handleProductCategorySelect = (e) => {
    setProductCategory(e.target.value);
    setSubCategory("");
    setSubSubCategory("");
    setLastSubCategory("");
  };

  const handleSubCategorySelect = (e) => {
    setSubCategory(e.target.value);
    setSubSubCategory("");
    setLastSubCategory("");
  };

  const handleSubSubCategorySelect = (e) => {
    setSubSubCategory(e.target.value);
    setLastSubCategory("");
  };

  const handleLastSubCategorySelect = (e) => {
    setLastSubCategory(e.target.value);
  };

  // const handlepicture = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();
  //   reader.onloadend = () => {
  //     setFileName((prevFileNames) => [...prevFileNames, reader.result]);
  //   };
  //   reader.readAsDataURL(file);
  // };
  return (
    <>
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <Flex bg="white" p={2} borderBottom="1px" borderBottomColor="#c3c3c3">
            <Box>
              <Text
                style={{
                  fontSize: "25px",
                  fontWeight: "500",
                  color: "#0e5176",
                }}
              >
                Add Products
              </Text>
            </Box>
            <Spacer />
            <Box>
              <Button
                // isLoading
                colorScheme="green"
                variant="solid"
                onClick={handleSubmit}
                // loadingText="Submitting"
              >
                Save Product
              </Button>
            </Box>
          </Flex>

          {/* main box */}
          <Alert status="success" variant="subtle">
            <AlertIcon />
            Product Added Successfully :)
          </Alert>
          <div className="mt-4">
            <Stack
              direction={{ base: "column", md: "row", sm: "column" }}
              mx={3}
            >
              <Box direction="column" margin="auto">
                <Image
                  boxSize="252px"
                  src={fileName.length > 0 ? fileName[0] : defaultImage}
                  alt="Product"
                />

                <Stack my={2} display="flex" direction="row" spacing={1}>
                  {[...Array(4)].map((_, id) => (
                    <Flex key={id}>
                      <Image
                        boxSize="60px"
                        objectFit="cover"
                        src={fileName[id] || defaultImage}
                        alt="Product"
                      />
                      <IconButton
                        title="Delete"
                        aria-label="delete"
                        style={{
                          background: "transparent",
                          position: "absolute",
                          marginLeft: "32px",
                          marginTop: "30px",
                        }}
                        isDisabled={!fileName[id]}
                        icon={
                          <DeleteIcon
                            color="red.600"
                            onClick={() => {
                              const newFileName = [...fileName];
                              newFileName[id] = null;
                              setFileName(newFileName);
                            }}
                          />
                        }
                      />
                    </Flex>
                  ))}
                </Stack>
                <Stack spacing={4} marginTop={2}>
                  <Input
                    id="Addproduct-btn"
                    type="file"
                    hidden
                    onChange={handlePicture}
                    name="images"
                  />
                  <label htmlFor="Addproduct-btn" className="addproduct-btn">
                    Choose Product Images <i className="fa-solid fa-upload"></i>
                  </label>
                </Stack>
              </Box>

              <Box>
                <Stack
                  //   onChange={handleChange}
                  spacing={4}
                  direction={{ base: "column", md: "row", sm: "column" }}
                >
                  <Select
                    fontSize={13}
                    variant="outline"
                    bg="white"
                    placeholder="Select Product Category"
                    value={productCategory}
                    onChange={handleProductCategorySelect}
                    isDisabled={false}
                  >
                    {categorylists &&
                      categorylists.map((category) => (
                        <option key={category._id} value={category._id}>
                          {category.name}
                        </option>
                      ))}
                  </Select>
                  <Select
                    fontSize={13}
                    variant="outline"
                    bg="white"
                    placeholder="Select Sub Category"
                    value={subCategory}
                    onChange={handleSubCategorySelect}
                    isDisabled={!productCategory}
                  >
                    {productCategory &&
                      categorylists
                        .find((category) => category._id === productCategory)
                        .children.map((subcategory) => (
                          <option key={subcategory._id} value={subcategory._id}>
                            {subcategory.name}
                          </option>
                        ))}
                  </Select>
                  <Select
                    fontSize={13}
                    variant="outline"
                    bg="white"
                    placeholder="Select Sub Sub Category"
                    value={subSubCategory}
                    onChange={handleSubSubCategorySelect}
                    isDisabled={!subCategory}
                  >
                    {subCategory &&
                      categorylists
                        .find((category) => category._id === productCategory)
                        .children.find(
                          (subcategory) => subcategory._id === subCategory
                        )
                        .children.map((subsubcategory) => (
                          <option
                            key={subsubcategory._id}
                            value={subsubcategory._id}
                          >
                            {subsubcategory.name}
                          </option>
                        ))}
                  </Select>
                </Stack>

                <Stack
                  spacing={4}
                  direction={{ base: "column", md: "row", sm: "column" }}
                  my={6}
                >
                  <Select
                    fontSize={13}
                    variant="outline"
                    bg="white"
                    placeholder="Select Last Sub Category"
                    value={lastSubCategory}
                    onChange={handleLastSubCategorySelect}
                    isDisabled={!subSubCategory}
                  >
                    {subSubCategory &&
                      categorylists &&
                      categorylists
                        .find((category) => category._id === productCategory)
                        .children.find(
                          (subcategory) => subcategory._id === subCategory
                        )
                        .children.find(
                          (subsubcategory) =>
                            subsubcategory._id === subSubCategory
                        )
                        .children.map((lastsubcategory) => (
                          <option
                            key={lastsubcategory._id}
                            value={lastsubcategory._id}
                          >
                            {lastsubcategory.name}
                          </option>
                        ))}
                  </Select>

                  <Input
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    placeholder="Enter Product Name"
                    name="name"
                    onChange={(e) => setname(e.target.value)}
                    size="md"
                  />
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      color="gray.300"
                      fontSize="1.2em"
                      children="₹"
                    />
                    <Input
                      fontSize={13}
                      _placeholder={{ color: "black" }}
                      size="md"
                      placeholder="Enter Price"
                      bg="white"
                      onChange={(e) => setprice(e.target.value)}
                    />
                  </InputGroup>
                  <Select
                    variant="outline"
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    placeholder="Select Unit"
                  />
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  <Input
                    variant="outline"
                    _placeholder={{ color: "black" }}
                    fontSize={13}
                    bg="white"
                    placeholder="Select Size"
                    name="Size"
                    onChange={(e) => setSize(e.target.value)}
                  />
                  <Select
                    variant="outline"
                    fontSize={13}
                    bg="white"
                    name="packagetype"
                    onChange={(e) => setpackagetype(e.target.value)}
                    placeholder="Packaging type"
                  >
                    <option>Physical</option>
                    <option>Other</option>
                  </Select>
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  <Input
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    placeholder="Color"
                    size="md"
                    name="Color"
                    onChange={(e) => setColor(e.target.value)}
                  />
                  <Input
                    variant="outline"
                    _placeholder={{ color: "black" }}
                    fontSize={13}
                    name="countryOrigin"
                    onChange={(e) => setcountryOrigin(e.target.value)}
                    bg="white"
                    placeholder="Counrty of Origin"
                  />
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  <Input
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    name="Minimum "
                    onChange={(e) => setMinimum(e.target.value)}
                    placeholder="Minimum Order Quantity"
                    size="md"
                  />
                  <Select
                    variant="outline"
                    fontSize={13}
                    bg="white"
                    placeholder="Product Stock"
                  >
                    {" "}
                    <option>In Stock</option>
                    <option>Out Of Stock</option>
                  </Select>
                </Stack>
              </Box>
            </Stack>
            <Stack p={4}>
              <Text mb="4px" fontSize="bold" fontWeight="600">
                {/* Write Short Description about your product: {value} */}
              </Text>
              <Textarea
                bg="white"
                onChange={(e) => setshort_des(e.target.value)}
                placeholder="Write here something"
                size="sm"
                name="short_des"
              />
            </Stack>
            <Stack p={4}>
              <Text mb="4px" fontSize="bold" fontWeight="600">
                {/* Write Brief Description about your product: {value} */}
              </Text>

              <Textarea
                bg="white"
                onChange={(e) => setbrief_des(e.target.value)}
                placeholder="Write here something to describe your product"
                size="sm"
                name="brief_des"
              />
            </Stack>

            <Stack p={4}>
              <Center>
                <Button
                  colorScheme="green"
                  variant="solid"
                  // loadingText="Submitting"
                  // isLoading
                >
                  Save Product
                </Button>
              </Center>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProduct;
