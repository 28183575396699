import React, { useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap"; // Import Bootstrap components

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "superAdmin",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Send the formData to your backend for registration
    // You can use fetch or Axios for this purpose
    console.log(formData);
  };

  return (
    <Container>
      <Row>
        <Col md={5} className="m-auto">
          <h2 className="mt-5 mb-3">Registration Form</h2>
          <Form
            onSubmit={handleSubmit}
            style={{
              border: "1px solid rgb(204, 204, 204)",
              boxShadow: "rgba(0, 0, 2, 0.1) 0px 2px 4px",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Select
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
              >
                <option value="superAdmin">Super Admin</option>
                <option value="subSuperAdmin">Sub Super Admin</option>
                <option value="subAdmin">Sub Admin</option>
              </Form.Select>
            </Form.Group>
            <Button variant="primary" type="submit">
              Register
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default RegistrationForm;
