import React from "react";
import "bootstrap";
import TopSupplier from "./TopSupplier";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const TopSuppliers = () => {
  const topSupplierImages = [
    "one.jpeg",
    "two.jpeg",
    "three.jpeg",
    "four.jpeg",
    "five.jpeg",
    "six.jpeg",
    "seven.jpeg",
    "eight.jpeg",
    "nine.jpeg",
    "ten.jpeg",
    "eleven.jpeg",
    "twelve.jpeg",
    "thirteen.jpeg",
    "forteen.jpeg",
    "fifteen.jpeg",
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const productList = topSupplierImages.map((image, index) => (
    <TopSupplier key={index} url={require(`../images/topSup/${image}`)} />
  ));

  return (
    <div>
      <h1 className="mt-5 mb-3" style={{ color: "#444444", fontWeight: "700" }}>
        Top Supplier
      </h1>
      <Carousel responsive={responsive}>{productList}</Carousel>
    </div>
  );
};

export default TopSuppliers;
