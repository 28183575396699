import React, { useState, useEffect } from "react";
import { GoComment } from "react-icons/go";
import { AiOutlineDelete } from "react-icons/ai";
import axios from "axios";

function PopupCallInquiries() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://demo.businessworldtrade.com/api/getCallback"
      );

      setData(response.data);
      console.log(data.name);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = (callbackId) => {
    axios
      .delete(`https://demo.businessworldtrade.com/api/callbacks/${callbackId}`)
      .then((response) => {
        console.log("User deleted successfully:", response.data.message);
        setData((prevData) =>
          prevData.filter((item) => item._id !== callbackId)
        );
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with an error status code
          console.error("Error deleting user:", error.response.data.error);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received from the server.");
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error setting up the request:", error.message);
        }
      });
  };

  return (
    <>
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper ">
          <div className="adminSubNav">
            <h3 className="admin-nav-titlee">PopUp and Call Inquiries</h3>
          </div>
          <hr />

          <div className="table-section">
            <div className="tablenav">
              <div className="sorting ml-2">
                <label>
                  Show{" "}
                  <select
                    name="example_length"
                    aria-controls="example"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div className="tableSearchbar mb-3 mr-2">
                <form className="d-flex " role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-primary" type="submit">
                    Search
                  </button>
                </form>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead className="text-center">
                  <tr>
                    <th scope="col">S.NO</th>
                    <th scope="col">USERNAME</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">MOBILE</th>
                    <th scope="col">MESSAGE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">DATE/TIME</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className="text-center px-2">
                      <th scope="row">{index + 1}</th>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.message}</td>
                      <td>
                        <div className="form-check form-switch d-flex justify-content-center ps-5">
                          <input
                            className="form-check-input btn-lg "
                            type="checkbox"
                            id="flexSwitchCheckChecked"
                          />
                        </div>
                      </td>
                      <td>{item.createdAt}</td>
                      <td>
                        <div className="buttonsEnq">
                          <AiOutlineDelete
                            size={18}
                            className="btn btn-danger btn-sm p-0"
                            title="Delete"
                            onClick={() => handleDelete(item._id)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PopupCallInquiries;
