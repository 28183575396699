import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";
import axios from "axios";

const FoodBeverages = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleEnquiryChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/createInquries", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));
    handleCloseModal();
    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };
  const titleOne = props.name.split(" ");

  return (
    <div
      className="card"
      style={{
        borderRadius: "20px",
        width: "85%",
        margin: "auto",
        boxShadow:
          "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
      }}
    >
      <img
        style={{
          width: "80%",
          height: "220px",
          padding: "20px",
          borderRadius: "20px 20px 0px 0px",
          margin: "auto",
        }}
        className="product--image"
        src={props.img}
        alt={props.name}
      />
      <h2 style={{ fontSize: "24px", textAlign: "center" }}>
        {titleOne.length >= 1
          ? titleOne[0] + " " + titleOne[1] + " ..."
          : "error"}
      </h2>
      <p style={{ color: "gray", textAlign: "center" }}>
        Sold by: {props.soldBy}
      </p>
      <p style={{ textAlign: "center" }}>{props.desc}</p>
      <p style={{ textAlign: "center" }}>
        <button
          style={{
            marginBottom: "20px",
            padding: "5px 10px",
            borderRadius: "10px",
            borderColor: "transparent",
            boxShadow: "rgb(14, 81, 118) 0px 10px 10px -10px",
            fontSize: "15px",
          }}
          onClick={handleOpenModal}
        >
          Get Enquiry
        </button>
      </p>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Get Enquiry for {props.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-1">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name || ""}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email || ""}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone || ""}
                onChange={handleEnquiryChange}
              />
            </div>

            <Button variant="primary" type="submit">
              Send Enquiry
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FoodBeverages;
