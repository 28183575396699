import React, { useState } from "react";
import { Container, Form, Row, Col, Button } from "react-bootstrap";
import axios from "axios";
import { useAuth } from "../context/AuthCotext"; // Import the useAuth and useUser hooks
import Navbar from "../Components/Homepage/Navbar";
import Footer from "../Components/Homepage/Footer";
const CatalogueForm = () => {
  const { accessToken } = useAuth(); // Get the accessToken from the context
  // Get the userRole from the context

  const [bannerImages, setBannerImages] = useState("");
  const [profileVideo, setProfileVideo] = useState("");
  const [isoCertification, setIsoCertification] = useState("");
  const [website, setWebsite] = useState("");
  const [companyProfile, setCompanyProfile] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    // Update the corresponding state based on the input field's name
    if (name === "isoCertification") {
      setIsoCertification(value);
    } else if (name === "website") {
      setWebsite(value);
    } else if (name === "companyProfile") {
      setCompanyProfile(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Prepare the data object to be sent to the server
    const formData = {
      bannerImages: bannerImages,
      profileVideo: profileVideo,
      isoCertification: isoCertification,
      website: website,
      companyProfile: companyProfile,
    };

    // Send the data to the server with the access token in headers
    axios
      .post(
        "https://demo.businessworldtrade.com/api/createContactProfile",
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        console.log("Catalog created:", response);
        // Handle success, such as showing a success message to the user
      })
      .catch(function (error) {
        console.log("Error creating catalog:", error);
        // Handle error, such as showing an error message to the user
      });
  };

  const handleFileChange = () => {};
  return (
    <>
      <Navbar />
      <h1
        className="my-2"
        style={{ fontFamily: "Poppins", textDecoration: "underline" }}
      >
        Catalogue Form
      </h1>
      <Container className="my-4">
        <Form onSubmit={handleSubmit}>
          <Row className="px-2 px-md-5 g-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="bannerImages" className="mb-3">
                <Form.Label>Select Banner Images</Form.Label>
                <Form.Control
                  type="file"
                  name="bannerImages"
                  value=""
                  onChange={handleFileChange}
                  multiple
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="px-2 px-md-5">
            <Col xs={12} md={6}>
              <Form.Group controlId="profileVideo" className="mb-3">
                <Form.Label>Select Profile Video</Form.Label>
                <Form.Control
                  type="file"
                  name="profileVideo"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="isoCertification">
                <Form.Label>ISO Certification</Form.Label>
                <Form.Control
                  type="text"
                  name="isoCertification"
                  placeholder="Enter ISO certification (if any)"
                  value={isoCertification}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="px-2 px-md-5 g-3">
            <Col xs={12} md={6}>
              <Form.Group controlId="website" className="mb-3">
                <Form.Label>Company's Website</Form.Label>
                <Form.Control
                  type="text"
                  name="website"
                  placeholder="Enter Link"
                  value=""
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3" controlId="companyProfile">
                <Form.Label>Company Profile</Form.Label>
                <Form.Control
                  as="textarea"
                  name="companyProfile"
                  placeholder="About Company"
                  style={{ height: "100px" }}
                  value=""
                  onChange={handleInputChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <div>
            <Button
              className="btn btn-primary d-block mx-auto mt-2"
              type="submit"
            >
              Create Catalog
            </Button>
          </div>
        </Form>
      </Container>
      <Footer />
    </>
  );
};

export default CatalogueForm;
