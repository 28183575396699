import React, { useEffect, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import axios from "axios";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { Button, Modal } from "react-bootstrap";

const TotalRegisterUser = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    // Initialize form data with empty values or default values
    name: "",
    email: "",
    phone: "",
    role: "",
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://demo.businessworldtrade.com/api/Getalluser")
      .then((response) => {
        setData(response.data.users);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleOpenModal = (user) => {
    setSelectedProduct(user);
    setShowModal(true);
    setFormData({
      name: user.name,
      email: user.email,
      phone: user.phone,
      role: user.role,
    });
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowModal(false);
  };

  const handleEnquiryChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log("Form Data Updated:", formData);
  };

  const handleDelete = (userId) => {
    axios
      .delete(`http://localhost:5000/api/deleteUser/${userId}`)
      .then((response) => {
        console.log("User deleted successfully:", response.data.message);
        setData((prevUser) => prevUser.filter((user) => user._id !== userId));
      })
      .catch((error) => {
        console.error("Error deleting user:", error.response.data.error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the updated data to the server using axios
    axios
      .put(
        `http://localhost:5000/api/updateUser/${selectedProduct._id}`,
        formData
      )
      .then((response) => {
        console.log("User updated successfully:", response.data.message);
        // Close the modal
        handleCloseModal();

        // Fetch the updated data from the server and update the state
        axios
          .get("http://localhost:5000/api/Getalluser")
          .then((response) => {
            setData(response.data.users);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error) => {
        console.error("Error in updating user:", error.response.data.error);
      });
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="table-responsive">
          <MDBTable className="table table-striped w-100 h-100">
            <MDBTableHead className="text-center">
              <tr>
                <th scope="col">S.NO</th>
                <th scope="col">NAME</th>
                <th scope="col">EMAIL</th>
                <th scope="col">PHONE</th>
                <th scope="col">ROLE</th>
                <th scope="col">DATE</th>
                <th scope="col">ACTIONS</th>
              </tr>
            </MDBTableHead>
            <MDBTableBody className="text-center">
              {data.map((user, index) => (
                <tr key={user._id} className=" px-2">
                  <th scope="row">{index + 1}</th>
                  <td>
                    {/* <div className="d-flex align-items-center"> */}
                    {/* Render user avatar */}

                    <p className="fw-bold mb-1">{user.name}</p>
                    {/* </div> */}
                  </td>
                  <td>
                    <p className="text-muted mb-0">{user.email}</p>
                  </td>
                  <td>
                    <div className="ms-3">
                      <p className="fw-bold mb-1">{user.phone}</p>
                    </div>
                  </td>
                  <td>{user.role}</td>
                  <td>{user.createdAt}</td>
                  <td>
                    <div className="buttonsEnq ">
                      <AiOutlineEdit
                        size={20}
                        className="btn btn-primary btn-sm p-0 me-1 "
                        title="Edit"
                        onClick={() => handleOpenModal(user)}
                      />
                      <AiOutlineDelete
                        size={20}
                        className="btn btn-danger btn-sm p-0 me-1"
                        title="Delete"
                        onClick={() => handleDelete(user._id)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </MDBTableBody>
          </MDBTable>
        </div>
      </div>

      {/* Render the modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update Profile {selectedProduct?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your inquiry form or content here */}
          {/* For example, you can include an email input and a textarea */}
          <form onSubmit={handleSubmit}>
            <div className="mb-1">
              <label htmlFor="email">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Role</label>
              <input
                type="text"
                className="form-control"
                id="role"
                name="role"
                value={formData.role}
                onChange={handleEnquiryChange}
              />
            </div>

            <Button variant="primary" type="submit">
              Update
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TotalRegisterUser;
