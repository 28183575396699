import React from "react";
import { FaWarehouse, FaShippingFast } from "react-icons/fa";
import { BsStarHalf, BsQuestionCircleFill } from "react-icons/bs";
import { MdOutlineSecurity } from "react-icons/md";

const features = [
  {
    icon: <BsStarHalf style={{ color: "orange", margin: "auto" }} />,
    title: "QUALITY AND SAVING",
    description: "Comprehensive quality control and affordable prices",
  },
  {
    icon: <FaWarehouse style={{ color: "orange", margin: "auto" }} />,
    title: "GLOBAL WAREHOUSE",
    description: "Comprehensive quality control and affordable prices",
  },
  {
    icon: <FaShippingFast style={{ color: "orange", margin: "auto" }} />,
    title: "FAST SHIPPING",
    description: "Comprehensive quality control and affordable prices",
  },
  {
    icon: <MdOutlineSecurity style={{ color: "orange", margin: "auto" }} />,
    title: "PAYMENT SECURITY",
    description: "Comprehensive quality control and affordable prices",
  },
  {
    icon: <BsQuestionCircleFill style={{ color: "orange", margin: "auto" }} />,
    title: "HAVE QUESTIONS?",
    description: "Comprehensive quality control and affordable prices",
  },
];

const ShopWithUs = () => {
  return (
    <div
      style={{
        width: "100%",
        background: "#4482ef",
        textAlign: "center",
      }}
    >
      <p
        style={{
          fontWeight: "700",
          color: "white",
          paddingTop: "25px",
        }}
      >
        Why Shop With Us?
      </p>
      <div
        className="d-flex justify-content-around flex-wrap "
        style={{ width: "90%", margin: "auto" }}
      >
        {features.map((feature, index) => (
          <div
            key={index}
            className="my-3"
            style={{
              padding: "40px 7px",
              width: "230px",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <h1>{feature.icon}</h1>
            <p
              className="my-2"
              style={{
                fontWeight: "700",
                fontSize: "14px",
              }}
            >
              {feature.title}
            </p>
            <p
              className="mx-1"
              style={{
                fontSize: "13px",
                color: "#444444",
              }}
            >
              {feature.description}
            </p>
          </div>
        ))}
      </div>
      <p className="my-2" style={{ color: "white" }}>
        Contrary to popular belief, Lorem Ipsum is not simply random text. It
        has roots in a piece of classical Latin literature from 45 BC
      </p>
      <p
        style={{
          color: "yellow",
          textShadow: "1px 1px 3px black",
          paddingBottom: "10px",
        }}
      >
        Start your order now.
      </p>
    </div>
  );
};

export default ShopWithUs;
