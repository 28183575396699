import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";

const SubCategory = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { categoryId, parentId } = useParams();
  const [categoryName, setCategoryName] = useState("");
  const [subCategoriesWithSubCategories, setSubCategoriesWithSubCategories] =
    useState([]);
  const navigate = useNavigate();
  const [parentIds, setParentIds] = useState([]); // Add parentIds state

  useEffect(() => {
    setParentIds([]); // Initialize parentIds with an empty array
    fetchData();
  }, [categoryId]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://demo.businessworldtrade.com/api/getcategory/${categoryId}`
      );
      setCategoryName(response.data.category.name);

      // Fetch subcategories of each subcategory
      const subCategoriesData = await Promise.all(
        response.data.subcategories.map(async (subCategory) => {
          const subCategoryId = subCategory._id;
          const subCategoryResponse = await axios.get(
            `https://demo.businessworldtrade.com/api/getcategory/                                                                                  vxx  xx${subCategoryId}`
          );
          return subCategoryResponse.data;
        })
      );

      // Now you have subcategories with their subcategories included
      setSubCategoriesWithSubCategories(subCategoriesData);
      console.log("dear", subCategoriesData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubcategoryClick = async (categoryId, parentIds, parentId) => {
    try {
      const response = await axios.get(
        `https://demo.businessworldtrade.com/api/getcategory/${categoryId}`
      );
      navigate(`/category-details/${categoryId}`, {
        state: {
          categoryData: response.data,
          parentIds: [...parentIds, categoryId],
          parentId: parentId, // Include the parentId in the state
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  // const getRandomColor = () => {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  const SubCategoryCard = ({ subCategory }) => {
    const [isHovered, setIsHovered] = useState(false);

    return (
      <div
        className="col-12 col-md-6 col-lg-3 d-flex"
        style={{
          cursor: "pointer",
          flexGrow: 1,
          flexShrink: 1,
          marginBottom: "10px",
        }}
        onClick={() =>
          handleSubcategoryClick(
            subCategory.category._id,
            [...parentIds, subCategory.category._id],
            parentId
          )
        }
        onMouseEnter={() => setIsHovered(true)} // Set isHovered to true on mouse enter
        onMouseLeave={() => setIsHovered(false)} // Set isHovered to false on mouse leave
      >
        <div
          className="card"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            // border: isHovered
            //   ? "2px solid " + getRandomColor()
            //   : "2px solid transparent",
            background: isHovered ? "Gainsboro" : "transparent",
          }}
        >
          <div className="card-body" style={{ flexGrow: 1 }}>
            <div className="row d-flex">
              {subCategory.category.images &&
                subCategory.category.images.length > 0 && (
                  <div className="col-sm-3 col-md-3">
                    <img
                      className=""
                      src={`https://demo.businessworldtrade.com/${subCategory.category.images[0].img}`}
                      alt={subCategory.category.name}
                      style={{ width: "100%", height: "auto" }}
                    />
                  </div>
                )}
              <div
                className="col-sm-3 col-md-9 subcategoriescontainer fs-5 d-flex flex-column"
                style={{ flexGrow: 1, flexShrink: 0 }}
              >
                <span>{subCategory.category.name}</span>
                {/* Render additional category data here */}
                {/* For example, subCategory.category.someOtherData */}
              </div>
              <div
                className="subcategories"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                <div />
                {subCategory.subcategories.slice(0, 3).map((subSubCategory) => (
                  <div key={subSubCategory._id} style={{ marginRight: "10px" }}>
                    <span className="fs-6 text-secondary">
                      {subSubCategory.name}
                    </span>
                    {/* Render additional subcategory data here */}
                    {/* For example, subSubCategory.someOtherData */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Navbar />

      <div className="ctg-agriculture">
        <div className="">
          Home /{" "}
          <span style={{ color: "red", cursor: "pointer" }}>
            {categoryName}
          </span>
        </div>
      </div>
      <h1>{categoryName}</h1>
      <div className="container">
        <div className="row cat-row ">
          {subCategoriesWithSubCategories.map((subCategory) => (
            <SubCategoryCard
              key={subCategory.category._id}
              subCategory={subCategory}
            />
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SubCategory;
