import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Container, Row, Col } from "react-bootstrap";
import "./SubSubCategory.css";
import { useParams } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";

const SubSubCategory = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 848 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 848, min: 600 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 600, min: 542 },
      items: 2,
    },
    mobilexs: {
      breakpoint: { max: 542, min: 0 },
      items: 3,
    },
  };

  const { categoryId } = useParams();
  const [categoryData, setCategoryData] = useState(null);
  const [relatedCategories, setRelatedCategories] = useState([]);

  useEffect(() => {
    const fetchCategoryData = async (parentId) => {
      try {
        const response = await axios.get(
          `https://demo.businessworldtrade.com/api/getcategory/${parentId}`
        );
        console.log(response.data); // Log category data
        setCategoryData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRelatedCategories = async (parentId) => {
      try {
        const response = await axios.get(
          `https://demo.businessworldtrade.com/api/getcategory?parentID=${parentId}`
        );
        console.log("aaaaaaaaa", response.data); // Log related categories data
        const relatedCategoriesData = response.data;
        setRelatedCategories(relatedCategoriesData);

        // Fetch subcategoryData for each related category
        const promises = relatedCategoriesData.map(async (relatedCategory) => {
          const subcategoryResponse = await axios.get(
            `http://localhost:5000/api/getcategory/${relatedCategory._id}`
          );
          console.log("subcategoryResponse.data", subcategoryResponse.data); // Log subcategory data
          const subcategoryData = subcategoryResponse.data;
          return { ...relatedCategory, subcategoryData };
        });

        const updatedRelatedCategories = await Promise.all(promises);
        setRelatedCategories(updatedRelatedCategories);
        console.log("hello", relatedCategories);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategoryData(categoryId);
    fetchRelatedCategories(categoryId);
  }, [categoryId]);

  if (!categoryData) {
    return (
      <div>
        <Navbar />
        <HashLoader
          color="#36d7b7"
          size={70}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        />
        <Footer />
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="sub-sub-category">
        <h3 className="category-heading">{categoryData.category.name}</h3>
      </div>
      <Container>
        <Row className="px-4 container-row">
          <Col
            xs={6}
            md={2}
            style={{ width: "24%" }}
            className="mx-2 rounded shadow container-row-column-one"
          >
            <h6 className="category-heading my-2">Related Category</h6>
            <div className="category-list mt-3">
              {categoryData.subcategories.map((relatedCat) => (
                <div key={relatedCat._id} className="category-item">
                  {relatedCat.images && relatedCat.images[0] && (
                    <img
                      className="category-img"
                      src={`http://localhost:5000/${relatedCat.images[0].img}`}
                      alt={relatedCat.name}
                    />
                  )}
                  <p className="category-name mt-2">{relatedCat.name}</p>
                  {relatedCat.subcategoryData && (
                    <p className="subcategory-data">
                      {relatedCat.subcategoryData.category.name}
                    </p>
                  )}
                </div>
              ))}
            </div>
          </Col>

          <Col xs={12} md={9} className="shadow container-row-column-two">
            <p className="sub-category-heading my-2">
              Top {categoryData.category.name} Categories
            </p>
            <Carousel responsive={responsive}>
              {categoryData.subcategories.map((category) => (
                <div
                  key={category._id}
                  className="my-3 mx-3 container-row-column-two-div"
                >
                  <img
                    className=""
                    src={`http://localhost:5000/${category.images[0].img}`}
                    alt={category.name}
                  />
                  <p className="text-center my-2 text-bold">{category.name}</p>
                </div>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row>
          <Col className="my-5 px-0 d-flex flex-wrap mx-auto g-0 border border-primary">
            {/* Render your product components here */}
          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  );
};

export default SubSubCategory;
