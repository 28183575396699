import React, { useEffect } from "react";
import { useParams} from "react-router-dom";

import {
  Box,
  Image,
  Stack,
  Text,
  Button,
  Spacer,
  Flex,
  Select,
  Input,
  Textarea,
  Center,
  AlertIcon,
  Alert,
  IconButton,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
// import { FaUpload } from "react-icons/fa";
// import { getProduct } from "../action/productAction";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import {
  // AddProductactions,
  getuserid,
  updateUserById,
} from "../action/productAction";
// import { Generatepublciurl } from "./urlconfigure";
const Editmanageproducts = () => {
  const { id } = useParams();

  // for text area in a form
  let [value, setValue] = React.useState("");

  let handleInputChange = (e) => {
    let inputValue = e.target.value;
    setValue(inputValue);
    // **************************
  };
  // ***************************
  const categorylists = useSelector((state) => state.products);
  const update = useSelector((state) => state.products);

  const user = useSelector((state) => state.products.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getuserid(id));
  }, [dispatch]);

  const nestedData = (data, parentId) => {
    if (!data) return [];
    let nested = [];
    for (let item of data) {
      if (!parentId && !item.parentId) {
        let children = nestedData(data, item._id);
        if (children.length) {
          item.children = children;
        }
        nested.push(item);
      } else if (item.parentId === parentId) {
        let children = nestedData(data, item._id);
        if (children.length) {
          item.children = children;
        }
        nested.push(item);
      }
    }
    return nested;
  };

  const nested = nestedData(categorylists.categorylist, null);

  // {category.children && category.children.length > 0 ? (<ul>{Renderporducts(category.children)}</ul>) :""}

  function extractChildren(categories, parentId) {
    if (!categories) return [];
    if (!parentId) return categories;
    return categories.flatMap((category) => {
      if (category._id === parentId) {
        return category.children;
      }
      return extractChildren(category.children, parentId);
    });
  }

  function SecondDropdown({ parentId }) {
    const subcategories = extractChildren(nested, parentId);
    const options = subcategories.map((subcategory) => (
      <option key={subcategory._id} value={subcategory._id}>
        {subcategory.name}
      </option>
    ));
    return (
      <Select
        onChange={handleChanges}
        variant="outline"
        fontSize={13}
        bg="white"
      >
        <option value="">Select a subcategory</option>
        {options}
      </Select>
    );
  }
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCatsub, setSelectedsub] = useState("");
  const [selectedCatsubsub, setSelectedsubsub] = useState("");

  const [name, setname] = useState([user?.name || ""]);
  const [countryOrigin, setcountryOrigin] = useState([]);

  const [Color, setColor] = useState([]);
  const [packagetype, setpackagetype] = useState([]);
  const [Size, setSize] = useState([]);
  const [Minimum, setMinimum] = useState([]);
  const [category, setcategory] = useState([]);
  const [price, setprice] = useState([]);
  const [short_des, setshort_des] = useState([]);
  const [brief_des, setbrief_des] = useState([]);

  console.log(name);
  const [fileName, setFileName] = useState([]);
  useEffect(() => {
    setcountryOrigin(user?.countryOrigin || "");
    setname(user?.name || "");
    setColor(user?.Color || "");
    setpackagetype(user?.packagetype || "");
    setprice(user?.price || "");
    setshort_des(user?.short_des || "");
    setbrief_des(user?.brief_des || "");
    setSize(user?.Size || "");
  }, [user, value]);
  function handleChange(event) {
    setSelectedCat(event.target.value);
  }
  if (!nested) return null;
  const options = nested.map((category) => (
    <option key={category.id} value={category.id}>
      {category.cat}
    </option>
  ));

  const handleChanges = (event, parentId) => {
    return setSelectedsub(event.target.value);
  };

  function Thirddropdown({ parentId }) {
    const subcategories = extractChildren(nested, parentId);
    const options = subcategories.map((subcategory) => (
      <option key={subcategory._id} value={subcategory._id}>
        {subcategory.name}
      </option>
    ));
    return (
      <Select
        onChange={handlechangesubsub}
        variant="outline"
        fontSize={13}
        bg="white"
      >
        <option value="">Select a subcategory</option>
        {options}
      </Select>
    );
  }

  const handlechangesubsub = (event, parentId) => {
    return setSelectedsubsub(event.target.value);
  };
  function FourthDropdown({ parentId }) {
    const subcategories = extractChildren(nested, parentId);
    const options = subcategories.map((subcategory) => (
      <option key={subcategory._id} value={subcategory.name}>
        {subcategory.name}
      </option>
    ));
    return (
      <Select
        variant="outline"
        fontSize={13}
        bg="white"
        onChange={(e) => setcategory(e.target.value)}
      >
        <option value="">Select a sub-subcategory</option>
        {options}
      </Select>
    );
  }

  console.log("====================================");
  console.log("====================================");
  const handleclick = (event) => {
    event.preventDefault();
    let obj = {
      name: name,
      countryOrigin: countryOrigin,
      Color: Color,
      packagetype: packagetype,
      Size: Size,
      // Minimum: Minimum,
      category: category,
      price: price,
      short_des: short_des,
      brief_des: brief_des,
    };
    dispatch(updateUserById(obj, id));
  };
  const handlepicture = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setFileName((prevFileNames) => [...prevFileNames, reader.result]);
    };
    reader.readAsDataURL(file);
  };
  return (
    <>
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <Flex bg="white" p={2} borderBottom="1px" borderBottomColor="#c3c3c3">
            <Box>
              <Text
                style={{
                  fontSize: "25px",
                  fontWeight: "500",
                  color: "#0e5176",
                }}
              >
                Add Products
              </Text>
            </Box>
            <Spacer />
            <Box>
              <Button colorScheme="green" variant="solid">
                Save Product
              </Button>
            </Box>
          </Flex>

          {/* main box */}
          <Alert status="success" variant="subtle">
            <AlertIcon />
            Product Added Successfully :)
          </Alert>
          <div className="mt-4">
            <Stack
              direction={{ base: "column", md: "row", sm: "column" }}
              mx={3}
            >
              <Box direction="column" margin="auto">
                {fileName.length > 0 ? (
                  <>
                    <Image
                      boxSize="252px"
                      src={fileName[0]}
                      alt="Dan Abramov"
                    />
                  </>
                ) : (
                  ""
                )}

                <Stack my={2} display="flex" direction="row" spacing={1}>
                  {/* <Image
                    boxSize="60px"
                    objectFit="cover"
                    src="https://bit.ly/dan-abramov"
                    alt="Dan Abramov"
                  />
                  <Image
                    boxSize="60px"
                    objectFit="cover"
                    src="https://bit.ly/dan-abramov"
                    alt="Dan Abramov"
                  />
                  <Image
                    boxSize="60px"
                    objectFit="cover"
                    src="https://bit.ly/dan-abramov"
                    alt="Dan Abramov"
                  />
                  <Image
                    boxSize="60px"
                    objectFit="cover"
                    src="https://bit.ly/dan-abramov"
                    alt="Dan Abramov"
                    
                  /> */}

                  {fileName.length > 0
                    ? fileName.map((data, id) => {
                        return (
                          <>
                            <Flex>
                              <Image
                                boxSize="60px"
                                objectFit="cover"
                                src={data}
                                key={id}
                                alt="Dan Abramov"
                              />
                              <IconButton
                                title="Delete"
                                aria-label="delete"
                                style={{
                                  background: "transparent",
                                  position: "absolute",
                                  marginLeft: "32px",
                                  marginTop: "30px",
                                }}
                                icon={
                                  <DeleteIcon
                                    color="red.600"
                                    onClick={() =>
                                      setFileName(
                                        fileName.filter(
                                          (_, index) => index !== id
                                        )
                                      )
                                    }
                                  />
                                }
                              />
                            </Flex>
                            {/* <DeleteIcon /> */}
                          </>
                        );
                      })
                    : ""}
                </Stack>
                <Stack spacing={4} marginTop={2}>
                  <Input
                    id="Addproduct-btn"
                    type="file"
                    hidden
                    name="images"
                    onChange={handlepicture}
                  ></Input>
                  <label htmlFor="Addproduct-btn" className="addproduct-btn">
                    Update Product Images <i className="fa-solid fa-upload"></i>
                  </label>
                </Stack>
              </Box>

              <Box>
                <Stack
                  onChange={handleChange}
                  spacing={4}
                  direction={{ base: "column", md: "row", sm: "column" }}
                >
                  <Select
                    fontSize={13}
                    variant="outline"
                    bg="white"
                    placeholder="Select Product Category"
                  >
                    {" "}
                    {nested.map((category) => (
                      <option key={category._id} value={category._id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>

                  {selectedCat && <SecondDropdown parentId={selectedCat} />}
                  {/* 
                  <Select
                    fontSize={13}
                    variant="outline"
                    bg="white"
                    placeholder="Select InnerSub Category"
                  /> */}
                  {selectedCatsub && (
                    <Thirddropdown parentId={selectedCatsub} />
                  )}
                </Stack>
                <Stack
                  spacing={4}
                  direction={{ base: "column", md: "row", sm: "column" }}
                  my={6}
                >
                  {/* <Select
                    variant="outline"
                    fontSize={13}
                    bg="white"
                    placeholder="Select LastSub Category"
                  /> */}
                  {selectedCatsubsub && (
                    <FourthDropdown parentId={selectedCatsubsub} />
                  )}

                  <Input
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    placeholder="Enter Product Name"
                    name="name"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    size="md"
                  />
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  <Input
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    value={price}
                    placeholder="Enter Price"
                    name="price"
                    onChange={(e) => setprice(e.target.value)}
                    size="md"
                  />
                  <Select
                    variant="outline"
                    fontSize={13}
                    bg="white"
                    placeholder="Select Unit"
                  />
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  <Input
                    variant="outline"
                    fontSize={13}
                    bg="white"
                    placeholder="Select Size"
                    name="Size"
                    value={Size}
                    onChange={(e) => setSize(e.target.value)}
                  />
                  <Select
                    variant="outline"
                    fontSize={13}
                    bg="white"
                    name="packagetype"
                    value={packagetype}
                    onChange={(e) => setpackagetype(e.target.value)}
                    placeholder="Packaging type"
                  >
                    <option>Physical</option>
                    <option>Other</option>
                  </Select>
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  <Input
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    placeholder="Color"
                    size="md"
                    value={Color}
                    name="Color"
                    onChange={(e) => setColor(e.target.value)}
                  />
                  <Input
                    variant="outline"
                    fontSize={13}
                    name="countryOrigin"
                    value={countryOrigin}
                    onChange={(e) => setcountryOrigin(e.target.value)}
                    bg="white"
                    placeholder="Counrty of Origin"
                  />
                </Stack>
                <Stack spacing={4} direction="row" my={6}>
                  {/* <Input
                    fontSize={13}
                    _placeholder={{ color: "black" }}
                    bg="white"
                    name="Minimum"
                    value={Minimum}
                    onChange={(e) => setMinimum(e.target.value)}
                    placeholder="Minimum Order Quantity"
                    size="md"
                  /> */}
                  <Select
                    variant="outline"
                    fontSize={13}
                    bg="white"
                    placeholder="Product Stock"
                  />
                </Stack>
              </Box>
            </Stack>
            <Stack p={4}>
              <Text mb="4px" fontSize="bold" fontWeight="600">
                Write Short Description about your product: {value}
              </Text>
              <Textarea
                bg="white"
                onChange={(e) => setshort_des(e.target.value)}
                placeholder="Write here something"
                size="sm"
                value={short_des}
                name="short_des"
              />
            </Stack>
            <Stack p={4}>
              <Text mb="4px" fontSize="bold" fontWeight="600">
                Write Brief Description about your product: {value}
              </Text>

              <Textarea
                bg="white"
                onChange={(e) => setbrief_des(e.target.value)}
                placeholder="Write here something to describe your product"
                size="sm"
                value={brief_des}
                name="brief_des"
              />
            </Stack>

            {/*  */}
            <Stack p={4}>
              <Center>
                <Button
                  colorScheme="green"
                  variant="solid"
                  onClick={handleclick}
                >
                  Save Product
                </Button>
              </Center>
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editmanageproducts;
