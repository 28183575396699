import React, { useEffect } from "react";
import NavbarHeader from "../../Homepage/Navbar";
import Footer from "../../Homepage/Footer";
import background from "../../images/web-design-banner.jpg";
export const Platinum = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    textAlign: "left",
    color: "grey",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  return (
    <>
      <NavbarHeader />
      <>
        {/* about banner start */}
        <div
          className="container-fluid webdesigning-banner gap py-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: " no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="row py-5">
            <div className="col-12 text-center text-light pt-3">
              <h5>WE ENHANCING YOUR BUSINESS </h5>
            </div>
            <div className="col-12 text-center  pb-3 banner-head">
              <h1 className="text-light">PLATINUM PACKAGE</h1>
            </div>
          </div>
        </div>
        {/* about banner start */}
        {/* PACkage start */}
        <h2 className="privacy-policy text-center my-5">
          BENEFITS OF PLATINUM PACKAGE
        </h2>
        <div
          className="container my-5"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="row px-2">
            <div className="col-sm-12 col-12 pt-4 mt-2 mb-3">
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Custom Website Design{" "}
                </span>
                <br />
                Get a stunning and fully customized website design that aligns
                with your brand identity and captivates visitors.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Domain Registration{" "}
                </span>
                <br />
                Register a unique domain name that establishes your online
                presence and enhances brand recognition.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Favicon{" "}
                </span>
                <br />
                Add a custom favicon to your website, making it easily
                recognizable and reinforcing your brand image.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Logistic Quotation{" "}
                </span>
                <br />
                Integrate logistic quotation services to provide customers with
                seamless shipping options and transparent cost calculations.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Language Converter{" "}
                </span>
                <br />
                Offer multilingual support with a language converter feature,
                allowing visitors to view your website in their preferred
                language.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Unlimited Product Listings{" "}
                </span>
                <br />
                Showcase an unlimited number of products with detailed
                descriptions, specifications, and high-quality images, allowing
                customers to explore your entire product range.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Mobile-Responsive Design{" "}
                </span>
                <br />
                Ensure your website looks and functions flawlessly on all
                devices, providing a seamless user experience for customers
                accessing your site on smartphones and tablets.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Google AdWords{" "}
                </span>
                <br />
                Boost your online visibility and drive targeted traffic to your
                website through strategically managed Google AdWords campaigns.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  On-Page SEO{" "}
                </span>
                <br />
                Optimize your website's on-page elements, including meta tags,
                headings, and content, to improve search engine rankings and
                attract organic traffic.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Email{" "}
                </span>
                <br />
                Establish a professional email address using your domain name,
                enhancing brand credibility and professionalism in your
                communication. With the Platinum Package, you'll receive a
                comprehensive range of features and services to elevate your
                online presence, attract customers, and maximize your business
                potential. From a custom website design and domain registration
                to logistic quotation integration, language conversion,
                unlimited product listings, mobile-responsive design, Google
                AdWords, on-page SEO, and professional email, this package
                empowers your business with a robust online presence and
                effective marketing tools.
              </p>
            </div>
          </div>
        </div>
        {/* PACkage end */}
      </>

      <Footer />
    </>
  );
};
