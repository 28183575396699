import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FoodBeverages from "./FoodBeverages";
import Papercup from "../images/homePageImg/machinery/paper-cup.jpg";
import Footwear from "../images/homePageImg/machinery/footwear-machinery.jpg";
import Arecaleaf from "../images/homePageImg/machinery/areca-leaf-machine.jpg";
// const FoodBeverage =images/homePageImg/machinery/areca-leaf-machinery.png ;
import CappingMachine from "../images/homePageImg/machinery/capping-machine.jpg";
import Handyinkjet from "../images/homePageImg/machinery/handy-inkjet.jpg";

const FoodBeverage = () => {
  const Machinery = [
    {
      img: Papercup,
      soldBy: "Madhava ",
      name: "Paper Cup Making Machine",
      description: "Wholeseller: Andhra Pradesh",
    },
    {
      img: Footwear,
      soldBy: "Sonu Kumar ",
      name: "Footwear Machinery",
      description: "Retailer: Muzaffarpur, Bihar ",
    },
    {
      img: Arecaleaf,
      soldBy: "Ravi ",
      name: "Areca Leaf Plate Making Machine",
      description: "Wholeseller: Kolkata",
    },
    {
      img: CappingMachine,
      soldBy: "Mohammed",
      name: "Capping machine",
      description: "Wholeseller: Mogadishu",
    },
    {
      img: Handyinkjet,
      soldBy: "Swami Vinod ",
      name: "Handy inkjet printer",
      description: "Retailer: Mumbai, Maharashtra",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const productList = Machinery.map((item) => (
    <FoodBeverages
      key={item.id}
      name={item.name}
      img={item.img}
      soldBy={item.soldBy}
      desc={item.description}
    />
  ));
  return (
    <div>
      <h1
        className="mt-5 mb-3"
        style={{
          color: "#444444",
          fontWeight: "700",
        }}
      >
        Machinery
      </h1>
      <Carousel responsive={responsive}>{productList}</Carousel>
    </div>
  );
};

export default FoodBeverage;
