import React, { useState } from "react";
import "./Testimonial.css";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import testimonial1 from "../images/testimonial-1.png";
import testimonial2 from "../images/testimonial-2.png";
import testimonial3 from "../images/testimonial-3.png";

const testimonialData = [
  {
    id: 1,
    image: testimonial1,
    name: "Pooja Masala Store",

    comment:
      "They helped in making the preliminary screening process of multiple website developers very easy for me. I am extremely happy with the service.",
  },
  {
    id: 2,
    image: testimonial2,
    name: "Blazing Onion",
    comment:
      "I have made a website from this company and now my business is going well, and I have got a lot of help from this company.",
  },
  {
    id: 3,
    image: testimonial3,
    name: "Shagupta Tanseem",
    comment:
      "I came to Business World Trade Solutions. They have helped me with all the SEO needs. Now they rank higher than competitors and make some good money.",
  },
];

const Testimonial = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  const handlePrevSlide = () => {
    const prevSlide =
      activeSlide === 0 ? testimonialData.length - 1 : activeSlide - 1;
    setActiveSlide(prevSlide);
  };

  const handleNextSlide = () => {
    const nextSlide =
      activeSlide === testimonialData.length - 1 ? 0 : activeSlide + 1;
    setActiveSlide(nextSlide);
  };

  return (
    <div style={{ width: "100%" }}>
      <section className="gradient-custom">
        <div className="container my-5">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="text-center mb-4 pb-2">
                <i className="fas fa-quote-left fa-3x text-white"></i>
              </div>

              <div className="card">
                <div className="card-body px-4">
                  <div
                    id="carouselDarkVariant"
                    className="carousel slide carousel-dark"
                    data-mdb-ride="carousel"
                  >
                    <div
                      className="carousel-indicators mb-0"
                      style={{ margin: "auto" }}
                    >
                      {testimonialData.map((testimonial, index) => (
                        <button
                          key={testimonial.id}
                          style={{
                            width: "10px",
                            height: "1px",
                            color: "black",
                            margin: "0px 5px",
                            borderRadius: "5px",
                            background:
                              activeSlide === index ? "black" : "transparent",
                          }}
                          data-mdb-target="#carouselDarkVariant"
                          data-mdb-slide-to={index}
                          className={activeSlide === index ? "active" : ""}
                          aria-current={
                            activeSlide === index ? "true" : "false"
                          }
                          aria-label={`Slide ${index + 1}`}
                          onClick={() => handleSlideChange(index)}
                        ></button>
                      ))}
                    </div>

                    <div className="carousel-inner pb-5">
                      {testimonialData.map((testimonial, index) => (
                        <div
                          key={testimonial.id}
                          className={`carousel-item ${
                            activeSlide === index ? "active" : ""
                          }`}
                        >
                          <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-8">
                              <div className="row">
                                <div className="col-lg-4 d-flex justify-content-center mb-0">
                                  <img
                                    src={testimonial.image}
                                    className="rounded-circle mt-2 shadow-1 mb-2 mb-lg-0"
                                    alt="woman avatar"
                                    width="180"
                                    height="160"
                                  />
                                </div>
                                <div className="col-9 col-md-9 col-lg-7 col-xl-8 text-center text-lg-start mx-auto mx-lg-0">
                                  <h4 className="mb-4 mt-2">
                                    {testimonial.name}
                                  </h4>
                                  <p className="mb-0 pb-3">
                                    {testimonial.comment}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-mdb-target="#carouselDarkVariant"
                      data-mdb-slide="prev"
                      onClick={handlePrevSlide}
                    >
                      <span
                        className="carousel-control-prev-icon testimonial-btn-color"
                        aria-hidden="true"
                      >
                        <MdArrowBackIos />
                      </span>
                      <span className="visually-hidden">Previous</span>
                    </button>

                    <button
                      className="carousel-control-next"
                      type="button"
                      data-mdb-target="#carouselDarkVariant"
                      data-mdb-slide="next"
                      onClick={handleNextSlide}
                    >
                      <span
                        className="carousel-control-next-icon testimonial-btn-color"
                        aria-hidden="false"
                      >
                        <MdArrowForwardIos />
                      </span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="text-center mt-4 pt-2">
                <i className="fas fa-quote-right fa-3x text-white"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;
