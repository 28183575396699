import React, { useEffect } from "react";
import NavbarHeader from "../../Homepage/Navbar";
import Footer from "../../Homepage/Footer";
import background from "../../images/web-design-banner.jpg";
export const Bronze = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    textAlign: "left",
    color: "grey",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  return (
    <>
      <NavbarHeader />
      <>
        {/* about banner start */}
        <div
          className="container-fluid webdesigning-banner gap py-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: " no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="row py-5">
            <div className="col-12 text-center text-light pt-3">
              <h5>WE ENHANCING YOUR BUSINESS </h5>
            </div>
            <div className="col-12 text-center  pb-3 ">
              <h1 className="text-light">STANDARD PACKAGE</h1>
            </div>
          </div>
        </div>
        {/* about banner start */}
        {/* PACkage start */}
        <h2 className="my-5 privacy-policy text-center">
          BENEFITS OF STANDARD PACKAGE
        </h2>
        <div
          className="container my-5"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="row px-2">
            <div className="col-sm-12 col-12 pt-4 mt-2 mb-3">
              {/* <h2 className="package-head text-center">
                BENEFITS <br /> STANDARD PACKAGE
              </h2> */}
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Website
                </span>
                <br />
                Get a visually appealing and professional website that
                represents your business in the best possible way.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Domain Registration{" "}
                </span>
                <br />
                Secure a unique domain name that reflects your brand and helps
                customers easily find your website online.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Eye-catching Favicon
                </span>
                <br />
                Add a custom favicon to your website, creating a memorable and
                recognizable brand identity.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Seamless Logistic Quotation{" "}
                </span>
                <br />
                Integrate logistic quotation services to provide customers with
                transparent shipping options and accurate cost calculations.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Multilingual Support{" "}
                </span>
                <br />
                Offer language conversion on your website, enabling visitors to
                browse and interact in their preferred language.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Extensive Product List
                </span>
                <br />
                Display up to 25 products with detailed descriptions,
                specifications, and high-quality images, allowing customers to
                explore your offerings.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Mobile-Optimized Design{" "}
                </span>
                <br />
                Ensure your website is fully responsive and optimized for mobile
                devices, providing a seamless browsing experience for users on
                smartphones and tablets.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Email Address{" "}
                </span>
                <br />
                Establish a professional email address using your domain name,
                enhancing brand credibility and professionalism in your
                communication.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Improved Online Presence
                </span>
                <br />
                With a well-designed website and domain, you can increase your
                online visibility, attract more customers, and stand out from
                the competition.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Enhanced User Experience
                </span>
                <br />
                The user-friendly interface, intuitive navigation, and
                responsive design of your website ensure a positive and engaging
                experience for visitors.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Convenient Shipping Options
                </span>
                <br />
                By integrating logistic quotation services, you simplify the
                shipping process for customers, making it easier for them to
                choose the most suitable and cost-effective shipping methods.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Global Reach{" "}
                </span>
                <br />
                With a multilingual website, you can expand your customer base
                and reach potential customers from different regions and
                language backgrounds.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Improved Online Presence
                </span>
                <br />
                With a multilingual website, you can expand your customer base
                and reach potential customers from different regions and
                language backgrounds
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Showcase Your Products
                </span>
                <br />
                Present up to 25 products in a visually appealing manner,
                allowing customers to view detailed information and images, and
                make informed purchase decisions.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Seamless Mobile Experience
                </span>
                <br />
                Your website's mobile optimization ensures that users can access
                and navigate your site effortlessly, regardless of the device
                they are using.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Communication
                </span>
                <br />
                Having a professional email address using your domain name adds
                credibility to your business and helps establish trust with
                customers.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Brand Consistency
                </span>
                <br />
                With a custom favicon and professional email address, you
                maintain a consistent and cohesive brand image across different
                online platforms.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Increased Credibility
                </span>
                <br />A well-designed website, along with a professional domain
                name and email address, enhances your business's credibility and
                fosters trust among customers.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Stand Out from Competitors
                </span>
                <br />
                By investing in a standard package, you position your business
                as a professional and trustworthy choice, setting yourself apart
                from competitors who may lack a strong online presence.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Streamlined Sales Process
                </span>
                <br />
                With clear product listings, easy navigation, and transparent
                shipping options, you create a smooth and hassle-free sales
                process for customers.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Customer Engagement
                </span>
                <br />A user-friendly website and seamless communication
                channels allow you to engage with customers effectively,
                addressing their inquiries and building strong relationships.
              </p>
              <p style={pstyle}>
                The Standard Package offers an array of features and benefits to
                help your business succeed online. From a professional website
                and domain registration to a customized favicon, logistic
                quotation integration, multilingual support, extensive product
                listings, mobile optimization, and professional email, this
                package ensures your online presence is both visually appealing
                and user-friendly. With these key highlights, you can
                effectively communicate the value of the Standard Package to
                your customers and showcase the benefits they can enjoy by
                choosing this package for their business needs
              </p>
            </div>
          </div>
        </div>
        {/* PACkage end */}
      </>

      <Footer />
    </>
  );
};
