import React, { useState, useEffect } from "react";
import "./Blog.css";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import { useParams } from "react-router-dom";

import { BlogData } from ".././BlogAssets/blogData/BlogData";

const BlogDetails = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const selectedBlog = BlogData.find((item) => item.id === parseInt(id));
    if (selectedBlog) {
      setBlog(selectedBlog);
    }
  }, [id]);

  const formatTitle = (title) => {
    const parts = title.split(":");
    return (
      <>
        {parts[0]}:
        <br />
        {parts[1]}
      </>
    );
  };

  const formatDescription = (desc) => {
    const paragraphs = desc.split("\n");
    return paragraphs.map((paragraph, index) => (
      <p key={index}>
        {paragraph
          .split(":")
          .map((part, index) => (
            <span key={index}>
              {index === 0 && (
                <div className="text-uppercase uppercase-text"></div>
              )}
              {part}
            </span>
          ))
          .reduce((prev, curr) => [prev, ":", curr])}
      </p>
    ));
  };

  return (
    <>
      <Navbar />
      {blog ? (
        <section className="singlePage">
          <div className="container">
            <h1>{formatTitle(blog.title)}</h1>
            <div className="col-12 d-flex justify-content-center align-items-center">
              <div className="d-flex justify-content-center align-items-center">
                <img src={blog.cover} alt="" />
              </div>
            </div>
            <div className="right ">{formatDescription(blog.desc)}</div>
          </div>
        </section>
      ) : null}
      <Footer />
    </>
  );
};

export default BlogDetails;
