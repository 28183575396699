import React, { useEffect, useState } from "react";
import "./ProductPage.css";
import Navbar from "./Homepage/Navbar";
import Footer from "./Homepage/Footer";
import axios from "axios";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addUser } from "../reducers/FormSlice";

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const { productId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productResponse = await axios.get(
          `https://demo.businessworldtrade.com/api/getsingleproduct/${productId}`
        );
        setProductDetails(productResponse.data);

        // Check if user profile data exists in the response
        if (productResponse.data.userProfile) {
          console.log("User profile:", productResponse.data.userProfile);
        } else {
          console.log("User profile data not available.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [productId]);

  const handleEnquiryChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/createInquries", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        // console.log(res);
      })
      .catch((err) => console.log(err));
    handleCloseModal();

    // Reset the form after submission if needed
    setFormData({});
    // console.log("form data", formData);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Navbar />

      {productDetails && (
        <div className="product-page my-3">
          <div className="product-images">
            <div>
              <img
                src={`https://demo.businessworldtrade.com/${productDetails.product.productPictures[0].img}`}
                alt={productDetails.product.name}
              />
            </div>
            {/* ... Other sub-images ... */}
          </div>

          <div className="product-details">
            <h1 className="product-name">{productDetails.product.name}</h1>
            <h3 className="product-price">
              Rs. {productDetails.product.price}/-
            </h3>
            <h6>
              Description:{" "}
              <span className="text-secondary">
                {productDetails.product.description}
              </span>
            </h6>

            <div className="">
              <h6>
                Business Type:{" "}
                <span className="text-secondary">
                  {productDetails.product.businessType}
                </span>
              </h6>
              <h6>
                Max Order:{" "}
                <span className="text-secondary">
                  {productDetails.product.maxOrder}
                </span>
              </h6>

              <h6>
                MOQ:{" "}
                <span className="text-secondary">
                  {productDetails.product.minOrder}
                </span>
              </h6>
              <h6>
                Year Of Establishment:{" "}
                <span className="text-secondary">
                  {productDetails.product.minOrder}
                </span>
              </h6>
              <h6>
                Location:{" "}
                <span className="text-secondary">
                  {productDetails.product.location}
                </span>
              </h6>
            </div>

            {/* user profilfe data start form here */}

            {productDetails.userProfile && (
              <div className="d-flex my-3">
                <div className="w-25">
                  <img
                    src="https://funkylife.in/wp-content/uploads/2023/03/good-morning-image-531.jpg"
                    alt=""
                    style={{ width: "40%", margin: "auto" }}
                  />
                </div>
                <div className="w-75">
                  <h5 className="product-page-company-name">
                    <h5>
                      Business Name :{" "}
                      <span className="text-secondary">
                        {productDetails.userProfile.companyname}
                      </span>
                    </h5>
                  </h5>
                  <h5>
                    {productDetails.userProfile.packageType} SINCE :{" "}
                    <span className="text-danger">
                      {" "}
                      {productDetails.userProfile.YearofEst}
                    </span>
                  </h5>
                </div>
              </div>
            )}
            <div className="actions">
              <button className="add-to-cart" onClick={() => handleOpenModal()}>
                Enquiry Now
              </button>
            </div>

            <div className="product-pg-enquiry">
              <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Get Enquiry for {productDetails.product.name}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {/* Add your inquiry form or content here */}
                  {/* For example, you can include an email input and a textarea */}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-1">
                      <label htmlFor="email">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleEnquiryChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email">Email address</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleEnquiryChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleEnquiryChange}
                      />
                    </div>

                    <Button variant="primary" type="submit">
                      Send Enquiry
                    </Button>
                  </form>
                </Modal.Body>
              </Modal>
            </div>
          </div>

          <div className="my-4 product-highlights">
            <h3>Product Details:</h3>
            <ul>{productDetails.product.berifDesc}</ul>
            <ul>{productDetails.product.berifDesc}</ul>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default ProductDetails;
