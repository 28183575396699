import React, { useEffect } from "react";
import NavbarHeader from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import BusinessInsuranceBanner from "../images/businessinsurance.jpg";

export const BussinessInsuarance = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavbarHeader />
      <div
        className="container-fluid contactus-banner gap py-5 mb-4 img-fluid"
        style={{
          backgroundImage: `url(${BusinessInsuranceBanner})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center ",
        }}
      >
        <div className="row py-5">
          <div className="col-12 text-center text-light pt-3">
            <h5 style={{ fontSize: "30px", fontWeight: "bold" }}>
              Business Insurance
            </h5>
          </div>
          <div className="col-12 text-center text-light pb-3 banner-head">
            <h1
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "800 ",
                color: "white",
              }}
            >
              {" "}
              Safeguarding Your Enterprise with Business World Trade{" "}
            </h1>
          </div>
        </div>
      </div>
      {/* <h3 className="text-center my-3 privacy-policy">
        Business Insurance Solutions: <br></br>Safeguarding Your Enterprise with
        Business World Trade
      </h3> */}
      {/* <img src={loan} style={{width:"100%",paddingBottom:'20px'}}/> */}
      <div className="privacy-policy-content">
        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Introduction:
          </span>
          <br></br>In the fast-paced and unpredictable world of business,
          protecting your enterprise from potential risks and liabilities is
          paramount. As a reputable company known as "Business World Trade," we
          understand the significance of comprehensive insurance coverage for
          businesses like yours. We are proud to offer a range of tailored
          business insurance solutions designed to provide financial security
          and peace of mind to entrepreneurs and companies.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Why Choose Business World Trade for Your Business Insurance Needs
          </span>
          <br></br>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Expertise in Business Insurance:
          </span>{" "}
          With extensive experience in the industry, Business World Trade has
          established itself as a trusted provider of business insurance. Our
          team of insurance specialists possesses in-depth knowledge of various
          sectors and understands the unique risks and challenges faced by
          businesses. We leverage this expertise to provide customized insurance
          solutions that meet your specific needs.
          <p></p>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Comprehensive Coverage Options:
          </span>{" "}
          We offer a wide range of insurance coverage options to protect your
          business assets, operations, and employees. Whether it's property
          insurance, liability insurance, professional indemnity insurance, or
          employee benefits coverage, Business World Trade has you covered.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Tailored Insurance Solutions:
          </span>
          <br></br>
          We recognize that every business is different, and their insurance
          requirements vary. That's why we work closely with our clients to
          understand their specific risks and design insurance policies that
          provide optimal coverage. Our tailored solutions ensure that you have
          the protection you need without paying for unnecessary coverage.
        </p>
        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Superior Customer Service:{" "}
          </span>
          <br></br>
          At Business World Trade, we prioritize the satisfaction of our
          clients. Our dedicated customer service team is committed to providing
          prompt and personalized assistance, addressing your queries, and
          guiding you through the insurance process. We believe in building
          long-term relationships based on trust and reliability.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            {" "}
            Business Insurance Products Offered
          </span>
          <br></br>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Property Insurance:
          </span>{" "}
          Safeguard your business property, including buildings, equipment,
          inventory, and assets, against risks such as fire, theft, natural
          disasters, and vandalism. Our property insurance policies provide
          comprehensive coverage to protect your physical assets.Liability
          Insurance: Protect your business from legal liabilities and potential
          lawsuits arising from third-party claims. Our liability insurance
          options cover general liability, product liability, professional
          liability, and more, shielding your business from financial losses due
          to lawsuits and legal expenses.
          <p></p>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Business Interruption Insurance:{" "}
          </span>{" "}
          Prepare for unforeseen events that could disrupt your business
          operations. Business interruption insurance provides coverage for lost
          income, ongoing expenses, and the costs of relocation or temporary
          facilities in the event of a covered peril that forces your business
          to temporarily shut down.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Workers' Compensation Insurance:
          </span>
          <br></br>
          Ensure the well-being of your employees and comply with legal
          requirements by providing workers' compensation coverage. This
          insurance protects your business by providing benefits to employees
          who suffer work-related injuries or illnesses, covering medical
          expenses, lost wages, and rehabilitation costs.
        </p>
        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Conclusion:
          </span>
          <br></br>At Business World Trade, we understand the challenges and
          risks faced by businesses in today's competitive landscape. Our
          comprehensive business insurance solutions provide the financial
          protection you need to mitigate risks, safeguard your assets, and
          ensure the continuity of your operations. Trust our expertise,
          personalized service, and tailored insurance products to secure your
          business's future. Contact us today to explore the insurance options
          available to you.
        </p>
      </div>
      <Footer />
    </>
  );
};
