import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import NavbarHeader from "../Components/Homepage/Navbar";
import Footer from "../Components/Homepage/Footer";
import { useAuth } from "../context/AuthCotext";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../reducers/FormSlice";
import "./ContactProfile.css";
import { RiImageAddFill } from "react-icons/ri";

const ContactProfile = () => {
  const { isLoggedIn } = useAuth();
  const [formData, setFormData] = useState({});
  const [profileImg, setProfileImg] = useState(null);
  const dispatch = useDispatch();

  const handleImageUpload = (e) => {
    const imgFile = e.target.files[0];
    setProfileImg(imgFile);
  };

  const accessToken = useSelector((state) => state.auth.token);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!isLoggedIn) {
        console.log("User is not logged in. Please log in.");
        return;
      }

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const formDataWithImage = new FormData();
      formDataWithImage.append("profileImage", profileImg); // Append profile image
      for (const key in formData) {
        formDataWithImage.append(key, formData[key]);
      }

      const response = await axios.post(
        "https://demo.businessworldtrade.com/api/createContactProfile",
        formData,
        config
      );
      console.log("Data submitted:", response);
      dispatch(addUser(formData));
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <>
      {/* <NavbarHeader /> */}
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper ">
          <div className="adminSubNav">
            <h3 className="admin-nav-titlee">PROFILE</h3>
          </div>
          <hr />
          <div className="profile-container">
            <h5 className="profile-title">Upload Your Profile</h5>
            <label htmlFor="profileImage" className="profile-icon">
              {profileImg ? (
                <img
                  src={URL.createObjectURL(profileImg)}
                  alt="Profile"
                  className="profile-image-preview"
                />
              ) : (
                <>
                  <div className="add-icon">
                    <RiImageAddFill />
                  </div>
                  <div className="add-text"></div>
                </>
              )}
              <input
                type="file"
                id="profileImage"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: "none" }}
              />
            </label>
          </div>
          <Form
            className="my-2 mx-4 py-1 px-5 shadow rounded"
            onSubmit={handleSubmit}
          >
            <Row className="my-3">
              <Col>
                <Form.Group controlId="firstname">
                  <Form.Label>
                    First Name <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.firstname}
                    type="text"
                    placeholder="Enter First Name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="lastname">
                  <Form.Label>
                    Last Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.lastName}
                    type="text"
                    placeholder="Enter Last Name"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="my-3">
              <Col>
                <Form.Group controlId="companyname">
                  <Form.Label>
                    Phone<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.phone}
                    type="text"
                    placeholder="Company Name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="phoneNo">
                  <Form.Label>Alternate Phone</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.Altphone}
                    type="text"
                    placeholder="Enter Alternate Number"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="my-3">
              <Col>
                <Form.Group controlId="email">
                  <Form.Label>
                    Email<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.email}
                    type="text"
                    placeholder="Enter Email"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="alternateEmail">
                  <Form.Label>Alternate Email</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.Altemail}
                    type="text"
                    placeholder="Enter Alternate Email"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="my-3">
              <Col>
                <Form.Group controlId="companyname">
                  <Form.Label>
                    Company Name<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.companyname}
                    type="text"
                    placeholder="Enter Company Name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.country}
                    type="text"
                    placeholder="Enter Country"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="my-3">
              <Col>
                <Form.Group controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.state}
                    type="text"
                    placeholder="Enter State"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.city}
                    type="text"
                    placeholder="Enter City"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Form.Group controlId="address">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.address}
                    andleChange
                    type="text"
                    placeholder="Address"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="landmark">
                  <Form.Label>Landmark</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.landmark}
                    type="text"
                    placeholder="Landmark"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Form.Group controlId="YearOfEst.">
                  <Form.Label>Year Of Est.</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.zipcode}
                    type="text"
                    placeholder="Year Of Est."
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="Business Type">
                  <Form.Label>Business Type</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.BusinessType}
                    type="text"
                    placeholder="Business Type"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Form.Group controlId="OwnershipType">
                  <Form.Label>Ownership Type</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.ownershiptype}
                    type="text"
                    placeholder="Ownership Type"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="NoOfEmployees">
                  <Form.Label>No of Employees</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.employeeStrength}
                    type="text"
                    placeholder="No of Employees"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Form.Group controlId="AnnualTurnover">
                  <Form.Label>Annual Turnover</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.annualturnover}
                    type="text"
                    placeholder="Annual Turnover"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="CompanyDescription">
                  <Form.Label>Company Description</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.company_des}
                    type="text"
                    placeholder="Company Description"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Form.Group controlId="Facebooklink">
                  <Form.Label>Facebook link</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.facebooklink}
                    type="text"
                    placeholder="Facebook link"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="InstagramLink">
                  <Form.Label>Instagram Link</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.instagramlink}
                    type="text"
                    placeholder="Instagram Link"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Form.Group controlId="BankName">
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.bankName}
                    type="text"
                    placeholder="Enter Bank Name"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="AccountType">
                  <Form.Label>Account Type</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.accType}
                    type="text"
                    placeholder="Enter Account Type"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              <Col>
                <Form.Group controlId="AccountNumber">
                  <Form.Label>Account Number</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.AccNumb}
                    type="text"
                    placeholder="Enter Account Number"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="IFSC Code">
                  <Form.Label>IFSC Code</Form.Label>
                  <Form.Control
                    onChange={handleChange}
                    value={formData.ifscCode}
                    type="text"
                    placeholder="Enter IFSC Code"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="my-3">
              {/*   */}
              <Col>
                <Button className="my-4" type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default ContactProfile;
