import React, { useEffect, useState } from "react";
import axios from "axios";

const PostBuyRequirement = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo.businessworldtrade.com/api/postbyrequirement"
        );
        console.log("response admin", response.data[0]);
        setData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <div
            className="adminSubNav"
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <h3
              className=" mt-2"
              style={{
                margin: "auto",
                color: "#0e5176",
                fontWeight: "bold",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              Post By Requirement
            </h3>
          </div>
          <hr />

          <div className=" table-section">
            <div className="table-responsive">
              <table className=" table  table-hover  h-auto">
                <thead className="text-center">
                  <tr>
                    <th scope="col" className=" ms-5">
                      S.NO
                    </th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">PHONE</th>
                    <th scope="col">MOQ</th>
                    <th scope="col">QUALITY</th>
                    <th scope="col">REQUIREMENT</th>
                    <th scope="col">PAYMENT</th>
                    <th scope="col">PURPOSE</th>
                    <th scope="col">SALE/ PURCHASE</th>
                    <th scope="col">DELIVERY PLACE</th>
                    <th scope="col">DESCRIPTION</th>
                    <th scope="col">PACKAGE</th>
                    <th scope="col">LOCATION</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, index) => (
                    <tr key={index} className="text-center px-2">
                      <th scope="row">{index + 1}</th>
                      <td>{item.productName}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.moq}</td>
                      <td>{item.quality}</td>
                      <td>{item.requirement}</td>
                      <td>{item.payment}</td>
                      <td>{item.purpose}</td>
                      <td>{item.sale}</td>
                      <td>{item.deliveryPlace}</td>
                      <td>{item.description}</td>
                      <td>{item.packaging}</td>
                      <td>{item.location}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostBuyRequirement;
//  <td>
//                             <div className="buttonsEnq">
//                               <GoComment
//                                 size={18}
//                                 className="btn btn-success btn-sm p-0 me-2"
//                                 title="Comment"
//                               />

//                               <AiOutlineDelete
//                                 size={18}
//                                 className="btn btn-danger btn-sm p-0"
//                                 title="Delete"
//                                 // onClick={() => handelDelete(eachData.id)}
//                               />
//                             </div>
//                           </td>
