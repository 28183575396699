import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import NavbarHeader from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import supplier from "../images/supplier.jpg";
import { axios } from "axios";
import { useDispatch } from "react-redux";
export const SellerSearch = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // axios
    //   .post("http://localhost:5000/api/callback", formData)
    //   .then((res) => {
    //     dispatch(addUser(res.data));
    //     console.log(res);
    //   })
    //   .catch((err) => console.log(err));

    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };
  return (
    <>
      <NavbarHeader />
      <h2 className="text-center my-3 privacy-policy">Search Supplier</h2>
      <div className="container my-3 w-100 d-flex">
        <div
          className="container-fluid "
          // style={{
          //   margin: "auto",
          //   boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          //   border: "none",
          // }}
        >
          <div className="row">
            <div
              className="col-sm-12 col-md-6"
              style={{
                // border: "1px solid red",
                margin: "auto",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                height: "auto",
                border: "none",
              }}
            >
              <Form className="mt-4" onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Search Type
                  </Form.Label>
                  <div className="d-flex p-2">
                    <Form.Check
                      className=" me-2 text-secondary"
                      type="radio"
                      label="Product"
                      name="Search Type"
                      value="Product"
                      onChange={handleChange}
                    />
                    <Form.Check
                      className="ms-2 text-secondary"
                      type="radio"
                      label="Company"
                      name="Search Type"
                      value="Company"
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Enter Products/Services
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Products/Services"
                    name="ProductsServices"
                    value={formData.ProductsServices || ""}
                    onChange={handleChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Enter City
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter City"
                    name="City"
                    value={formData.City || ""}
                    onChange={handleChange}
                  />
                  <div className="d-flex p-2">
                    <Form.Check
                      className=" ms-2 text-secondary pe-2"
                      type="radio"
                      label="Show suppliers only from the city"
                      name="Dealing"
                      value="Show suppliers only from the city"
                      onChange={handleChange}
                    />
                    <Form.Check
                      className="ms-2 text-secondary pe-2"
                      type="radio"
                      label="Show Suppliers Dealing In The City"
                      name="Dealing"
                      value="Show Suppliers Dealing In The City"
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Looking For:
                  </Form.Label>
                </Form.Group>
                <Form.Group>
                  <div className="d-flex p-2 flex-md-column flex-sm-row align-items-start">
                    <div className="row">
                      <Form.Check
                        className=" ms-2 text-secondary"
                        type="radio"
                        label="Wholesaler"
                        name="Looking For"
                        value="Wholesaler"
                        onChange={handleChange}
                      />
                      <Form.Check
                        className="ms-2 text-secondary"
                        type="radio"
                        label="Manufacturer"
                        name="Looking For"
                        value="Manufacturer"
                        onChange={handleChange}
                      />
                      <Form.Check
                        className=" ms-2 text-secondary"
                        type="radio"
                        label="retailer"
                        name="Looking For"
                        value="retailer"
                        onChange={handleChange}
                      />
                      <Form.Check
                        className="ms-2 text-secondary"
                        type="radio"
                        label="Exporter"
                        name="Looking For"
                        value="Exporter"
                        onChange={handleChange}
                      />
                      <Form.Check
                        className="ms-2 text-secondary"
                        type="radio"
                        label="All"
                        name="Looking For"
                        value="All"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Show Products
                  </Form.Label>
                  <div className="d-flex p-2">
                    <Form.Check
                      className=" ms-2 text-secondary pe-2"
                      type="radio"
                      label="With Price"
                      name="Show Products"
                      value="With Price"
                      onChange={handleChange}
                    />
                    <Form.Check
                      className="ms-2 text-secondary pe-2"
                      type="radio"
                      label="All Products"
                      name="Show Products"
                      value="All Products"
                      onChange={handleChange}
                    />
                  </div>
                </Form.Group>
                <div className="text-center">
                  <Button
                    className="my-3  "
                    variant="outline-primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
            <div className="col-sm-12 col-md-6 d-flex mt-2">
              <img src={supplier} className="img-fluid " alt="downloading..." />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
