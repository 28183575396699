import React, { useEffect } from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import { BsFillChatTextFill } from "react-icons/bs";
import { TbCategory } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { BiSupport } from "react-icons/bi";
import one from ".././images/topSup/one.jpeg";
import two from ".././images/topSup/two.jpeg";
import three from ".././images/topSup/three.jpeg";
import four from ".././images/topSup/four.jpeg";
import five from ".././images/topSup/five.jpeg";
import six from ".././images/topSup/four.jpeg";
import seven from ".././images/topSup/seven.jpeg";
import eight from ".././images/topSup/eight.jpeg";
import nine from ".././images/topSup/nine.jpeg";
import ten from ".././images/topSup/ten.jpeg";
import eleven from ".././images/topSup/eleven.jpeg";
import twelve from ".././images/topSup/twelve.jpeg";
import thirteen from ".././images/topSup/thirteen.jpeg";
import forteen from ".././images/topSup/forteen.jpeg";
import fifteen from ".././images/topSup/fifteen.jpeg";
import mission from ".././images/mission1.png";
import vission from ".././images/vission-2.png";
import goal from ".././images/vission-1.png";

import "./FooterMain.css";
const AboutUS = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <Navbar />
      {/* FIRST_DIV */}
      <div className="about-us-first-div my-3">
        <div className="about-us-first-div-left">
          <h2 style={{ color: "#2d5e79" }}>Making Indian MSMEs</h2>
          <h2>
            <span style={{ color: "red" }}>Digitally </span>
            <span style={{ color: "#2d5e79" }}>Saksham</span>
          </h2>
          <p>
            Welcome to{" "}
            <span style={{ color: "#464545" }}>
              Business World Trade, a premier B2B platform
            </span>{" "}
            connecting buyers and sellers from around the globe. We are
            dedicated to revolutionizing the way businesses trade and thrive in
            today's dynamic marketplace.
          </p>
          <p>
            At Business World Trade, our mission is to provide a robust and
            seamless platform that facilitates efficient and transparent
            transactions between buyers and sellers. We believe in empowering
            businesses of all sizes by creating opportunities for growth,
            collaboration, and success.
          </p>
        </div>
        <div className="about-us-first-div-right">
          <div className="first-animation-slider">
            <div className="first-animation-slider-img">
              <img src={one} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={two} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={three} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={four} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={five} alt="error" />
            </div>
          </div>

          <div className="first-animation-slider">
            <div className="first-animation-slider-img-two">
              <img src={six} alt="error" />
            </div>
            <div className="first-animation-slider-img-two">
              <img src={seven} alt="error" />
            </div>
            <div className="first-animation-slider-img-two">
              <img src={eight} alt="error" />
            </div>
            <div className="first-animation-slider-img-two">
              <img src={nine} alt="error" />
            </div>
            <div className="first-animation-slider-img-two">
              <img src={ten} alt="error" />
            </div>
          </div>

          <div className="first-animation-slider">
            <div className="first-animation-slider-img">
              <img src={eleven} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={twelve} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={thirteen} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={forteen} alt="error" />
            </div>
            <div className="first-animation-slider-img">
              <img src={fifteen} alt="error" />
            </div>
          </div>
        </div>
      </div>

      {/* ABOUT_US */}
      <div className="aboutus-second">
        <div className="about-us-second-div">
          <div className="about-us-second-div-block">
            <div className="about-us-second-div-block-left">
              <h2>OUR MISSION</h2>
              <h4>
                Revolutionizing B2B Trade, Empowering Businesses, and Connecting
                Global Success
              </h4>
              <p>
                Business World Trade is to revolutionize global trade by
                providing a seamless and trusted B2B platform that connects
                buyers and sellers. We are dedicated to empowering businesses of
                all sizes, fostering transparency, and creating opportunities
                for growth and success. Through our innovative solutions, we aim
                to simplify the trading process, facilitate efficient
                transactions, and build strong, long-lasting business
                relationships.
              </p>
            </div>
            <div className="about-us-second-div-block-right">
              <img src={mission} alt="" />
            </div>
          </div>
          <div className="about-us-second-div-block-two">
            <div className="about-us-second-div-block-right">
              <img src={vission} alt="" />
            </div>
            <div className="about-us-second-div-block-left">
              <h2>OUR VISION</h2>
              <h4>
                Redefining Global Trade, Inspiring Growth, and Empowering
                Businesses for a Thriving Future.
              </h4>
              <p>
                Business World Trade is to be the leading global B2B platform
                that empowers businesses to thrive and succeed in the digital
                age. We envision a future where trade barriers are minimized,
                connections are effortlessly made, and businesses can access a
                world of opportunities with ease. We strive to be at the
                forefront of innovation, continuously evolving our platform to
                meet the changing needs of the business community. By enabling
                seamless collaboration and facilitating trusted transactions, we
                aspire to be the driving force behind the growth and prosperity
                of businesses worldwide.
              </p>
            </div>
          </div>
          <div className="about-us-second-div-block">
            <div className="about-us-second-div-block-left">
              <h2>OUR GOALS</h2>
              <h4>
                Empowering Businesses, Fostering Collaboration, and Driving
                Global Success through Innovative Solutions
              </h4>
              <p>
                Business World Trade is to revolutionize the B2B trading
                landscape by providing a robust and seamless platform for
                businesses to connect, grow, and thrive. We aim to foster
                business growth by expanding their reach and opening new
                markets. Our platform enables seamless transactions, promoting
                secure and transparent communication between buyers and sellers.
                With a focus on trust, transparency, and fair trade practices,
                we strive to build long-lasting relationships and facilitate
                collaborations that drive mutual success.
              </p>
            </div>
            <div className="about-us-second-div-block-right">
              <img src={goal} alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* LAST_DIV */}
      <div className="about-us-last-div my-5">
        <div className="about-us-last-div-section">
          <TbCategory style={{ color: "red", fontSize: "43px" }} />
          <h4 className="about-us-last-div-section-h">80,000+ </h4>
          <p className="about-us-last-div-section-p">Product Categories</p>
        </div>
        <div className="about-us-last-div-section">
          <FaUsers style={{ color: "red", fontSize: "45px" }} />
          <h4 className="about-us-last-div-section-h">1 Million+ </h4>
          <p className="about-us-last-div-section-p">Registered Users</p>
        </div>
        <div className="about-us-last-div-section">
          <BsFillChatTextFill style={{ color: "red", fontSize: "40px" }} />
          <h4 className="about-us-last-div-section-h">1000+ Inquiries</h4>
          <p className="about-us-last-div-section-p">Every Day</p>
        </div>
        <div className="about-us-last-div-section">
          <BiSupport style={{ color: "red", fontSize: "40px" }} />
          <h4 className="about-us-last-div-section-h">24/7</h4>
          <p className="about-us-last-div-section-p">Customer Support</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AboutUS;
