import React from "react";
import { useEffect } from "react";
import star from "../Components/images/star icon-01.jpg";
import silver from "../Components/images/silver icon-01.jpg";
import premium from "../Components/images/premium icon-01.jpg";
import sapphire from "../Components/images/sapphire icon-01.jpg";
import gold from "../Components/images/gold icon-01.jpg";
import platinum from "../Components/images/platinum icon-01.jpg";
import standard from "../Components/images/standard icon-01.jpg";
import diamond from "../Components/images/diamond icon-01.jpg";
import starpdf from "../Components/FooterPages/PDFfiles/STAR.pdf";
import diamondpdf from "../Components/FooterPages/PDFfiles/DIAMOND.pdf";
import standardpdf from "../Components/FooterPages/PDFfiles/STANDARD.pdf";
import platinumpdf from "../Components/FooterPages/PDFfiles/PLATINUM .pdf";
import premiumpdf from "../Components/FooterPages/PDFfiles/PREMIUM new.pdf";
import sapphirepdf from "../Components/FooterPages/PDFfiles/SAPHIRE.pdf";
import silverpdf from "../Components/FooterPages/PDFfiles/SILVER.pdf";
import goldpdf from "../Components/FooterPages/PDFfiles/GOLD.pdf";
// import platinumpdf from "../Components/FooterPages/PDFfiles/PLATINUM.pdf";
import { Link } from "react-router-dom";

const Packges = () => {
  const wrapperCardStyle = {
    width: "100%", // Set the width
    height: "200px", // Set the height
    padding: "0px",
    margin: "10px", // Set the padding
    backgroundColor: "#f0f0f0", // Example background color
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const products = [
    {
      id: "1",
      name: "STAR PACKAGE",
      img: `${star}`,
      pdf: `${starpdf}`,
      link: "/star",
    },
    {
      id: "2",
      name: "SILVER PACKAGE",
      img: `${silver}`,
      pdf: `${silverpdf}`,
      link: "/silver",
    },
    {
      id: "3",
      name: "GOLD PACKAGE",
      img: `${gold}`,
      pdf: `${goldpdf}`,
      link: "/gold",
    },
    {
      id: "4",
      name: "BRONZE PACKAGE",
      img: `${standard}`,
      pdf: `${standardpdf}`,
      link: "/bronze",
    },
    {
      id: "5",
      name: "DIAMOND PACKAGE",
      img: `${diamond}`,
      pdf: `${diamondpdf}`,
      link: "/diamond",
    },
    {
      id: "6",
      name: "PALTINUM PACKAGE",
      img: `${platinum}`,
      pdf: `${platinumpdf}`,
      link: "/platinum",
    },
    {
      id: "7",
      name: "PREMIUM PACKAGE",
      img: `${premium}`,
      pdf: `${premiumpdf}`,
      link: "/premium",
    },
    {
      id: "8",
      name: "SAPPHIRE PACKAGE",
      img: `${sapphire}`,
      pdf: `${sapphirepdf}`,
      link: "/sapphire",
    },
  ];

  return (
    <>
      {/* about banner start */}
      <div className="col-12 admin-dash-cont">
        {/* about banner start */}
        <div className="content-wrapper" style={wrapperCardStyle}>
          <div className="advertise-with-us">
            {products.map((product, index, key) => (
              <div className="wrapper-card " key={index} id={key}>
                <div className="card front-face">
                  <img src={product.img} alt="" />
                </div>
                <div className="card back-face container-p">
                  <img src={product.img} alt="" className="image-p img-fluid" />
                  <div className="overlay-p">
                    <a href={product.pdf}>View Package</a>
                  </div>

                  <div className="info">
                    <div className="title">{product.name}</div>
                    <Link to={product.link}>
                      <button className="me-2" style={{ padding: "11px 15px" }}>
                        Benefits
                      </button>
                    </Link>
                    <Link to="/checkout">
                      <button
                        className="ms-2 me-2"
                        style={{ padding: "11px 15px" }}
                      >
                        Buy Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Packges;
