export const ALL_PRODUCT_REQUEST = "ALL_PRODUCT_REQUEST";
export const ALL_PRODUCT_SUCCESS = "ALL_PRODUCT_SUCCESS";
export const ALL_PRODUCT_FAIL = "ALL_PRODUCT_FAIL";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//get all products in sllerpanel

export const Getallproducts = "Getallproducts";
export const getproductsReq = "getproductsReq";
//products  add here
export const Addproducts = "Add_products";
export const contactprofile = "contactprofile";
export const Contactaddreq = "Contactaddreq";

export const businessprofilee = "businessprofilee";
export const sellerDocsInfo = "sellerDocsInfo";
export const sellerBankdetailsInfo = "sellerBankdetailsInfo";

export const GET_USER_BYID_REQUEST = "GET_USER_BYID_REQUEST";
export const GET_USER_BYID_SUCCESS = "GET_USER_BYID_SUCCESS";
export const GET_USER_BYID_FAILURE = "GET_USER_BYID_FAILURE";

// add product
export const ADD_PRODUCT_REQUEST = " ADD_PRODUCT_REQUEST";
export const ADD_PRODUCT_SUCCESS = " ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_FAILURE = "ADD_PRODUCT_FAILURE";

export const GET_USER_upade_REQUEST = "GET_USER_BYID_REQUEST";
export const GET_USER_update_SUCCESS = "GET_USER_BYID_SUCCESS";
export const GET_USER_update_FAILURE = "GET_USER_BYID_FAILURE";

export const Deleteproducts = "Deleteproducts";

//authentication constant>>>>>>>>>

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";

// authentication constant login >>>>>>>>>>

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAIL = "SIGNIN_FAIL";

///update role

export const UpdateRoleReq = "UpdateRoleReq";
export const UpdateRoleSuccess = "UpdateRoleSuccess";
export const UpdateRoleFail = "UpdateRoleFail";

// Admin constants
export const FETCH_SELLER_DETAILS_SUCCESS = "FETCH_SELLER_DETAILS_SUCCESS";
export const FETCH_SELLER_DETAILS_FAILURE = "FETCH_SELLER_DETAILS_FAILURE";

//Admi edit

export const GET_SELLER_BYID_SUCCESS = "GET_SELLER_BYID_SUCCESS";
export const GET_SELLER_UPDATE_SUCCESS = "GET_USER_UPDATE_SUCCESS";
export const DeleteContactProfile = "DeleteContactProfile";

export const LoginAdminSucces = "LoginAdminSucces";
export const AddContactProfile = "AddContactProfille";
export const FailContactProfile = "FailContactProfile";

//getcontactprfoeldatabyid single user???????????????

export const GetcontactprfilebyUserLogin_req =
  "GetcontactprfilebyUserLogin_req";
export const GetcontactprfilebyUserLogin_success =
  "GetcontactprfilebyUserLogin_success";
export const GetcontactprfilebyUserLogin_fail =
  "GetcontactprfilebyUserLogin_fail";

//middleware for form submission

export const FormReq = "FormReq";
export const FormSuccess = "FormSuccess";
export const FormReFail = "FormReFail";
export const GetFormReq = "FormReq";
export const GetFormSuccess = "FormSuccess";
export const GetFormReFail = "FormReFail";
export const CreateCategory_Req = "CreateCategory_Req";
export const CreateCategory_Succes = "CreateCategory_Succes";
export const CreateCategory_Fails = "CreateCategory_Fails";
