import React, { useState, useEffect } from "react";
import Navbar from "../Components/Homepage/Navbar";
import Footer from "../Components/Homepage/Footer";
import { Row, Col, Button, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import logo from "../Components/images/bwt-dark.png";
import Carousel from "react-bootstrap/Carousel";
import BWT2 from "../Components/images/BWT2.jpeg";
import { FiPhoneCall } from "react-icons/fi";
import { BiHelpCircle } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Catalogue = () => {
  const pstyle = {
    textAlign: "center",
    color: "black",
    fontWeight: "light",
    fontSize: "17px",
  };
  const spanstyle = {
    fontSize: "18px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };

  const data = [
    {
      name: "aaaaaaaaaaaaaaa",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1200px-Cat03.jpg",
    },
    {
      name: "bbbbbbbbbbbbbbb",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1200px-Cat03.jpg",
    },
    {
      name: "cccccccccccccc",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1200px-Cat03.jpg",
    },
    {
      name: "ddddddddddddddd",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1200px-Cat03.jpg",
    },
    {
      name: "eeeeeeeeeeee",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1200px-Cat03.jpg",
    },
    {
      name: "fffffffffff",
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/3/3a/Cat03.jpg/1200px-Cat03.jpg",
    },
  ];
  // const { businessName } = useParams();
  const location = useLocation();
  const productData = location.state?.productData;
  // console.log("productData", productData);
  const { userProfileId } = useParams();
  // console.log("productData", productData);
  const [catalogue, setCatalogue] = useState({});
  // const [catalogueUserId, setCatalogueUserId] = useState({});
  const [userProfileData, setUserProfileData] = useState({});
  const [contactProfile, setContactProfile] = useState({});
  const [userProducts, setUserProducts] = useState({});
  const [catalogueId, setCatalogueId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const catalogueResponse = await axios.get(
          `http://localhost:5000/api/getCatalogue`
        );
        setCatalogue(catalogueResponse.data);
        // console.log("catalogue", catalogueResponse.data);
        if (
          catalogueResponse.data.data &&
          catalogueResponse.data.data.length > 0
        ) {
          const userId = catalogueResponse.data.data[0].userId;
          const contactprofileuserId =
            catalogueResponse.data.data[0].contactProfile.userId;
          // console.log("contactprofileuserId", contactprofileuserId);
          // setCatalogueUserId(userId);

          if (contactprofileuserId === userProfileId) {
            console.log("catalogueUserId is equal to userProfileId");
            setCatalogueId(catalogueResponse.data.data[0]._id);
            setUserProducts(catalogueResponse.data.data[0]);
            console.log(
              "userProducts",
              catalogueResponse.data.data[0].userProducts
            );
            // Check if userId, userProfileId, and contactprofileuserId are equal
            if (
              userId === userProfileId &&
              userProfileId === contactprofileuserId
            ) {
              // Set the ContactProfile here
              setContactProfile(catalogueResponse.data.data[0].contactProfile);
              // console.log(
              //   "contactProfile",
              //   catalogueResponse.data.data[0].contactProfile
              // );
            }
          } else {
            console.log("catalogueUserId is not equal to userProfileId");
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userProfileId]);

  useEffect(() => {
    if (catalogueId) {
      const fetchData = async () => {
        try {
          // Fetch additional data using catalogueId
          const userDataResponse = await axios.get(
            `https://demo.businessworldtrade.com/api/getCatalogue/${catalogueId}`
          );
          setUserProfileData(userDataResponse.data);
          console.log("Fetched user profile data:", userDataResponse.data);
          // Access the userProducts array
          const userProducts = userDataResponse.data.userProducts;

          // Check if userProducts is an array and has items
          if (Array.isArray(userProducts) && userProducts.length > 0) {
            // Access the first item in userProducts
            const firstProduct = userProducts[0];

            // Access the productPictures array within the first product
            const productPictures = firstProduct.productPictures;

            // Check if productPictures is an array and has items
            if (Array.isArray(productPictures) && productPictures.length > 0) {
              // Access the 0 index image (img) within productPictures
              const firstImage = productPictures[0].img;
              console.log("First image URL:", firstImage);
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [catalogueId]);

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <Navbar />

      <div>
        <div
          className="d-flex p-2 justify-content-around align-items-center"
          style={{ boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px" }}
        >
          <h6 className="my-0">{contactProfile.companyname}</h6>
          <h6 className="my-0">
            {contactProfile.packageType} Member since{" "}
            <span style={{ color: "red" }}>{contactProfile.YearofEst}</span>
          </h6>
          <Button variant="secondary">Contact Now</Button>
        </div>
        <div className="d-flex flex-wrap justify-content-around align-items-center my-3">
          <div className="w-25">
            <img
              src={logo}
              alt="error"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </div>
          <div>
            <h5>
              Designated{" "}
              <span style={{ color: "red" }}>
                {contactProfile.BusinessType}
              </span>{" "}
              since{" "}
              <span style={{ color: "red" }}>{contactProfile.YearofEst}</span>
            </h5>
          </div>
        </div>{" "}
        {/* BANNER SECTION */}
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item>
            <img
              src={BWT2}
              text="First slide"
              alt="error"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={BWT2}
              text="Second slide"
              alt="error"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              src={BWT2}
              text="third slide"
              alt="error"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </Carousel.Item>
        </Carousel>
        {/* PRODUCTS */}
        <h2 className="text-center my-4 catalog-about-heading">Products</h2>
        <Container>
          <Row className=" my-2">
            {productData?.results && productData.results.length > 0 ? (
              productData.results.map((product) => (
                <Col md={4}>
                  <Card style={{ width: "18rem", margin: "auto" }}>
                    {product.productPictures &&
                    product.productPictures.length > 0 ? (
                      <Card.Img
                        className="p-3"
                        variant="top"
                        src={`http://localhost:5000/${product.productPictures[0].img}`} // Use [0] to get the first image
                        alt={`Product Image`}
                      />
                    ) : (
                      <div className="my-3 mx-3 container-row-column-two-div">
                        <img
                          style={{
                            height: "35vh",
                            width: "70vh",
                          }}
                          src={"#"}
                          alt=""
                          className="img-fluid carousel-img"
                        />
                      </div>
                    )}
                    <Card.Body>
                      <Card.Title>{product.name}</Card.Title>
                      <Card.Text>{product.description}</Card.Text>
                    </Card.Body>
                    <ListGroup className="list-group-flush">
                      <ListGroup.Item>
                        {productData.results[0].price}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        {productData.results[0].minOrder}
                      </ListGroup.Item>
                    </ListGroup>
                    <Card.Body>
                      <Card.Link href="#">Contact Now</Card.Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <></>
            )}
            <Col md={8}>
              <div className="d-flex flex-wrap justify-content-evenly">
                {userProfileData?.data?.userProducts?.length > 0 ? (
                  userProfileData.data.userProducts.map((product, index) => (
                    <Card
                      style={{ width: "14rem", padding: "10px" }}
                      key={index}
                    >
                      {product.productPictures &&
                      product.productPictures.length > 0 ? (
                        <Card.Img
                          variant="top"
                          src={`http://localhost:5000/${product.productPictures[0].img}`} // Use [0] to get the first image
                          alt={`Product Image`}
                        />
                      ) : (
                        <div className="my-3 mx-3 container-row-column-two-div">
                          <img
                            style={{
                              height: "35vh",
                              width: "70vh",
                            }}
                            src={"#"}
                            alt=""
                            className="img-fluid carousel-img"
                          />
                        </div>
                      )}
                      <Card.Body>
                        <Card.Title>{product.name}</Card.Title>
                        <Button variant="primary">Go somewhere</Button>
                      </Card.Body>
                    </Card>
                  ))
                ) : (
                  <p>No products found.</p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {/* ABOUT */}
        {userProfileData && userProfileData.data && (
          <>
            <h2 className="text-center my-4 catalog-about-heading">
              About companyname
            </h2>
            <div className="w-100  px-5 catalogue-div-about-us-div">
              <div className="text-center px-5 catalogue-div-about-us-div-video">
                <video
                  width="850"
                  height="500"
                  controls
                  style={{ objectFit: "cover" }}
                >
                  <source src="https://youtu.be/Kb8CW3axqRE" type="video/mp4" />
                </video>
              </div>
              <div className="catalogue-div-about-us-div-profile">
                <h3 style={{ color: "#313131" }}>Company Profile</h3>
                <div className="my-3 scroll-container">
                  <p className="text-left text-secondary">
                    {userProfileData.data.companyDesc}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
        {/* CONTACT US */}
        <h2 className="text-center my-4 catalog-about-heading">Contact Us</h2>
        <Container>
          <Row>
            <Col>
              <div className="contact-us-container-catalogue">
                <div className="form-container-catalogue">
                  <h2>Contact Us</h2>
                  <form>
                    <div className="form-group-catalogue">
                      <label htmlFor="from">From:</label>
                      <input type="text" id="from" name="from" required />
                    </div>
                    <div className="form-group-catalogue">
                      <label htmlFor="to">To:</label>
                      <input type="text" id="to" name="to" required />
                    </div>
                    <div className="form-group-catalogue">
                      <label htmlFor="message">Message:</label>
                      <textarea id="message" name="message" required></textarea>
                    </div>
                    <button type="submit">Send Message</button>
                  </form>
                </div>
              </div>
            </Col>

            <Col className="d-flex align-items-center">
              <iframe
                className="contact-us-map"
                title="Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7017.587495097762!2d77.32073398885588!3d28.42547973848462!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cdda23d188a31%3A0x9ed9bf2084d105b6!2sBasilva%20Colony%2C%20Old%20Faridabad%2C%20Faridabad%2C%20Haryana%20121002!5e0!3m2!1sen!2sin!4v1690889790453!5m2!1sen!2sin"
                width="500"
                height="450"
                style={{ margin: "auto" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </Col>
          </Row>
        </Container>
        {/* CONTACT DETAILS */}
        <Container className="my-4 px-5">
          <div
            className="row"
            style={{
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
          >
            <h4 className="text-center my-2 ">Connect With Us</h4>
            <div className="col-md-4 col-sm-12 my-2">
              <p style={pstyle}>
                <FiPhoneCall style={{ margin: "auto", color: "orange" }} />
                Customer Care No.
                <span style={spanstyle}>
                  <br />
                  {contactProfile.phone}{" "}
                </span>
              </p>
            </div>
            <div className="col-md-4 col-sm-12 my-2">
              <p style={pstyle}>
                <BiHelpCircle style={{ margin: "auto", color: "orange" }} />
                Help
                <span style={spanstyle}>
                  <br />
                  {contactProfile.Altphone}
                </span>
              </p>
            </div>
            <div className="col-md-4 col-sm-12 my-2">
              <p style={pstyle}>
                <FiMail style={{ margin: "auto", color: "orange" }} />
                Write To Us
                <span style={spanstyle}>
                  <br />
                  {contactProfile.email}
                </span>
              </p>
            </div>
          </div>
        </Container>
        {/* ADDRESS DETAILS */}
        <Container className="my-4 px-5">
          <Row
            style={{
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            }}
          >
            <Col
              md={6}
              className="border-right px-5 text-center my-3 address-details-column"
            >
              <h5 className="text-center text-decoration-underline">
                Office Address
              </h5>
              <p>Adress Line</p>
              <p>Landmark Location</p>
              <p>{contactProfile.city}</p>
              <h6>
                {contactProfile.zipcode}, {contactProfile.state}
              </h6>
            </Col>
            <Col md={6} className="px-5 text-center my-3">
              <h5 className="text-center text-decoration-underline">
                Authentication
              </h5>
              <p>
                {contactProfile.BusinessType} since {contactProfile.YearofEst}
              </p>
              <p>Our "{contactProfile.packageType}Member" since "year"</p>
              <p>Main Products listed here</p>
              <p>{contactProfile.ifscCode}</p>
            </Col>
          </Row>
        </Container>
        {/* WEBSITE LINK */}
        <div className="w-75 m-auto">
          <h2 className="text-center my-4 catalog-about-heading">
            For More Info Visit Our Website
          </h2>
          <p className="text-center">
            <a href="http://localhost:3000/product-det">
              `http://localhost:3000/product-det`
            </a>
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Catalogue;
