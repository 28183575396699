import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const TopCategory = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          "https://demo.businessworldtrade.com/api/getcategory"
        );
        console.log("response Data", response);
        setCategories(response.data);
        // console.log("Fetched Categories:", response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const limitedCategories = categories.slice(0, 12);

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(`/category/${categoryId}/${categoryName}`);
  };

  return (
    <>
      <h1 className="privacy-policy my-3">Top Categories</h1>
      <div className="top-category pb-5">
        {limitedCategories.map((category) => (
          <div className=" Wrapper rounded mt-3" key={category._id}>
            <a
              href="#!" // Use '#' to prevent page reload (assuming you're not using React Router Link)
              onClick={() => handleCategoryClick(category._id, category.name)}
            >
              <div className="Card rounded">
                {category.images.length > 0 && (
                  <img
                    src={`https://demo.businessworldtrade.com/${category.images[0].img}`}
                    alt={category.name}
                  />
                )}
                <div>
                  <h6 className="p-2 ps-4 text-center mt-2">{category.name}</h6>
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default TopCategory;
