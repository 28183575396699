import React, { useEffect } from "react";
import NavbarHeader from "../../Homepage/Navbar";
import Footer from "../../Homepage/Footer";
import background from "../../images/web-design-banner.jpg";
export const Star = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    textAlign: "left",
    color: "grey",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  return (
    <>
      <NavbarHeader />
      <>
        {/* about banner start */}
        <div
          className="container-fluid webdesigning-banner gap py-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: " no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="row py-5">
            <div className="col-12 text-center text-light pt-3">
              <h5>WE ENHANCING YOUR BUSINESS </h5>
            </div>
            <div className="col-12 text-center  pb-3 banner-head">
              <h1 className="text-light">STAR PACKAGE</h1>
            </div>
          </div>
        </div>
        {/* about banner start */}
        {/* PACkage start */}
        <h2 className=" my-5 privacy-policy text-center">
          BENEFITS Of STAR PACKAGE
        </h2>
        <div
          className="container my-5"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="row px-2">
            <div className="col-sm-12 col-12 pt-4 mt-2 mb-3">
              {/* <h2 className="package-head text-center">
                Benifits Of STAR PACKAGE
              </h2> */}
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Website
                </span>
                <br />
                Get a professionally designed and visually appealing website
                that showcases your products or services effectively.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Domain Registration{" "}
                </span>
                <br />
                Register a unique domain name that represents your brand and
                helps establish a strong online presence.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Favicon{" "}
                </span>
                <br />
                Add a custom favicon to your website, creating a distinctive
                brand identity and improving recognition.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Logistic Quotation{" "}
                </span>
                <br />
                Integrate logistic quotation services to provide customers with
                seamless shipping options and transparent cost calculations.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Language Converter{" "}
                </span>
                <br />
                Offer multilingual support with a language converter feature,
                allowing visitors to view your website in their preferred
                language.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Unlimited Product Listings
                </span>
                <br />
                Display an unlimited number of products with detailed
                descriptions, images, and specifications, enabling customers to
                explore your complete product range.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Mobile Version{" "}
                </span>
                <br />
                Ensure your website is optimized for mobile devices, providing a
                user-friendly experience to visitors accessing your site on
                smartphones and tablets.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Google AdWords
                </span>
                <br />
                Drive targeted traffic to your website through strategically
                managed Google AdWords campaigns, increasing visibility and
                attracting potential customers.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  On-Page SEO{" "}
                </span>
                <br />
                Optimize your website's on-page elements, such as meta tags,
                headings, and content, to improve search engine rankings and
                organic traffic.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Off-Page SEO
                </span>
                <br />
                Implement off-page SEO techniques to enhance your website's
                online visibility and reputation, including link building and
                social media integration.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Category Banners{" "}
                </span>
                <br />
                Highlight three key categories or featured products with
                visually appealing banners, attracting attention and improving
                navigation.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  SSL Certificate
                </span>
                <br />
                Secure your website with an SSL certificate, ensuring data
                encryption and building trust among visitors.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Sponsored Banners{" "}
                </span>
                <br />
                Promote your business with sponsored banners on relevant
                platforms, increasing brand visibility and reaching a wider
                audience.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Lead Generation
                </span>
                <br />
                Capture valuable leads through inquiry forms on your website,
                enabling follow-up and conversion into potential sales.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Export Bill Discounting{" "}
                </span>
                <br />
                Access specialized financial services such as export bill
                discounting, helping you manage cash flow and optimize business
                operations.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Email{" "}
                </span>
                <br />
                Establish a professional email address using your domain name,
                enhancing brand credibility and facilitating effective
                communication.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Category Image Banner
                </span>
                <br />
                Enhance category pages with visually appealing image banners,
                providing a captivating browsing experience for customers.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Social Media Campaign{" "}
                </span>
                <br />
                Run targeted social media campaigns to promote your business,
                engage with your audience, and increase brand awareness.
              </p>
              <p style={pstyle}>
                The Star Package offers a comprehensive suite of features and
                services to empower your business online. From a professional
                website and domain registration to logistic quotation
                integration, language conversion, unlimited product listings,
                mobile optimization, Google AdWords, on-page and off-page SEO,
                category banners, SSL certificate, sponsored banners, lead
                generation, export bill discounting, professional email,
                category image banners, and social media campaigns, this package
                equips your business with the tools needed to thrive in the
                digital landscape.
              </p>
            </div>
          </div>
        </div>
        {/* PACkage end */}
      </>

      <Footer />
    </>
  );
};
