import { MdLocationOff } from "react-icons/md";
import { FcAlarmClock } from "react-icons/fc";

const Productdetails = () => {
  return (
    <>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content" style={{ backgroundColor: "#0e5176" }}>
          <div className="heading">
            <div className="products">
              <p style={{ fontSize: "25px" }}>
                {" "}
                Product Relevent Leads for BuyLeads you might be interested.
              </p>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}

        <div className="card cards mt-4 mx-4" style={{ width: "" }}>
          <div className="clock" style={{ backgroundColor: "#dc3545" }}>
            <h5 className="mx-2 mt-2" style={{ color: "white" }}>
              {" "}
              <FcAlarmClock /> 3 months ago 03 Sep, 22
            </h5>
          </div>

          <div className="card-body">
            <div className="product-relevant">
              <div className="relevan">
                <div className="">
                  <h4>
                    <MdLocationOff /> India Champaran Bihar
                  </h4>
                </div>

                <div className="">
                  <h4
                    style={{
                      color: "#dc3545",
                      fontSize: "20px",
                      fontWeight: 600,
                    }}
                  >
                    Buyer Details
                  </h4>
                  <h4 style={{ color: "grey", fontSize: "20px" }}>Aman</h4>
                </div>

                <div className="relevants">
                  <div className="">
                    <div className="">
                      <p style={{ fontWeight: "550" }}>Member:</p>
                    </div>
                    <div className="">
                      {" "}
                      <p style={{ fontWeight: "550" }}>Buyer: </p>{" "}
                    </div>
                    <div className="">
                      {" "}
                      <p style={{ fontWeight: "550" }}>Mobile: </p>{" "}
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <p className="mx-1" style={{ fontWeight: "450" }}>
                        Resale wholesale
                      </p>
                    </div>

                    <div className="">
                      <p className=" mx-2" style={{ fontWeight: "450" }}>
                        {" "}
                        East champaran bihar
                      </p>
                    </div>
                    <div className="">
                      <p className=" mx-2" style={{ fontWeight: "450" }}>
                        {" "}
                        weekly
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relevants  mx-3">
                <div className="">
                  <div className="">
                    <p style={{ fontWeight: "550" }}> I Want To Buy :</p>
                  </div>
                  <div className="">
                    {" "}
                    <p style={{ fontWeight: "550" }}>Quantity :</p>{" "}
                  </div>
                  <div className="">
                    {" "}
                    <p style={{ fontWeight: "550" }}>Requirement Type :</p>{" "}
                  </div>
                </div>
                <div className="">
                  <div className="">
                    <p>Mustard Oil</p>
                  </div>
                  <div className="">
                    {" "}
                    <p> Ton</p>{" "}
                  </div>
                  <div>
                    {" "}
                    <p className=""> Monthly</p>{" "}
                  </div>
                </div>
              </div>

              <div className="relevants">
                <div className="">
                  <div className="">
                    <p style={{ fontWeight: "550" }}>Purpose:</p>
                  </div>
                  <div className="">
                    <p style={{ fontWeight: "550" }}>Preferred Location:</p>{" "}
                  </div>
                  <div className="">
                    {" "}
                    <p style={{ fontWeight: "550" }}>Want To Buy:</p>{" "}
                  </div>
                </div>
                <div className="">
                  <div>
                    <p>Resale wholesale</p>
                  </div>

                  <div className="">
                    <p className=" mx-2"> East champaran bihar</p>
                  </div>
                  <div className="">
                    <p className=""> weekly</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <p>
                Description: I want to buy this product kindly send your
                quotation on urgent basis....
              </p>
            </div>

            <div className=""></div>
          </div>

          <div className="relevantbtn container">
            <div className="">
              <button type="button" className="btn btn-danger">
                Contact Buyer Now
              </button>
            </div>
            <div className="">
              <button type="button" className="btn btn-primary">
                Add to Shortlist
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Productdetails;
