import React, { useEffect, useState } from "react";
import NavbarHeader from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import callback from "../images/requirement.jpg";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";
export const PostbyRequirement = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        "https://demo.businessworldtrade.com/api/postbyrequirement",
        formData
      )
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));

    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };

  return (
    <>
      <NavbarHeader />
      <h3 className="text-center privacy-policy my-3">Post By Requirement</h3>
      <div className="container my-3 w-100 d-flex">
        <div className="container-fluid  ">
          <div className="row">
            <div
              className="col-sm-12 col-md-6"
              style={{
                // border: "1px solid red",
                margin: "auto",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                height: "auto",
                border: "none",
              }}
            >
              <Form className=" mt-4" onSubmit={handleSubmit}>
                <div className="row">
                  <Form.Group
                    className=" col-sm-12 col-md-6 mb-2 "
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Product Name
                    </Form.Label>
                    <Form.Control
                      placeholder="Product Name"
                      aria-label="Product Name"
                      aria-describedby="basic-addon1"
                      name="productName"
                      value={formData.productName || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="mb-4 col-sm-12 col-md-6"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      MOQ (Kg/Tons)
                    </Form.Label>

                    <Form.Control
                      placeholder="MOQ (Kg/Tons)"
                      aria-label="MOQ (Kg/Tons)"
                      aria-describedby="basic-addon2"
                      name="moq"
                      value={formData.moq || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Quality / Size / Specification
                    </Form.Label>

                    <Form.Control
                      placeholder="Quality / Size / Specification"
                      aria-label="Quality / Size / Specification"
                      aria-describedby="basic-addon1"
                      name="quality"
                      value={formData.quality || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Sale / Purchase
                    </Form.Label>

                    <Form.Control
                      placeholder="Sale / Purchase"
                      aria-label="Sale / Purchase"
                      aria-describedby="basic-addon1"
                      name="sale"
                      value={formData.sale || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Requirement Frequency
                    </Form.Label>

                    <Form.Control
                      placeholder="Requirement Frequency"
                      aria-label="Requirement Frequency"
                      aria-describedby="basic-addon1"
                      name="requirement"
                      value={formData.requirement || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Packaging Size
                    </Form.Label>

                    <Form.Control
                      placeholder="Packaging Size"
                      aria-label="Packaging Size"
                      aria-describedby="basic-addon1"
                      name="packaging"
                      value={formData.packaging || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Payment Terms
                    </Form.Label>

                    <Form.Control
                      placeholder="Payment Terms"
                      aria-label="Payment Terms"
                      aria-describedby="basic-addon1"
                      name="payment"
                      value={formData.payment || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Purpose Of Requirement
                    </Form.Label>

                    <Form.Control
                      placeholder="Purpose Of Requirement"
                      aria-label="Purpose Of Requirement"
                      aria-describedby="basic-addon1"
                      name="purpose"
                      value={formData.purpose || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Location
                    </Form.Label>

                    <Form.Control
                      placeholder="Location"
                      aria-label="Location"
                      aria-describedby="basic-addon1"
                      name="location"
                      value={formData.location || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Description
                    </Form.Label>

                    <Form.Control
                      placeholder="Description"
                      aria-label="Description"
                      aria-describedby="basic-addon1"
                      name="description"
                      value={formData.description || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Delivery Place
                    </Form.Label>

                    <Form.Control
                      placeholder="Delivery Place"
                      aria-label="Delivery Place"
                      aria-describedby="basic-addon1"
                      name="deliveryPlace"
                      value={formData.deliveryPlace || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Email
                    </Form.Label>

                    <Form.Control
                      placeholder="Email"
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      name="email"
                      value={formData.email || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <div className="row">
                  <Form.Group
                    className="col-sm-12 col-md-6 mb-4"
                    controlId="formBasicEmail"
                  >
                    <Form.Label
                      style={{
                        display: "flex",
                        flexDirection: "left",
                        fontSize: "19px",
                        fontWeight: "500",
                      }}
                    >
                      Phone No.
                    </Form.Label>

                    <Form.Control
                      placeholder="Phone No."
                      aria-label="Phone No."
                      aria-describedby="basic-addon1"
                      name="phone"
                      value={formData.phone || ""}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </div>
                <Button
                  className="my-2 "
                  variant="outline-primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
            <div className="col-sm-12 col-md-6 d-flex mt-2">
              <img src={callback} className="img-fluid " alt="downloading..." />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
