import React, { useEffect } from "react";
import NavbarHeader from "../../Homepage/Navbar";
import Footer from "../../Homepage/Footer";
import background from "../../images/web-design-banner.jpg";

export const Premium = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    textAlign: "left",
    color: "grey",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  return (
    <>
      <NavbarHeader />
      <>
        {/* about banner start */}
        <div
          className="container-fluid webdesigning-banner gap py-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: " no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="row py-5">
            <div className="col-12 text-center text-light pt-3">
              <h5>WE ENHANCING YOUR BUSINESS </h5>
            </div>
            <div className="col-12 text-center  pb-3 banner-head">
              <h1 className="text-light">PREMIUM PACKAGE</h1>
            </div>
          </div>
        </div>
        {/* about banner start */}
        {/* PACkage start */}
        <h2 className="privacy-policy my-5 text-center">
          BENEFITS OF PREMIUM PACKAGE
        </h2>
        <div
          className="container my-5"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="row px-2">
            <div className="col-sm-12 col-12 pt-4 mt-2 mb-3">
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Website{" "}
                </span>
                <br />
                Get a professionally designed website that reflects your brand
                image and engages visitors.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Domain Registration{" "}
                </span>
                <br />
                Register a unique domain name that represents your business and
                enhances your online presence.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Favicon{" "}
                </span>
                <br />
                Add a custom favicon to your website, creating a recognizable
                brand icon in the browser tab.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Logistic Quotation{" "}
                </span>
                <br />
                Integrate logistic quotation services, enabling customers to
                calculate shipping costs easily.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Language Converter{" "}
                </span>
                <br />
                Provide multilingual support with a language converter feature,
                allowing visitors to view your website in their preferred
                language.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Unlimited Product Listings{" "}
                </span>
                <br />
                Display an unlimited number of products, enabling you to
                showcase your complete product range.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Mobile Version{" "}
                </span>
                <br />
                Optimize your website for mobile devices, ensuring a seamless
                and user-friendly experience for mobile users.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Google My Business (GMB){" "}
                </span>
                <br />
                Set up and optimize your Google My Business profile to enhance
                local visibility and attract more customers.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Chatbot{" "}
                </span>
                <br />
                Implement a chatbot feature to provide instant customer support
                and improve engagement on your website.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Admin Login Panel{" "}
                </span>
                <br />
                Access an easy-to-use admin login panel to manage and update
                your website content conveniently.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Sponsored Banners{" "}
                </span>
                <br />
                Promote your business with sponsored banners on relevant
                platforms, increasing brand visibility and reaching a wider
                audience.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Lead Generation{" "}
                </span>
                <br />
                Capture valuable leads through inquiry forms on your website,
                enabling follow-up and conversion into potential sales.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Email{" "}
                </span>
                <br />
                Establish a professional email address using your domain name,
                enhancing brand credibility and facilitating effective
                communication.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Premium Features{" "}
                </span>
                <br />
                Unlock premium features tailored to your business needs,
                ensuring a competitive edge in the market.
              </p>
              <p style={pstyle}>
                The Premium Package offers a comprehensive solution to elevate
                your online presence and boost your business. From a
                professional website, domain registration, and logistic
                quotation integration to language conversion, unlimited product
                listings, mobile optimization, Google My Business (GMB),
                chatbot, admin login panel, sponsored banners, lead generation,
                and professional email, this package equips your business with
                the necessary tools to succeed in the digital landscape.
              </p>
            </div>
          </div>
        </div>
        {/* PACkage end */}
      </>

      <Footer />
    </>
  );
};
