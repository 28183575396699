import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Pharmaceutical from "./Pharmaceutical";
import Facemask from "../images/homePageImg/facemask.png";
import Eyeshadow from "../images/homePageImg/eyeshadow.png";
import Hydroalcoholic from "../images/homePageImg/Hydroalcoholic.png";
import SunProtection from "../images/homePageImg/sunprotection.png";
import Foundation from "../images/homePageImg/foundation-01.png";
import Mascara from "../images/homePageImg/mascara-01.png";
import Eyeliner from "../images/homePageImg/eyeliner-01.png";
import Lipstick from "../images/homePageImg/lipsticks-01.png";
import Bodycare from "../images/homePageImg/bodycare.png";
const healthBeauty = [
  {
    img: Facemask,
    soldBy: "Rajeev",
    name: "Face mask",
    description: "Wholeseller: Delhi",
  },
  {
    img: Eyeshadow,
    soldBy: "Mahesh",
    name: "Eye shadow",
    description: "Wholeseller: Jaipur, Rajasthan",
  },
  {
    img: Hydroalcoholic,
    soldBy: "Kamal garg",
    name: "Hydroalcoholic perfumes",
    description: "Wholeseller: Agra, U.P",
  },
  {
    img: SunProtection,
    soldBy: "Sital Das",
    name: "Sun Protection",
    description: "Retailer: West Bengal",
  },
  {
    img: Foundation,
    soldBy: "Sushant",
    name: "Foundation ",
    description: "Trader: Delhi",
  },
  {
    img: Mascara,
    soldBy: "Navkar",
    name: "Mascara ",
    description: "Retailer: Indore",
  },
  {
    img: Eyeliner,
    soldBy: "Alok singh",
    name: "Eye liner",
    description: "Wholeseller: Uttar Pradesh",
  },
  {
    img: Lipstick,
    soldBy: "Sajal Roychowdhury",
    name: "Lip stick",
    description: "Wholeseller: Kolktata",
  },
  {
    img: Bodycare,
    soldBy: "Bhima Darade",
    name: "Body care",
    description: "Trader: Maharashtra",
  },
  // Add your additional products here
];

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1024, min: 800 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Pharmaceuticals = () => {
  const productList = healthBeauty.map((item, index) => (
    <Pharmaceutical
      key={index}
      name={item.name}
      img={item.img}
      soldBy={item.soldBy}
      desc={item.description}
    />
  ));

  return (
    <div>
      <h1
        className="mt-5 mb-3"
        style={{
          color: "#444444",
          fontWeight: "700",
        }}
      >
        Health & Beauty
      </h1>
      <Carousel responsive={responsive}>{productList}</Carousel>
    </div>
  );
};

export default Pharmaceuticals;
