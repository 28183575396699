import React from "react";
// import axios from "axios";
import "bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FeaturedProduct from "./FeaturedProduct";
import Dryturmeric from "../images/homePageImg/dryturmeric.jpg";
import CorianderSeed from "../images/homePageImg/Corianderseed.jpg";
import Cuminseed from "../images/homePageImg/Cuminseed.jpg";
// import Haldi from "../images/homePageImg/haldi.jpeg"
import FillingCapping from "../images/homePageImg/Fillin-Capping.jpg";
import MensJeans from "../images/homePageImg/Mens-jeans.jpg";
import SSutensils from "../images/homePageImg/Ss-utensils.jpg";

const FeaturedProducts = () => {
  const featuredProducts = [
    {
      img: Dryturmeric,
      soldBy: "Anil Jain",
      name: "Turmeric Powder",
      description: "Wholeseller: Bhayandar Maharashtra",
    },
    {
      img: CorianderSeed,
      soldBy: "Lokesh",
      name: "Coriander Seed",
      description: "Wholeseller: Delhi",
    },
    {
      img: FillingCapping,
      soldBy: "Pardeep Gupta",
      name: "Filling Capping Machine",
      description: "Wholeseller: Jammu",
    },
    {
      img: Cuminseed,
      soldBy: "Pankaj",
      name: "Cumin Seed",
      description: "Wholeseller: Jaisalmer",
    },
    {
      img: SSutensils,
      soldBy: "ASHOK",
      name: "S.S UTENSILS",
      description: "Wholeseller: Puducherry",
    },
    {
      img: MensJeans,
      soldBy: "Shadab Khan",
      name: "Mens Jeans",
      description: "Wholeseller: Delhi",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 0,
    },
  };

  return (
    <div>
      <h1
        className="my-3 mt-4"
        style={{
          color: "#444444",
          fontSize: "40px",
          fontWeight: "700",
        }}
      >
        Featured Products
      </h1>
      <Carousel responsive={responsive}>
        {featuredProducts.map((item, index) => (
          <FeaturedProduct
            key={index}
            name={item.name}
            img={item.img}
            soldBy={item.soldBy}
            desc={item.description}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default FeaturedProducts;
