import React from "react";
import Navbar from "./Homepage/Navbar";
import Banner from "./Homepage/Banner";
import FormHomepage from "./Homepage/FormHomepage";
import TopCategory from "./Homepage/TopCategory";
import FeaturedProducts from "./Homepage/FeaturedProducts";
import TopSuppliers from "./Homepage/TopSuppliers";
import AgricultureProducts from "./Homepage/AgricultureProducts";
import FoodBeverage from "./Homepage/FoodBeverage";
import ApparelsFashions from "./Homepage/ApparelsFashions";
import Pharmaceuticals from "./Homepage/Pharmaceuticals";
import AboutMe from "./Homepage/AboutMe";
import ShopWithUs from "./Homepage/ShopWithUs";
import Testimonial from "./Homepage/Testimonial";
import Footer from "./Homepage/Footer";

const HomePage = () => {
  return (
    <div className="outer-content">
      <Navbar />
      <Banner />
      <FormHomepage />
      <TopCategory />
      <FeaturedProducts />
      <AgricultureProducts />
      <FoodBeverage />
      <ApparelsFashions />
      <Pharmaceuticals />
      <TopSuppliers />
      <AboutMe />
      <ShopWithUs />
      <Testimonial />
      <Footer />
    </div>
  );
};

export default HomePage;
