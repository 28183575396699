import axios from "axios";
import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const ManageProduct = () => {
  const [formData, setFormData] = useState({
    name: "Shiva",
    price: "12",
    quantity: "12",
    description: "",
    images: [], // Store selected images in an array
    businessType: "Manufacture",
    location: "dasdfasdadsas dansfasf",
    minOrder: "23",
    category: "64abc3f79ad9e6fc1c76dee7",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const { files } = e.target;
    setFormData({ ...formData, images: files }); // Store selected images in the state
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("name", formData.name);
    form.append("price", formData.price);
    form.append("quantity", formData.quantity);
    form.append("description", formData.description);
    form.append("businessType", formData.businessType);
    form.append("location", formData.location);
    form.append("minOrder", formData.minOrder);
    form.append("category", formData.category);

    // Convert the selected images to the format expected by the backend
    const productPictures = [];
    for (let i = 0; i < formData.images.length; i++) {
      productPictures.push({
        img: formData.images[i].name, // Use the image file name as the "img" field value
        _id: i.toString(), // Generate a unique ID for each image (you can use any ID generation logic)
      });
      form.append("productPictures", formData.images[i]);
    }

    // Append the formatted productPictures to the form data
    form.append("productPictures", JSON.stringify(productPictures));

    axios
      .post("http://localhost:5000/api/createProduct", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Product created:", response.data);
        setFormData({
          name: "",
          price: "",
          quantity: "",
          description: "",
          images: [],
          businessType: "",
          location: "",
          minOrder: "",
          category: "",
        });
      })
      .catch((error) => {
        console.log("Error creating product:", error);
        // You can add code here to show an error message to the user or perform other error handling
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <label>Product Name</label>
        <input
          type="text"
          placeholder="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <label>Price</label>
        <input
          type="text"
          placeholder="Price"
          name="price"
          value={formData.price}
          onChange={handleChange}
        />
        <label>Quantity</label>
        <input
          type="text"
          placeholder="Quantity"
          name="quantity"
          value={formData.quantity}
          onChange={handleChange}
        />
        <label>Description</label>
        <textarea
          placeholder="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
        />
        <label>Business Type</label>
        <input
          type="text"
          placeholder="Business Type"
          name="businessType"
          value={formData.businessType}
          onChange={handleChange}
        />
        <label>Location</label>
        <input
          type="text"
          placeholder="Location"
          name="location"
          value={formData.location}
          onChange={handleChange}
        />
        <label>Min Order</label>
        <input
          type="text"
          placeholder="Min Order"
          name="minOrder"
          value={formData.minOrder}
          onChange={handleChange}
        />
        <label>Category</label>
        <input
          type="text"
          placeholder="Category"
          name="category"
          value={formData.category}
          onChange={handleChange}
        />
        <label>Product Pictures</label>
        <input type="file" name="images" onChange={handleFileChange} multiple />

        <Button type="submit">Create Product</Button>
      </Form>
    </div>
  );
};

export default ManageProduct;
