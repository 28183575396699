import React from "react";

const IsoCertification = () => {
  return (
    <>
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <div
            className="adminSubNav"
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <h3
              className=" mt-2"
              style={{
                margin: "auto",
                color: "#0e5176",
                fontWeight: "bold",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              ISO CERTIFICATIONS
            </h3>
          </div>
          <hr />

          <div className="table-section">
            <div className="tablenav">
              <div className="sorting ml-2">
                <label>
                  Show{" "}
                  <select
                    name="example_length"
                    aria-controls="example"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div className="tableSearchbar mb-3 mr-2">
                <form className="d-flex " role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-primary" type="submit">
                    Search
                  </button>
                </form>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped table-hover">
                <thead>
                  <tr className="text-center">
                    <th scope="col">S.NO</th>
                    <th scope="col">NAME</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">MOBILE</th>
                    <th scope="col">BUSINESS NAME</th>
                    <th scope="col">CITY</th>
                    <th scope="col">GST NO.</th>
                    <th scope="col">PAN NO.</th>
                    <th scope="col">CERTIFICATION REQUIRED</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <th scope="row">1</th>
                    <td>Ramesh</td>
                    <td>Ramesh@gmail.com</td>
                    <td>9876543210</td>
                    <td>Buyer</td>
                    <td>Delhi</td>
                    <td>Delhi</td>
                    <td>Delhi</td>
                    <td>Delhi</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IsoCertification;
