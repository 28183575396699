import React, { useState, useEffect, CSSProperties } from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import { BsFillGridFill } from "react-icons/bs";
import { GiHamburgerMenu } from "react-icons/gi";
import "../../index.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HashLoader from "react-spinners/HashLoader";

const carouselCardStyles = {
  transition: "transform 0.3s ease",
  cursor: "pointer",
  "&:hover": {
    transform: "scale(1.1) translateY(-10px)", // Add both scaling and translateY for the "bringing up" effect
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.3)", // Add a box shadow for the raised effect
  },
};

const AllProductsPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { categoryId, parentId } = useParams();
  console.log("category parent", parentId);
  const navigate = useNavigate();
  const [currentDesign, setCurrentDesign] = useState("grid");
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [category, setCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [subcategoriesdata, setsubcategoriesData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [parentCategoryData, setParentCategoryData] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedLi, setSelectedLi] = useState(null);

  // CSS for the hover effect
  const generateLiStyles = (categoryId) => {
    return {
      cursor: "pointer",
      // padding: selectedCategoryId === categoryId ? "10px 0" : "0",
      // margin: selectedCategoryId === categoryId ? "10px 0" : "0",
      transform:
        selectedCategoryId === categoryId ? "translateY(-10px)" : "none", // Bringing up effect
      transition: "transform 0.3s ease",
    };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the current category data
        const categoryResponse = await axios.get(
          `https://demo.businessworldtrade.com/api/getcategory/${categoryId}`
        );
        const categoryData = categoryResponse.data;
        console.log(categoryData);
        setCategory(categoryData.category);
        setSubCategories(categoryData.subcategories);

        // Fetch subcategories of each subcategory
        const subCategoriesData = await Promise.all(
          categoryResponse.data.subcategories.map(async (subCategory) => {
            const subCategoryId = subCategory._id;
            const subCategoryResponse = await axios.get(
              `https://demo.businessworldtrade.com/api/getcategory/${subCategoryId}`
            );
            return subCategoryResponse.data;
          })
        );
        setsubcategoriesData(subCategoriesData);
        console.log("hello", subCategoriesData);

        // Fetch the parent category data based on the parentId, if parentId exists
        if (categoryData.category.parentId) {
          const parentCategoryResponse = await axios.get(
            `https://demo.businessworldtrade.com/api/getcategory/${categoryData.category.parentId}`
          );
          const parentCategoryData = parentCategoryResponse.data;
          setParentCategoryData(parentCategoryData);
        } else {
          setParentCategoryData(null);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [categoryId]);

  const handleParentCategoryClick = async (categoryId) => {
    setSelectedCategoryId(categoryId);

    try {
      const categoryResponse = await axios.get(
        `https://demo.businessworldtrade.com/api/getcategory/${categoryId}`
      );
      const categoryData = categoryResponse.data;

      // Set the subcategories of the clicked parent category
      setSubCategories(categoryData.subcategories);
      // Set the category data
      setCategory(categoryData.category);

      console.log("Clicked Parent Category Data:", categoryData);
    } catch (error) {
      console.log("API Error:", error);
    }
  };

  const handleCategoryClick = async (categoryID) => {
    setSelectedSubcategory(categoryID);

    try {
      const categoryResponse = await axios.get(
        `https://demo.businessworldtrade.com/api/getcategory/${categoryID}`
      );
      const categoryData = categoryResponse.data;

      // Set the subcategories of the clicked parent category
      setSubCategories(categoryData.subcategories);

      // Set the category data
      setCategory(categoryData.category);

      console.log("Clicked Parent Category Data:", categoryData.subCategories);

      // Now, navigate to the desired route with the categoryID
      navigate(`/category/${categoryID}`);
    } catch (error) {
      console.log("API Error:", error);
    }
  };
  if (!category || !parentCategoryData) {
    return (
      <div>
        <Navbar />

        <HashLoader
          color="#36d7b7"
          size={70}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        />

        <Footer />
      </div>
    );
  }

  // let filteredSubCategories = [...subCategories];
  let filteredSubCategories = [...subCategories];
  if (selectedFilter === "az") {
    filteredSubCategories.sort((a, b) => a.name.localeCompare(b.name));
  } else if (selectedFilter === "za") {
    filteredSubCategories.sort((a, b) => b.name.localeCompare(a.name));
  }

  const handleGridClick = () => {
    setCurrentDesign("grid");
  };

  const handleMenuClick = () => {
    setCurrentDesign("menu");
  };

  const responsive = {
    // Define your responsive settings here
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // Number of slides to scroll when clicking arrows
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  return (
    <>
      <Navbar />
      <div className="container py-4">
        <div className="row">
          {/* Start of Sidebar Component */}
          <div className="col-12 col-md-3 my-5">
            {/* On small screens, display the carousel */}
            <div
              id="carouselExampleControls"
              className="carousel d-md-none"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                {parentCategoryData.subcategories &&
                  parentCategoryData.subcategories.map((cate, index) => (
                    <div
                      className={`carousel-item ${index === 0 ? "active" : ""}`}
                      key={cate._id}
                      onClick={() => handleParentCategoryClick(cate._id)}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="border  rounded-pill p-1 text-center mt-0 "
                        // style={{ width: "40vh" }}
                      >
                        {cate.name}
                      </div>
                    </div>
                  ))}
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>

            {/* On medium and larger screens, display the original content */}

            <div
              className="hamburger-drop1 border rounded d-none d-md-block"
              style={{ borderColor: "Gainsboro" }}
            >
              <ul className="hamburger-items1">
                {parentCategoryData.subcategories &&
                  parentCategoryData.subcategories.map((cate) => (
                    <li
                      className={`ham-item border rounded-pill pe-5 ps-5 ${
                        selectedCategoryId === cate._id ? "active-category" : ""
                      } hamburger-items2`}
                      key={cate._id}
                      onClick={() => {
                        handleParentCategoryClick(cate._id);
                        setSelectedLi(cate._id);
                      }}
                      style={{
                        ...generateLiStyles(cate._id),
                      }}
                    >
                      <h6 className="p-1 text-center">{cate.name}</h6>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          {/* End of Sidebar Component */}

          <div className="col-12 col-md-9">
            <div className="row align-items-center mb-3">
              <div className="col-6 col-md-3">
                <button
                  className="border text-center btn me-2"
                  onClick={handleGridClick}
                >
                  <BsFillGridFill size={25} />
                </button>
                <button
                  className="border text-center btn"
                  onClick={handleMenuClick}
                >
                  <GiHamburgerMenu size={25} />
                </button>
              </div>
              <div className="col-6 col-md-6 text-center fs-5 fw-bold">
                {subCategories.length} total products
              </div>
              <div className="col-12 col-md-3">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={selectedFilter}
                  onChange={(e) => setSelectedFilter(e.target.value)}
                >
                  <option value="">Filters</option>
                  <option value="az">A to Z</option>
                  <option value="za">Z to A</option>
                </select>
              </div>
            </div>

            <div className="row">
              {/* Grid Design */}
              {currentDesign === "grid" && (
                <Carousel responsive={responsive}>
                  {filteredSubCategories.map((category) => (
                    <div
                      key={category._id}
                      onClick={() => handleCategoryClick(category._id)}
                      style={{ ...carouselCardStyles }} // Apply the hover effect styles here
                      className="my-3 mx-3 container-row-column-two-div"
                    >
                      <img
                        className=""
                        src={`https://demo.businessworldtrade.com/${category.images[0].img}`}
                        alt={category.name}
                      />
                      <p className="text-center my-2 text-bold">
                        {category.name}
                      </p>
                    </div>
                  ))}
                </Carousel>
              )}
              {/* Menu Design */}
              {currentDesign === "menu" &&
                filteredSubCategories.map((item) => (
                  <div
                    className="col-12 col-md-6 mb-4 menu1"
                    key={item._id}
                    onClick={() => handleCategoryClick(item._id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="card border rounded">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-4 col-md-3">
                            <img
                              className="img-fluid"
                              src={`https://demo.businessworldtrade.com/${item.images[0].img}`}
                              alt={item.name}
                            />
                          </div>
                          <div className="col-8 col-md-9 text-center">
                            {item.name}
                          </div>
                          {/* {subcategoriesdata.map((item) => (
                            <div>{item.name}</div>
                          ))} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AllProductsPage;
