import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import EasyTicker from "./EasyTicker";

// import { submitForm, submitFormData } from "../../reducers/FormSlice";

const FormHomepage = () => {
  const products = [
    {
      id: "1",
      name: "VERIFIED BUYERS",
      cover: require(".././images/verified-01.jpg"),
    },
    {
      id: "2",
      name: "24 X 7 HELP CENTER",
      cover: require(".././images/help center-01.jpg"),
    },
    {
      id: "3",
      name: "FREE QUOTES FROM SELLER",
      cover: require(".././images/quote-01.jpg"),
    },
    {
      id: "4",
      name: "TRADE WITH CONFIDENCE",
      cover: require(".././images/trade with confidence-01.jpg"),
    },
  ];

  return (
    <div className="container-fluid my-5 ">
      <div className="row">
        <div className="col-md-5 col-sm-12">
          <div className="row">
            {products.map((product) => (
              <div className="col-6">
                <div className="card rounded border border-0 border-light">
                  <img
                    src={product.cover}
                    className="card-img-top px-4 pt-4"
                    alt={product.name}
                  />
                  <h4 className="card-text px-2 pb-3 text-center">
                    {product.name}
                  </h4>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="col-md-7 col-sm-12">
          <EasyTicker />
        </div>
      </div>
    </div>
  );
};

export default FormHomepage;
