import React, { useEffect } from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import careerImage from "../images/Career.jpg";

const Career = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Navbar />
      <div
        className="container-fluid contactus-banner gap py-5 mb-4 img-fluid"
        style={{
          backgroundImage: `url(${careerImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center left",
        }}
      >
        <div className="row py-5">
          <div className="col-12 text-right text-light pt-3">
            <div class="row">
              <div className="col-6"></div>
              <div className="col-6">
                <h5 className="career-heading text-center text-light pt-3">
                  Career With
                </h5>
                <h1 className="career-title text-center text-light pt-2 pb-3">
                  Business World Trade
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-career-content">
        <div className="privacy-policy-content">
          <p className="career-paragraph">
            Join our dynamic team at{" "}
            <span className="highlight-text">Business World Trade</span> and be
            part of a leading B2B platform that connects businesses worldwide.
            We are passionate about fostering growth and enabling businesses to
            thrive in the global marketplace. As we continue to expand our
            services and reach, we are constantly seeking talented individuals
            who share our vision and can contribute to our success.
          </p>

          <div className="career-section">
            <h4 className="career-section-title">Why Work With Us</h4>
            <p className="career-section-paragraph">
              Meaningful Impact: At Business World Trade, you will have the
              opportunity to make a significant impact on the B2B industry. We
              empower businesses by providing them with the tools and resources
              they need to succeed in today's competitive market.
            </p>
          </div>

          <div className="career-section">
            <h4 className="career-section-title">Collaborative Environment</h4>
            <p className="career-section-paragraph">
              We foster a collaborative and inclusive work environment, where
              teamwork and innovation are highly valued. We believe in the power
              of diverse perspectives and encourage open communication and idea
              sharing.
            </p>
          </div>

          <div className="career-section">
            <h4 className="career-section-title">Professional Growth</h4>
            <p className="career-section-paragraph">
              We are committed to the growth and development of our employees.
              As part of our team, you will have access to learning and
              development programs, training workshops, and mentorship
              opportunities to enhance your skills and advance your career.
            </p>
          </div>

          <div className="career-section">
            <h4 className="career-section-title">Exciting Challenges</h4>
            <p className="career-section-paragraph">
              Working in the B2B industry brings exciting challenges and
              opportunities for continuous learning. You will have the chance to
              work on diverse projects, collaborate with industry experts, and
              stay at the forefront of technological advancements.
            </p>
          </div>

          <div className="career-section">
            <h4 className="career-section-title">Work-Life Balance</h4>
            <p className="career-section-paragraph">
              We understand the importance of maintaining a healthy work-life
              balance. We strive to create a flexible work environment that
              promotes well-being, allowing our employees to excel both
              personally and professionally.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Career;
