import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavbarLink = styled(Link)`
  color: white;
  text-decoration: none;
  &:hover,
  &:focus {
    color: blue;
  }
  &:active {
    color: red;
  }
`;

function Sidenav() {
  const user = JSON.parse(localStorage.getItem("user"));

  return (
    <div className="col-4">
      {/* Main Sidebar Container */}
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/">
          <img
            src="../logoo.png"
            style={{ height: "60px", width: "100%" }}
            alt="logo"
          />
          <hr style={{ backgroundColor: "#c3c3c3", margin: "0px" }} />
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user panel (optional) */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
              <img
                src="./model.jpg"
                className="img-circle elevation-2"
                alt="UserImage"
              />
            </div>

            <div className="info">
              <a
                id="helloadmin"
                style={{ textDecoration: "none" }}
                className="d-block"
                href="/"
              >
                Click here to go home
              </a>
            </div>
          </div>

          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              //   data-accordion="false"
            >
              {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
              <li className="nav-item menu-open"></li>
              <li className="nav-item">
                <NavbarLink to="/sellerhome" className="nav-link">
                  <i className="fa-solid fa-gauge nav-icon" />
                  <p> Dashboard</p>
                </NavbarLink>
              </li>
              <li className="nav-item">
                <NavbarLink to="/contactprofile" className="nav-link">
                  <i className="fa-regular fa-clipboard  nav-icon"></i>
                  <p>Contact Profile</p>
                </NavbarLink>
              </li>
              <li className="nav-item">
                <Link to="/packges" className="nav-link">
                  <i className="fa-solid fa-gear nav-icon"></i>
                  <p>Service Package</p>
                </Link>
              </li>
              <li className="nav-item">
                <a href="/" className="nav-link">
                  <i className="fa-solid fa-users nav-icon"></i>
                  <p>Lead Desk</p>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  <i className="nav-icon fas fa-edit" />
                  <p>
                    Buy Leades
                    <i className="fas fa-angle-left right" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavbarLink to="/addcategory" className="nav-link">
                      <i className="fa-solid fa-list nav-icon"></i>
                      <p>Product Relavent Leads</p>
                    </NavbarLink>
                  </li>

                  <li className="nav-item">
                    <NavbarLink to="/addsubcategory" className="nav-link">
                      <i className="fa-solid fa-list nav-icon"></i>
                      <p>Current Leades</p>
                    </NavbarLink>
                  </li>
                  <li className="nav-item">
                    <NavbarLink to="/addinnersubcategory" className="nav-link">
                      <i className="fa-solid fa-list nav-icon"></i>
                      <p>Shortlisted Leads</p>
                    </NavbarLink>
                  </li>
                  <li className="nav-item">
                    <NavbarLink to="/sellerhome" className="nav-link">
                      <i className="fa-solid fa-list nav-icon"></i>
                      <p>Consumed Leades</p>
                    </NavbarLink>
                  </li>
                  <li className="nav-item">
                    <NavbarLink to="/sellerhome" className="nav-link">
                      <i className="fa-solid fa-list nav-icon"></i>
                      <p>Total Lead Records</p>
                    </NavbarLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavbarLink to="/sellermanageproducts" className="nav-link">
                  <i className="fa-solid fa-list-check nav-icon"></i>
                  <p>Manage Products</p>
                </NavbarLink>
              </li>
              <li className="nav-header">More</li>
              <li className="nav-item">
                <Link className="nav-link">
                  <i className="fa-regular fa-clipboard  nav-icon"></i>
                  <p>
                    Settings
                    <i className="fas fa-angle-left right" />
                  </p>
                </Link>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <NavbarLink to="/blogcategory" className="nav-link">
                      <i className="fa-solid fa-list nav-icon"></i>
                      <p>Notification Settings</p>
                    </NavbarLink>
                  </li>
                  <li className="nav-item">
                    <NavbarLink to="/blogposts" className="nav-link">
                      <i className="fa-solid fa-image-portrait nav-icon"></i>
                      <p>Account Settings</p>
                    </NavbarLink>
                  </li>
                  <li className="nav-item">
                    <NavbarLink to="/blogposts" className="nav-link">
                      <i className="fa-solid fa-image-portrait nav-icon"></i>
                      <p>Location Prefrences</p>
                    </NavbarLink>
                  </li>
                  <li className="nav-item">
                    <NavbarLink to="/blogposts" className="nav-link">
                      <i className="fa-solid fa-image-portrait nav-icon"></i>
                      <p>Change Password</p>
                    </NavbarLink>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavbarLink to="/pages" className="nav-link">
                  <i className="fa-solid fa-book nav-icon"></i>
                  <p>Add Client Video</p>
                </NavbarLink>
              </li>
            </ul>
          </nav>

          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </div>
  );
}

export default Sidenav;
