import React, { useEffect } from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";

const FAQ = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Navbar />
      <h3 className="text-center privacy-policy">Frequently Asked Questions</h3>
      <div className="privacy-policy-content">
        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: What is Business World Trade and how does the portal work?
          </span>
          <br></br>
          Answer: Business World Trade is a leading B2B portal that connects
          businesses globally. The portal serves as a platform where companies
          can discover new trade opportunities, connect with potential partners,
          and engage in international trade transactions
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: How can I register on the Business World Trade portal?
          </span>
          <br></br>
          Answer: To register on the Business World Trade portal, visit our
          website and click on the "Register" button. Fill in the required
          information, and upon successful registration, you will gain access to
          the portal's features and services.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: Can I showcase my products/services on the Business World Trade
            portal?
          </span>
          <br></br>
          Answer: Absolutely! The Business World Trade portal allows businesses
          to create comprehensive company profiles and showcase their products
          and services to a global audience of potential buyers.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: How can I search for potential business partners on the Business
            World Trade portal?
          </span>
          <br></br>
          Answer: The portal offers advanced search functionality, enabling
          users to search for potential business partners based on various
          criteria such as industry, location, product category, and more.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: Is the Business World Trade portal secure for conducting trade
            transactions?
          </span>
          <br></br>
          Answer: Yes, the Business World Trade portal prioritizes security and
          implements robust measures to ensure secure trade transactions between
          registered businesses.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: Are there any fees associated with using the Business World Trade
            portal?
          </span>
          <br></br>
          Answer: The Business World Trade portal offers both free and premium
          membership options. Basic access to the portal is available at no
          cost, while premium memberships provide additional benefits and
          features for a fee.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: How can I connect with other businesses on the Business World
            Trade portal?
          </span>
          <br></br>
          Answer: The portal provides messaging and communication features that
          allow registered users to connect and interact with other businesses,
          fostering meaningful partnerships and collaborations.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: How can I connect with other businesses on the Business World
            Trade portal?
          </span>
          <br></br>
          Answer: The portal provides messaging and communication features that
          allow registered users to connect and interact with other businesses,
          fostering meaningful partnerships and collaborations.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: Does the Business World Trade portal offer trade-related
            resources and information?
          </span>
          <br></br>
          Answer: Yes, the portal offers a range of trade-related resources,
          including industry insights, market trends, trade guides, and
          informative articles to help businesses stay informed and make
          informed decisions.
        </p>

        <p style={{ textAlign: "left", color: "grey" }}>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Q: Can I receive personalized assistance or support on the Business
            World Trade portal?
          </span>
          <br></br>
          Answer: Yes, the Business World Trade portal has a dedicated customer
          support team that can assist users with any inquiries, technical
          issues, or general support related to the portal's features and
          services.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default FAQ;
