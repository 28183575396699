import React, { useEffect } from "react";
import NavbarHeader from "../../Homepage/Navbar";
import Footer from "../../Homepage/Footer";
import background from "../../images/web-design-banner.jpg";
export const Gold = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    textAlign: "left",
    color: "grey",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  return (
    <>
      <NavbarHeader />
      <>
        {/* about banner start */}
        <div
          className="container-fluid webdesigning-banner gap py-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: " no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="row py-5">
            <div className="col-12 text-center text-light pt-3">
              <h5>WE ENHANCING YOUR BUSINESS </h5>
            </div>
            <div className="col-12 text-center text-light pb-3 banner-head">
              <h1 className="text-light">GOLD PACKAGE</h1>
            </div>
          </div>
        </div>
        {/* about banner start */}
        {/* PACkage start */}
        <h2 className="my-5 privacy-policy text-center">
          BENEFITS OF GOLD PACKAGE
        </h2>
        <div
          className="container my-5"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="row px-2">
            <div className="col-sm-12 col-12 pt-4 mt-2 mb-3">
              {/* <h2 className="package-head text-center">
                Benifits Of GOLD PACKAGE
              </h2> */}
              <p style={pstyle}>
                Are you ready to take your online business to new heights? With
                our Gold Package, you'll have everything you need to establish a
                strong online presence, attract customers, and maximize your
                business growth.{" "}
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Website Development
                </span>
                <br />
                Our expert web development team will create a professional and
                visually appealing website that showcases your products or
                services effectively. We'll design a user-friendly interface,
                ensuring seamless navigation and a positive user experience.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Domain Name Registration{" "}
                </span>
                <br />
                Get a unique and memorable domain name that represents your
                brand. We'll assist you in choosing the perfect domain name and
                handle the registration process, ensuring your online identity
                stands out.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Favicon Creation
                </span>
                <br />
                Display up to 25 products on your website with an intuitive and
                engaging product gallery. We'll assist you in uploading product
                images, descriptions, and pricing information, allowing
                potential customers to explore your offerings.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Logistic Quotation System{" "}
                </span>
                <br />
                Streamline your business operations with a logistic quotation
                system. We'll implement a user-friendly system that enables
                customers to request shipping quotes, enhancing transparency and
                convenience.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Lead Generation Tools{" "}
                </span>
                <br />
                Generate valuable leads for your business with our lead
                generation tools. We'll incorporate contact forms and strategic
                call-to-action buttons on your website to capture visitor
                information and convert them into potential customers.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Language Converter
                </span>
                <br />
                Expand your global reach by integrating a language converter on
                your website. This feature will allow users to switch between
                different languages, ensuring a personalized experience for
                visitors worldwide.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Mobile-Friendly Version{" "}
                </span>
                <br />
                With the increasing use of mobile devices, it's crucial to have
                a mobile-friendly website. We'll optimize your website to be
                responsive across various screen sizes, providing a seamless
                browsing experience for mobile users.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Package Pricing and Duration{" "}
                </span>
                <br />
                The Gold Package is available at a competitive price of [insert
                price] per month. The package duration is [insert duration].
                This package offers excellent value for businesses seeking
                comprehensive online solutions.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Get Started Today
                </span>
                <br />
                Take your online business to new heights with our Gold Package.
                Contact our sales team at [insert contact details] to discuss
                your requirements and get started on your journey to success.
              </p>
              <p style={pstyle}>
                Business World Trade is dedicated to helping businesses like
                yours thrive in the competitive online marketplace. Join us now
                and unleash the full potential of your business!
              </p>
            </div>
          </div>
        </div>
        {/* PACkage end */}
      </>

      <Footer />
    </>
  );
};
