import React from "react";
import Navbar from "../Homepage/Navbar";
import "./Logistics.css";
import { useEffect } from "react";
import Footer from "../Homepage/Footer";
import icon1 from ".././images/icons/icon-01.png";
import icon2 from ".././images/icons/delivery1.png";
import icon3 from ".././images/icons/shipment1.png";
import icon5 from ".././images/icons/logistics.png";
import icon4 from ".././images/icons/support.png";
import icon6 from ".././images/icons/delivery1.png";
import icon7 from ".././images/icons/gps.png";
import icon8 from ".././images/icons/365.png";
import logistics from ".././images/Logistis2-01.jpg";

export const Logistics = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    // fontSize: "20px",
    color: "grey",
    fontWeight: 500,
    marginTop: "5px",
  };

  const divstyle = { display: "flex", alignItems: "center" };

  // const imgstyle = { marginRight: "10px" };
  return (
    <>
      <Navbar />
      <div className="container  p-4">
        <div className="row ">
          <div className="col-md-6 col-sm-12  d-flex align-items-end">
            <div className="   ">
              <h3
                className="text-center"
                style={{ fontSize: "36px", color: "#5c63a0" }}
              >
                Connecting Logistics to your business.
              </h3>
              <p
                className="text-center p-2"
                style={{
                  color: "#595959",
                  fontSize: "17px",
                  width: "100%",
                }}
              >
                BWT Logistics aims to fulfill your logistics requirements so
                that you can invest more time in your valuable business .
              </p>
              {/* <div className="align-items-center justify-content-center d-flex"> */}
              <div className="align-items-center justify-content-center d-flex">
                <button
                  className="p-3"
                  style={{
                    backgroundImage:
                      "linear-gradient(to top, #30cfd0 0%, #330867 100%)",

                    fontSize: "18px",
                    fontWeight: "600",
                    color: "#fff",
                    borderRadius: "50px",
                    border: "none",
                    transition: "all ease .5s",
                  }}
                >
                  Get An Instant Online Quote
                </button>
              </div>
            </div>
            {/* </div> */}
          </div>
          <div className="col-md-6 col-sm-12  d-flex align-items-center justify-content-center">
            <img src={logistics} className="img-fluid " alt="downloading..." />
          </div>
        </div>
      </div>

      {/* Start About Section */}
      <div className="container-fluid d-flex h-auto p-3 process-section">
        <div className="row">
          <h3
            className="text-center"
            style={{ fontSize: "28px", fontWeight: "500", color: "#5c63a0" }}
          >
            What Is In It For SME<span>s</span>?
          </h3>
          <p
            className="text-center p-3"
            style={{
              lineHeight: " 26px",
              fontWeight: "400",
              color: " grey",
              fontSize: "16px",
            }}
          >
            Business World Trade has come about constantly connecting Buyers and
            Suppliers enhancing their business for the past 2 durations. To
            strengthen the business further Business World Trade has partnered
            with ReturnTrucks which provides a platform for SMEs to grease the
            cargo of goods in an effective and timely manner. guests can post
            their weight and find a corresponding runner delivery service
            provider. guests can check the prices for each of the delivery
            services providers like Gati, VRL, and Safexpress on this platform
            and get the swish deals for their shipments. With a installation for
            tracking your order with GPS technology and a24/ 7 support team, we
            thrive to give a hassle-free shipping experience to our guests.
          </p>
        </div>
      </div>

      {/* End About Section */}

      {/* Start Delivery */}
      <div
        className="process-section-main text-light"
        style={{
          backgroundImage: "linear-gradient(to top, #30cfd0 0%, #330867 100%)",
        }}
      >
        <div className="container ">
          <h3>Delivery Made Easy</h3>
          <div className="process-list">
            <div className="process">
              <div className="img-section">
                <img
                  src="https://tiimg.tistatic.com/new_website1/ti-logistics/icon1.png"
                  alt="tradeindia Logistics Process"
                />
              </div>
              <div className="content ">
                <span>Post Your Shipment</span>
              </div>
            </div>
            <div className="process">
              <div className="img-section">
                <img
                  src="https://tiimg.tistatic.com/new_website1/ti-logistics/icon2.png"
                  alt="tradeindia Logistics Process"
                />
              </div>
              <div className="content">
                <span>Choose The Best Transporter Quote</span>
              </div>
            </div>
            <div className="process">
              <div className="img-section">
                <img
                  src="https://tiimg.tistatic.com/new_website1/ti-logistics/icon3.png"
                  alt="tradeindia Logistics Process"
                />
              </div>
              <div className="content">
                <span>Ready Shipment For Pickup</span>
              </div>
            </div>
            <div className="process">
              <div className="img-section">
                <img
                  src="https://tiimg.tistatic.com/new_website1/ti-logistics/icon4.png"
                  alt="tradeindia Logistics Process"
                />
              </div>
              <div className="content">
                <span>Make Payment To Transporter</span>
              </div>
            </div>
            <div className="process">
              <div className="img-sec">
                <img
                  src="https://tiimg.tistatic.com/new_website1/ti-logistics/icon5.png"
                  alt="tradeindia Logistics Process"
                />
              </div>
              <div className="content">
                <span>Shipment Delivered</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Delivery */}
      {/* Benefits for customers */}
      <div className="container  p-3 h-auto gap process-section">
        <h1
          className="text-center "
          style={{
            fontSize: "28px",
            fontWeight: "500",
            color: "#5c63a0",
          }}
        >
          Benefits for customers
        </h1>
        <div className="row p-4 ">
          <div className="col-sm-12 col-md-2 "></div>
          <div className="col-sm-12 col-md-5 ">
            <div className="py-1" style={divstyle}>
              <img src={icon1} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}> Get the best quotes for your order</p>
            </div>
            <div className="py-1" style={divstyle}>
              <img src={icon2} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}>Ship Anywhere: Extensive nationwide network</p>
            </div>
            <div className="py-1" style={divstyle}>
              <img src={icon3} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}>Lesser transit time and on-time delivery</p>
            </div>
            <div className="py-1" style={divstyle}>
              <img src={icon4} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}>24/7 support team</p>
            </div>
          </div>

          <div className="col-sm-12 col-md-5 ">
            <div className="py-1" style={divstyle}>
              <img src={icon5} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}> Leading logistics providers: Gati, VRL</p>
            </div>
            <div className="py-1" style={divstyle}>
              <img src={icon6} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}>Door pickup and door delivery facility</p>
            </div>
            <div className="py-1" style={divstyle}>
              <img src={icon7} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}>Online Live Tracking facility</p>
            </div>
            <div className="py-1" style={divstyle}>
              <img src={icon8} alt="Icon" />
              &nbsp;&nbsp;
              <p style={pstyle}>24*7*365 days operations</p>
            </div>
          </div>
        </div>
      </div>
      {/* End Benefits for customers */}
      <div
        className=" d-flex 
       bg-primary h-auto p-3"
        style={{
          backgroundImage: "linear-gradient(to top, #30cfd0 0%, #330867 100%)",
        }}
      >
        {/*start More about logistics */}
        <div className="container">
          <div>
            <h3 className="text-center fs-2"> Know more about BWT Logistics</h3>

            <div className="row d-flex flex-row ">
              <div className=" col-12  fs-6">
                <p className="text-center">
                  <span className="fs-5">1234567890767</span>
                  &emsp;
                  <span className="fs-4 mt-2">
                    <b>|</b>
                  </span>
                  &emsp;
                  <span className="fs-5">info@businessworldtrade.com</span>
                </p>
              </div>
              {/* <div className="col-md-6 col-sm-12 fs-6">
                info@businessworldtrade.com
              </div> */}
            </div>
          </div>
        </div>
        {/*end More about logistics */}
      </div>
      <Footer />
    </>
  );
};
