import React, { useState, useEffect } from "react";
import axios from "axios";

const FtaEnquries = () => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get("http://localhost:5000/api/getAllFta");

      setData(response.data);
      console.log(data.name);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <div
            className="adminSubNav"
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <h3
              className=" mt-2"
              style={{
                margin: "auto",
                color: "#0e5176",
                fontWeight: "bold",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              Free Trade Agreement (FTA)
            </h3>
          </div>
          <hr />

          <div className="table-section">
            <div className="tablenav">
              <div className="sorting ml-2">
                <label>
                  Show{" "}
                  <select
                    name="example_length"
                    aria-controls="example"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div className="tableSearchbar mb-3 mr-2">
                <form className="d-flex " role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-primary" type="submit">
                    Search
                  </button>
                </form>
              </div>
            </div>
            <div className="table-responsive">
              <div className="table-responsive">
                <table className="table table-striped table-hover h-auto">
                  <thead>
                    <tr className="text-center">
                      <th scope="col">S.No</th>
                      <th scope="col">NAME</th>
                      <th scope="col">EMAIL</th>
                      <th scope="col">MOBILE</th>
                      <th scope="col">COMPANY NAME</th>
                      <th scope="col">IEC</th>
                      <th scope="col">GST NO.</th>
                      <th scope="col">ADDRESS</th>
                      <th scope="col">BUSINESS TYPE</th>
                      <th scope="col">COMPANY PAN NO</th>
                      <th scope="col">TIME PERIOD</th>
                      <th scope="col">YEAR OF ESTABLISHMENT </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr className="text-center" key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.name}</td>
                        <td>{item.email}</td>
                        <td>{item.phone}</td>
                        <td>{item.campanyName}</td>
                        <td>{item.iec}</td>
                        <td>{item.gst}</td>
                        <td>{item.address}</td>
                        <td>{item.selectionType}</td>
                        <td>{item.comapnyPan}</td>
                        <td>{item.timePeriod}</td>
                        <td>{item.yearOfEst}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FtaEnquries;
