import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ApparelFashion from "./ApparelFashion";
import Tshirt from "../images/homePageImg/apparelFashion/tshirt(2-8yrs).png";
import Girlsjeans from "../images/homePageImg/apparelFashion/girlsjeans.png";
import Boysfootwear from "../images/homePageImg/apparelFashion/boysfootwear.png";
import Floatersandals from "../images/homePageImg/apparelFashion/floatersandals.png";
import Trackpants from "../images/homePageImg/apparelFashion/trackpants-01.png";
import Mennightsuit from "../images/homePageImg/apparelFashion/mennightsuit.png";

const ApparelsFashions = () => {
  const ApparelFashions = [
    {
      img: Tshirt,
      soldBy: "Ravinder",
      name: "T-Shirts(2-8 Yrs)",
      description: "Trader: Haryana",
    },
    {
      img: Girlsjeans,
      soldBy: "Sudarshan",
      name: "Girl's Jeans(6-12Yrs)",
      description: "Wholeseller: Chennai",
    },
    {
      img: Boysfootwear,
      soldBy: "Dinesh kumar ",
      name: "Boy's Footwear",
      description: "Retailer: Gwalior",
    },
    {
      img: Floatersandals,
      soldBy: "Amandeep",
      name: "Floater Sandals",
      description: "Wholeseller: Kanpur",
    },
    {
      img: Trackpants,
      soldBy: "Ravinder",
      name: "Trackpants Tracksuits (3-9 Yrs)",
      description: "Wholeseller: Haryana",
    },
    {
      img: Mennightsuit,
      soldBy: "Tajinder singh",
      name: "Men's Night Suit ",
      description: "Wholeseller: Ludhiana",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://fakestoreapi.com/products")
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const productList = ApparelFashions.map((item) => (
    <ApparelFashion
      key={item.id}
      name={item.name}
      img={item.img}
      soldBy={item.soldBy}
      desc={item.description}
    />
  ));
  return (
    <div>
      <h1
        className="mt-5 mb-3"
        style={{
          color: "#444444",
          fontWeight: "700",
        }}
      >
        Apparel & Fashion
      </h1>
      <Carousel responsive={responsive}>{productList}</Carousel>
    </div>
  );
};

export default ApparelsFashions;
