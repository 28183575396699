import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./stores/Store";
import { SearchProvider } from "./context/search";
import { AuthProvider } from "./context/AuthCotext";
import { UserProvider } from "./context/AuthCotext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <AuthProvider>
          <SearchProvider>
            <UserProvider>
              <App />
            </UserProvider>
          </SearchProvider>
        </AuthProvider>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
