import React, { useEffect } from "react";
import NavbarHeader from "../../Homepage/Navbar";
import Footer from "../../Homepage/Footer";
import background from "../../images/web-design-banner.jpg";
export const Silver = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    textAlign: "left",
    color: "grey",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  return (
    <>
      <NavbarHeader />
      <>
        {/* about banner start */}
        <div
          className="container-fluid webdesigning-banner gap py-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: " no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="row py-5">
            <div className="col-12 text-center text-light pt-3">
              <h5>WE ENHANCING YOUR BUSINESS </h5>
            </div>
            <div className="col-12 text-center text-light pb-3 banner-head">
              <h1>SLIVER PACKAGE</h1>
            </div>
          </div>
        </div>
        {/* about banner start */}
        {/* PACkage start */}
        <h2 className="my-5 privacy-policy text-center">
          BENEFITS OF SLIVER PACKAGE
        </h2>
        <div
          className="container my-5"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="row px-2">
            <div className="col-sm-12 col-12 pt-4 mt-2 mb-3">
              {/* <h2 className="package-head text-center">
                Benifits Of SLIVER PACKAGE
              </h2> */}
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Website
                </span>
                <br />
                Establish a strong online presence with a fully functional and
                user-friendly website that showcases your products and services.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Global Reach{" "}
                </span>
                <br />
                Expand your market and connect with potential customers
                worldwide.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  24/7 Availability
                </span>
                <br />
                Your website acts as a virtual storefront, providing access to
                information about your offerings round the clock.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Enhanced Visibility{" "}
                </span>
                <br />
                Present your products or services attractively through an online
                catalog and engaging image slideshow, attracting a broader
                audience.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Competitive Advantage{" "}
                </span>
                <br />
                Stay ahead of competitors by establishing an online presence and
                reaching customers who are yet to embrace the digital era.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Credibility and Trust
                </span>
                <br />
                Build your brand reputation and gain customer trust through a
                well-designed website that reflects your expertise and
                commitment to quality.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Lead Generation{" "}
                </span>
                <br />
                Capture valuable leads through inquiry forms, allowing you to
                follow up and convert them into sales opportunities.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Customer Convenience{" "}
                </span>
                <br />
                Enable easy inquiry submissions and prompt responses, enhancing
                customer satisfaction and engagement.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Cost-Effective
                </span>
                <br />
                Enjoy cost-effective marketing compared to traditional methods,
                ensuring a good return on investment.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Logistic Quotation Integration
                </span>
                <br />
                Provide hassle-free shipping options by integrating logistic
                quotation services, making it convenient for customers to
                calculate shipping costs.
              </p>
              <p style={pstyle}>
                The Silver Package offers a comprehensive solution to promote
                your business online, expand your reach globally, establish
                credibility, generate leads, and provide convenience to your
                customers. With a professional website, online catalog, image
                slideshow, and lead generation capabilities, you can stay ahead
                of the competition and maximize your business potential.
                Additionally, the package ensures 24/7 availability,
                cost-effective marketing, and logistic quotation integration for
                seamless shipping solutions.
              </p>
            </div>
          </div>
        </div>
        {/* PACkage end */}
      </>

      <Footer />
    </>
  );
};
