import React, { useEffect, useState } from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import fta from "../images/freetradeagreement.jpg";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";
import axios from "axios";

export const FreeTradeAgreement = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const formlabelstyle = {
    display: "flex",
    flexDirection: "left",
    fontSize: "19px",
    fontWeight: "500",
  };
  const [formData, setFormData] = useState({});
  //   const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/createfta", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));

    // Reset the form after submission if needed
    setFormData({});
    // console.log(formData);
  };

  return (
    <>
      <Navbar />
      <div className="container-fluid   mb-4 mt-1">
        <img src={fta} alt="fta" />
        {/* <div className="row py-5" style={{ paddingLeft: "50%" }}>
          <div className="col-12 text-center text-light pb-3 banner-head"></div>
        </div> */}
      </div>
      <div className="text-center privacy-policy-content my-5">
        <h3 className="text-center  privacy-policy my-1 mb-3">
          FREE TRADE AGREEMENT
          <br />
          (Apply Now)
        </h3>
        <div className="row">
          <div className="col-md-3 ">
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Name*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  name="name"
                  value={formData.name || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-3 ">
            {" "}
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Email Address*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Email Address"
                  aria-label="Email Address"
                  aria-describedby="basic-addon1"
                  name="email"
                  value={formData.email || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-3">
            {" "}
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Mobile Number*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Mobile Number"
                  aria-label="Mobile Number"
                  aria-describedby="basic-addon1"
                  name="phone"
                  value={formData.phone || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-3 ">
            {" "}
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Company Name*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Company Name"
                  aria-label="Company Name"
                  aria-describedby="basic-addon1"
                  name="campanyName"
                  value={formData.campanyName || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 ">
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>IEC*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="IEC "
                  aria-label="IEC "
                  aria-describedby="basic-addon1"
                  name="iec"
                  value={formData.iec || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-3 ">
            {" "}
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>GST*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="GST"
                  aria-label="GST"
                  aria-describedby="basic-addon1"
                  name="gst"
                  value={formData.gst || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-3">
            {" "}
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Address*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Address"
                  aria-label="Address"
                  aria-describedby="basic-addon1"
                  name="address"
                  value={formData.address || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-3">
            {" "}
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Select*</Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="selectionType"
                onChange={handleChange}
              >
                <option>Select...</option>
                <option value="Manufacturer">Manufacturer</option>
                <option value="Trader">Trader</option>
                <option value="Wholeseller">Wholeseller</option>
                <option value="Retailer">Retailer</option>
                <option value="Farmer">Farmer</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-md-3 ">
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Company PAN No.*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Company PAN No"
                  aria-label="Company PAN No"
                  aria-describedby="basic-addon1"
                  name="comapnyPan"
                  value={formData.comapnyPan || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>

          <div className="col-md-3 ">
            {" "}
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>Time Period*</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Time Period"
                  aria-label="Time Period"
                  aria-describedby="basic-addon1"
                  name="timePeriod"
                  value={formData.timePeriod || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
          <div className="col-md-3 ">
            <Form.Group className="mb-2 " controlId="formBasicEmail">
              <Form.Label style={formlabelstyle}>
                Year Of Establishment*
              </Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Year Of Establishment"
                  aria-label="Year Of Establishment"
                  aria-describedby="basic-addon1"
                  name="yearOfEst"
                  value={formData.yearOfEst || ""}
                  onChange={handleChange}
                />
              </InputGroup>
            </Form.Group>
          </div>
        </div>

        <div>
          <Button
            className="btn-lg pe-4 ps-4"
            variant="outline-primary"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>

      <Footer />
    </>
  );
};
