// LogIn.js
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Flex,
  Checkbox,
  FormControl,
  Heading,
  Input,
  Stack,
  ChakraProvider,
  FormLabel,
  Button,
  Image,
} from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { signinSuccess } from "../../reducers/authSlice";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../context/AuthContext";
import { useAuth } from "../../context/AuthCotext";
import loginImage from "../images/login.jpg";

export default function LogIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logIn, logOut, isLoggedIn } = useAuth();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    })); 
  };

  const handleLogin = () => {
    setLoading(true);
    axios
      .post("https://demo.businessworldtrade.com/api/signin", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const { message, user, token } = response.data;
        toast.success(message);
        localStorage.setItem("token", token);

        dispatch(signinSuccess({ user, token }));
        setLoading(false);
        logIn();
        console.log("Login successfully:", user);
        navigate("/");
      })
      .catch((error) => {
        console.error("Login error:", error);
        toast.error("Invalid email or password.");
        setLoading(false);
      });
  };

  const handleLogout = () => {
    // Add your logout logic here, e.g., clearing localStorage, updating state
    localStorage.removeItem("token");
    logOut();
    dispatch(logOut()); // Assuming you have a signout action
    navigate("/"); // Redirect to the home page or any other desired page after logout
  };

  const handleClick = (e) => {
    e.preventDefault();
    const isValidEmail = /\S+@\S+\.\S+/.test(formData.email);

    if (!isValidEmail) {
      toast.error("Please enter a valid email!");
      return;
    }

    if (isLoggedIn) {
      handleLogout();
    } else {
      handleLogin();
    }
  };

  return (
    <ChakraProvider>
      <ToastContainer />
      <Stack
        className="homepage-login-page"
        minH={"100vh"}
        direction={{ base: "column", md: "row" }}
      >
        <Flex p={8} flex={1} align={"center"} justify={"center"}>
          <Stack spacing={4} w={"full"} maxW={"md"}>
            <Heading fontSize={"2xl"}>Sign in to your account</Heading>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email || ""}
                onChange={handleChange}
              />
            </FormControl>

            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                name="password"
                value={formData.password || ""}
                onChange={handleChange}
              />
            </FormControl>

            <Stack spacing={6}>
              <Checkbox>Remember me</Checkbox>
              <Button
                colorScheme={"blue"}
                variant={"solid"}
                type="submit"
                onClick={handleClick}
                isLoading={loading}
                loadingText={isLoggedIn ? "Logging out..." : "Signing in..."}
              >
                {isLoggedIn ? "Sign out" : "Sign in"}
              </Button>
            </Stack>
          </Stack>
        </Flex>
        <Flex flex={1}>
          <Image
            className="homepage-login-page-image"
            alt={"Login Image"}
            src={loginImage}
          />
        </Flex>
      </Stack>
    </ChakraProvider>
  );
}
