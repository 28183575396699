import React from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import "./VideoTestimonial.css";
import ReactPlayer from "react-player";
import agri from ".././images/agri.jpg";

const VideoTestimonials = () => {
  return (
    <>
      <Navbar />
      <div className="ctg-agriculture">
        <div className="">
          Home /{" "}
          <span style={{ color: "red", cursor: "pointer" }}>
            Video Testimonials
          </span>
        </div>
      </div>
      <div className="container w-75">
        <div className="row ">
          <div className="col-md-8 ">
            {" "}
            <ReactPlayer
              url="https://youtu.be/bsnW2LfxEsM" // Replace with the correct video file path
              controls={true} // Show video controls (play, pause, volume, etc.)
              width="100%" // Set the width of the video player
              height="50vh" // Set the height of the video player (auto maintains aspect ratio)
            />
          </div>
          <div className="col-md-4 bg-warning"></div>
        </div>
        &nbsp;
      </div>
      <div className="container row w-75">
        <div
          className="col-12 col-md-4 mb-4 menu1"
          style={{ cursor: "pointer" }}
        >
          <div className="card border rounded">
            <div
              className="card-body"
              style={{
                borderLeft: "6px solid red ",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
              }}
            >
              <div className="row">
                <div className="col-4 col-md-4">
                  <img
                    className="img-fluid"
                    src={agri}
                    alt=""
                    style={{ width: "100vh", height: "15vh" }}
                  />
                </div>
                <div className="col-8 col-md-8 text-left">
                  <p>Anikita Singhal</p>
                  <p>Company Name</p>
                  <p>Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-4 mb-4 menu1"
          style={{ cursor: "pointer" }}
        >
          <div className="card border rounded">
            <div
              className="card-body"
              style={{
                borderLeft: "6px solid yellow ",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
              }}
            >
              <div className="row">
                <div className="col-4 col-md-4">
                  <img
                    className="img-fluid"
                    src={agri}
                    alt=""
                    style={{ width: "100vh", height: "15vh" }}
                  />
                </div>
                <div className="col-8 col-md-8 text-left">
                  <p>Anikita Singhal</p>
                  <p>Company Name</p>
                  <p>Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-4 mb-4 menu1"
          style={{ cursor: "pointer" }}
        >
          <div className="card border rounded">
            <div
              className="card-body"
              style={{
                borderLeft: "6px solid green ",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
              }}
            >
              <div className="row">
                <div className="col-4 col-md-4">
                  <img
                    className="img-fluid"
                    src={agri}
                    alt=""
                    style={{ width: "100vh", height: "15vh" }}
                  />
                </div>
                <div className="col-8 col-md-8 text-left">
                  <p>Anikita Singhal</p>
                  <p>Company Name</p>
                  <p>Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-4 mb-4 menu1"
          style={{ cursor: "pointer" }}
        >
          <div className="card border rounded">
            <div
              className="card-body"
              style={{
                borderLeft: "6px solid gray ",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
              }}
            >
              <div className="row">
                <div className="col-4 col-md-4">
                  <img
                    className="img-fluid"
                    src={agri}
                    alt=""
                    style={{ width: "100vh", height: "15vh" }}
                  />
                </div>
                <div className="col-8 col-md-8 text-left">
                  <p>Anikita Singhal</p>
                  <p>Company Name</p>
                  <p>Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-12 col-md-4 mb-4 menu1"
          style={{ cursor: "pointer" }}
        >
          <div className="card border rounded">
            <div
              className="card-body"
              style={{
                borderLeft: "6px solid blue ",
                borderTopLeftRadius: "6px",
                borderBottomLeftRadius: "6px",
              }}
            >
              <div className="row">
                <div className="col-4 col-md-4">
                  <img
                    className="img-fluid"
                    src={agri}
                    alt=""
                    style={{ width: "100vh", height: "15vh" }}
                  />
                </div>
                <div className="col-8 col-md-8 text-left">
                  <p>Anikita Singhal</p>
                  <p>Company Name</p>
                  <p>Designation</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VideoTestimonials;
