import React, { useEffect, useState } from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import { FiPhoneCall } from "react-icons/fi";
import { BiHelpCircle } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
// import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// import Row from "react-bootstrap/Row";
// import FloatingLabel from "react-bootstrap/FloatingLabel";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import contact from "../images/contact-banner.jpg";
import contactus from "../images/contactus.png";
import axios from "axios";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";

// import { contactus } from "../../reducers/Contactus";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const pstyle = {
    textAlign: "center",
    color: "black",
    fontWeight: "light",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "18px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // console.log(formData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/contactus", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));

    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };
  return (
    <>
      <Navbar />
      {/* about banner start */}
      <div
        className="container-fluid contactus-banner gap py-5 mb-4 "
        style={{
          backgroundImage: `url(${contact})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="row py-5">
          <div className="col-12 text-center text-light pt-3">
            <h5 style={{ fontSize: "30px", fontWeight: "bold" }}>
              FEEL FREE TO CONTACT US
            </h5>
          </div>
          <div className="col-12 text-center text-light pb-3 banner-head">
            <h1
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "800 ",
                color: "white",
              }}
            >
              {" "}
              CONTACT US{" "}
            </h1>
          </div>
        </div>
      </div>
      {/* about banner start */}

      <div className="position-relative">
        <div
          className="privacy-policy-content d-flex mb-3 h-auto emaildiv"
          style={{
            backgroundcolor: "white",
          }}
        >
          <div className="container-fluid ">
            <div className="row float-left h-auto">
              <div className="float-left ">
                <p
                  style={{
                    color: "black",
                    fontWeight: "light",
                    fontSize: "20px",
                  }}
                >
                  Get a quick solution to all your queries
                </p>
              </div>
              <div className="row ">
                <div className="col-md-6 col-sm-12 ">
                  <p style={pstyle}>
                    <FiPhoneCall style={{ margin: "auto", color: "#2b5c77" }} />
                    Customer Care No.
                    <span style={spanstyle}>
                      <br />
                      +91-7042177777
                    </span>
                  </p>
                </div>
                {/* <div className="col-md-4 col-sm-12">
                  <p style={pstyle}>
                    <BiHelpCircle
                      style={{ margin: "auto", color: "#2b5c77" }}
                    />
                    Help
                    <span style={spanstyle}>
                      <br />
                      +91-7042177777
                    </span>
                  </p>
                </div> */}
                <div className="col-md-6 col-sm-12">
                  <p style={pstyle}>
                    <FiMail style={{ margin: "auto", color: "#2b5c77" }} />
                    Write To Us
                    <span style={spanstyle}>
                      <br />
                      info@businessworldtrade.com
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container py-0 w-100 d-flex">
        <div className="container-fluid  ">
          <div className="row">
            <div
              className="d-flex col-sm-12 col-md-6  py-1 px-4"
              // style={{ objectFit: "cover" }}
              // style={{
              //   border: "1px solid red",
              //   margin: "auto",
              //   boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              //   height: "auto",
              //   // border: "none",
              // }}
            >
              <img src={contactus} className="img-fluid" alt="downloading..." />
            </div>
            <div
              className="col-sm-12 col-md-6 py-1 px-4"
              style={{
                margin: "auto",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                height: "auto",
                border: "none",
              }}
            >
              <Form className="mt-2" onSubmit={handleSubmit}>
                <Form.Group className="mb-2 " controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    First Name
                  </Form.Label>
                  <InputGroup className="mb-4">
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                    <Form.Control
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="firstname"
                      value={formData.firstname || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-2 " controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Last Name
                  </Form.Label>
                  <InputGroup className="mb-4">
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                    <Form.Control
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="lastname"
                      value={formData.lastname || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Email
                  </Form.Label>
                  <InputGroup className="mb-4">
                    <Form.Control
                      placeholder="Enter Your Email Id"
                      aria-label="Enter Your Email Id"
                      aria-describedby="basic-addon2"
                      name="email"
                      value={formData.email || ""}
                      onChange={handleChange}
                    />
                    <InputGroup.Text id="basic-addon2">
                      @gmail.com
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Phone Number
                  </Form.Label>
                  <InputGroup className="mb-4">
                    <InputGroup.Text id="basic-addon1">
                      Mobile No.
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                      aria-describedby="basic-addon1"
                      name="phone"
                      value={formData.phone || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <Button
                  className="my-1 "
                  variant="outline-primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
