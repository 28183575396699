import React from "react";

function GeneralSettings() {
  return (
    <>
      <div className="col-12 admin-dash-cont">
        <div className="content-wrapper ">
          <div ml-6>
            <h1> heloo</h1>
          </div>
          <div mr-6>
            <h1> hello</h1>
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneralSettings;
