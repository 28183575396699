import React, { useEffect, useState } from "react";
import NavbarHeader from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import EFilingBanner from "../images/e-filing1.jpg";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";

export const EFilling = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showModalOther, setShowModalOther] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({});

  const handleOpenModal = (productTitle) => {
    setSelectedProduct({ name: productTitle });
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowModal(false);
  };

  const handleOpenModalOther = (productTitle) => {
    setSelectedProduct({ name: productTitle });
    setShowModalOther(true);
  };

  const handleCloseModalOther = () => {
    setSelectedProduct(null);
    setShowModalOther(false);
  };

  const handleEnquiryChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const data = [
    {
      id: "1",
      title: "WORLD TRUST CERTIFICATION",
      description:
        "The ISO certificate helps to enhance the credibility and authority of your company as well as the overall effectiveness of the company.",
      image: require("../images/World T icon.png"),
    },
    {
      id: "2",
      title: "ISO CERTIFICATION",
      description:
        "The ISO certificate helps to enhance the credibility and authority of your company as well as the overall effectiveness of the company.",
      image: require("../images/png icon[1]-01.png"),
    },
    {
      id: "3",
      title: "MSME CERTIFICATE",
      description:
        "An MSME Certificate, also known as Udyog Aadhaar or MSME Udyam Registration in India, is a government-issued certificate that provides recognition to Micro, Small, and Medium Enterprises (MSMEs).",
      image: require("../images/MSME icon.png"),
    },
    {
      id: "4",
      title: "E-STAMP CERTIFICATE",
      description:
        "An e-stamp certificate, also known as an electronic stamp certificate, is a digital or electronic version of a traditional physical stamp paper used for legal and financial transactions, particularly in India.",
      image: require("../images/stamp-01.png"),
    },
    {
      id: "5",
      title: "GST REGISTRATION",
      description:
        "GST Registration is required for firms that have a turnover exceeding INR 20 lakh. Also, the limit is 10 lakh in northern hilly states.",
      image: require("../images/gst icon.png"),
    },
    {
      id: "6",
      title: "IEC CODE",
      description:
        "IEC code is required at the time someone is about to kick-start his import/export business in the country and is a key identification number that allows export from India or Import to India.",
      image: require("../images/png icon[1]-01.png"),
    },
    // {
    //   id: "7",
    //   title: "ISO CERTIFICATE",
    //   description:
    //     "The ISO certificate helps to enhance the credibility and authority of your company as well as the overall effectiveness of the company.",
    // },
    {
      id: "7",
      title: "ITR FILLING",
      description:
        "Income tax return is required for the people whose gross income exceeds the tax exemption limit.",
      image: require("../images/itr.png"),
    },
    {
      id: "8",
      title: "COMPANY INCORPORATION",
      description:
        "The company incorporation is the process that is basically used to form a corporate entity or the company. The entity can be a Private limited company, One person company, LLP, Partnership firm.",
      image: require("../images/cin.png"),
    },
    {
      id: "9",
      title: "FSSAI CERTIFICATE",
      description:
        "FSSAI registration is necessary for All the manufacturers, restaurants, and as well as traders who are involved in the food business. This ensures the safety of food products provided or manufactured by different companies in India. ",
      image: require("../images/FSSAI icon.png"),
    },
    {
      id: "10",
      title: "AD CODE REGISTRATION",
      description:
        "AD Code (Authorised Dealer Code) registration is a requirement for businesses in India engaged in foreign exchange transactions. ",
      image: require("../images/AD CODE icon.png"),
    },
    {
      id: "11",
      title: "APEDA REGISTRATION",
      description:
        "APEDA stands for the Agricultural and Processed Food Products Export Development Authority. It is an agency of the Government of India responsible for promoting and regulating the export of agricultural and processed food products from India.",
      image: require("../images/FSSAI.png"),
    },
    {
      id: "12",
      title: "US FDA CERTIFICATION",
      description:
        "USFDA stands for the United States Food and Drug Administration, which is a federal agency under the U.S. Department of Health and Human Services. ",
      image: require("../images/us.png"),
    },
    {
      id: "13",
      title: "FIEO REGISTRATION",
      description:
        "FIEO stands for the Federation of Indian Export Organisations, and FIEO registration refers to the process of becoming a member of this apex trade promotion organization in India.",
      image: require("../images/FIEO icon.png"),
    },
    {
      id: "14",
      title: "TRADEMARK CERTIFICATE / COPYRIGHT",
      description:
        "A trademark registration is the process that proves ownership over the brand, logo, or name. It also protects your brand from any illegal use by a third party.",
      image: require("../images/copy rt icon.png"),
    },
    {
      id: "15",
      title: "INTERNATIONAL COMPANY REGISTRATION",
      description:
        "FIEO stands for the Federation of Indian Export Organisations, and FIEO registration refers to the process of becoming a member of this apex trade promotion organization in India.",
      image: require("../images/IC.png"),
    },
    // {
    //   id: "16",
    //   title: "OTHERS",
    //   description:
    //     "The ISO certificate helps to enhance the credibility and authority of your company as well as the overall effectiveness of the company.",
    // },
    {
      id: "16",
      title: "ACCOUNTING",
      description:
        "The Accounting is the systematic process of accounting, analysing, and interpretation of financial operations. Each &amp; Every company, large or small, is responsible for providing its accounting records to the Department of Income Tax.",
      image: require("../images/acounting icon.png"),
    },
  ];

  const privacypolicycontent = {
    width: " 90%",
    margin: "auto",
    /* text-align: left; */
    // boxShadow: "rgba(99, 99, 99, 0.2) 2px 6px 8px 6px",
    padding: "20px 20px",
    borderRadius: "5px",
  };

  const h3style = {
    textAlign: "start",
    fontSize: "18px",
    fontWeight: "bold",
    fontFamily: "Montserrat,sans-serif",
    color: "#000",
  };

  const pstyle = { lineHeight: "23px", color: "#727272" };
  const divstyle = { display: "flex", alignItems: "start" };

  const initialItemsToShow = 4; // Set the initial number of items to show
  const [itemsToShow, setItemsToShow] = useState(initialItemsToShow);

  // Function to load more items
  const loadMoreItems = () => {
    setItemsToShow(itemsToShow + 6); // Increase the number of items to show by 6
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Assuming Model.Title is a string, you can include it in formData like this
    const updatedFormData = { ...formData, certificate: selectedProduct?.name };

    axios
      .post("http://localhost:5000/api/certificate", updatedFormData)
      .then((res) => {
        // Handle the response as needed
        console.log(res);
      })
      .catch((err) => console.log(err));

    // Reset the form after submission
    setFormData({});
    setShowModal(false);
    setShowModalOther(false);
  };

  return (
    <>
      <NavbarHeader />
      <div
        className="container-fluid contactus-banner gap py-5 mb-4 "
        style={{
          backgroundImage: `url(${EFilingBanner})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="row py-5">
          <div className="col-12 text-center text-light pt-3">
            <h5 style={{ fontSize: "30px", fontWeight: "bold" }}>
              Simplify Your Tax Filing Process with
            </h5>
          </div>
          <div className="col-12 text-right text-light pb-3 ">
            <h1
              style={{
                // fontFamily: "Poppins, sans-serif",
                fontWeight: "800 ",
                color: "white",
              }}
            >
              {" "}
              Business World Trade's E-Filing Services{" "}
            </h1>
          </div>
        </div>
      </div>

      <div className="mt-4" style={privacypolicycontent}>
        <div className="row d-flex">
          <div
            className="col-sm-6 col-md-4 mt-3 mb-2 d-flex flex-column"
            style={{ flexGrow: 1, flexShrink: 0 }}
          >
            <div
              className="card privacypolicycontentbox"
              style={{ backgroundColor: "#f9f9ff", height: "18rem" }}
            >
              <div className="card-body text-center d-flex flex-column">
                <h3 className="card-title">
                  <div className="py-1" style={divstyle}>
                    <img src={require("../images/ffttaa.png")} alt="Icon" />
                    &nbsp; &nbsp;
                    <p style={h3style} className="mt-2">
                      {" "}
                      FREE TRADE AGREEMENT
                    </p>
                  </div>
                </h3>
                <p style={pstyle} className="text-start">
                  A free trade agreement is an alliance between two or further
                  nations to shorten walls to significance and exports among
                  them. Under a free trade policy, goods and services can be
                  bought and vended across transnational borders .
                </p>

                <div style={{ marginTop: "-0.5rem" }}>
                  <Link to="/fta">
                    <button
                      type="button"
                      className="btn btn-outline-primary ftabutton"
                    >
                      Enquiry
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {data.slice(0, itemsToShow).map((item) => (
            <div
              className="col-sm-6 col-md-4 mt-3 mb-2 d-flex flex-column"
              key={item.id}
              style={{ flexGrow: 1, flexShrink: 0 }}
            >
              <div
                className="card privacypolicycontentbox "
                style={{ backgroundColor: "#f9f9ff", height: "18rem" }}
              >
                <div className="card-body text-center d-flex flex-column  text-align-justify">
                  <h3 className="card-title">
                    <div className="py-1" style={divstyle}>
                      <img src={item.image} alt="Icon" />
                      &nbsp; &nbsp;
                      <p style={h3style} className="mt-2">
                        {" "}
                        {item.title}
                      </p>
                    </div>
                  </h3>
                  <p style={pstyle} className="text-start">
                    {item.description}
                  </p>
                </div>

                {/* <div className="mt-3"> </div> */}
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => handleOpenModal(item.title)}
                  >
                    Enquiry
                  </button>
                </div>
              </div>
            </div>
          ))}

          <div className="col-sm-6 col-md-4 mt-3 mb-2 ">
            <div
              className="card privacypolicycontentbox"
              style={{ backgroundColor: "#f9f9ff", height: "18rem" }}
            >
              <div className="card-body text-center d-flex flex-column ">
                <h3 className="card-title ">
                  <div className="py-1" style={divstyle}>
                    <img
                      src="https://www.e-startupindia.com/theme/default/images/iso.png"
                      alt="Icon"
                    />
                    &nbsp; &nbsp;
                    <p style={h3style} className="mt-2">
                      {" "}
                      OTHERS
                    </p>
                  </div>
                </h3>
                <p style={pstyle} className="text-start">
                  The ISO certificate helps to enhance the credibility and
                  authority of your company as well as the overall effectiveness
                  of the company.
                </p>
                <div style={{ marginTop: "2.5rem" }}>
                  <button
                    type="button"
                    className="btn btn-outline-primary ftabutton"
                    onClick={() => handleOpenModalOther("OTHERS")}
                  >
                    Enquiry
                  </button>
                </div>
              </div>
            </div>
          </div>
          {itemsToShow < data.length && (
            <div className="col-12 text-center mt-4">
              <button
                type="button"
                class="btn btn-outline-primary"
                onClick={loadMoreItems}
              >
                View More
              </button>
            </div>
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-1">
              <label htmlFor="email">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Company Name">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleEnquiryChange}
              />
            </div>

            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Send Enquiry
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Modal show={showModalOther} onHide={handleCloseModalOther}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedProduct?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-1">
              <label htmlFor="email">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Company Name">Company Name</label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Company Name">Message</label>
              <input
                type="text"
                className="form-control"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleEnquiryChange}
              />
            </div>
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Send Enquiry
            </Button>
          </form>
        </Modal.Body>
      </Modal>
      <Footer />
    </>
  );
};
