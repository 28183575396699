import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import NavbarHeader from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import freightQuotesBanner from "../images/frieght1.jpg";
import bg from "../images/bg3.jpg";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";
import axios from "axios";
export const SellerfreightQuotes = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/freight", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));

    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };
  return (
    <>
      <NavbarHeader />
      <div
        className="container-fluid contactus-banner gap py-5 mb-4 "
        style={{
          backgroundImage: `url(${freightQuotesBanner})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="row py-5" style={{ paddingLeft: "50%" }}>
          <div className="col-12 text-center text-light pt-3">
            <h5
              style={{
                fontSize: "30px",
                fontWeight: "bold",
              }}
            >
              FREIGHT QUOTES BY
            </h5>
          </div>
          <div className="col-12 text-center text-light pb-3 banner-head">
            <h1
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "800 ",
                color: "white",
              }}
            >
              {" "}
              Business World Trade{" "}
            </h1>
          </div>
        </div>
      </div>
      <div
        className="text-center privacy-policy-content my-5"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          // backgroundPosition: "center",
        }}
      >
        <h3 className="text-center  privacy-policy my-3">FREIGHT QUOTES</h3>
        <p style={{ padding: "0px 50px", color: " rgb(65, 65, 65)" }}>
          Post your freight shipping quotes here! Also view freight shipping
          quotes from almost 100+ freight forwarders in a flash. No Hidden Cost!
          Easy Comparison! No Hidden Details!
        </p>

        <Container style={{ border: "1px solid transparent" }}>
          <Row
            className="p-3 seller-freight-quotes-mq"
            style={{
              display: "flex",
              flexWrap: "wrap",
              border: "1px solid transparent",
            }}
          >
            {/* COLUMN 1 */}
            <Col
              className="p-3 seller-freight-quotes-mq"
              style={{ border: "1px solid transparent" }}
            >
              {/* ROW 1 */}
              <Row className="mb-3" style={{ border: "1px solid transparent" }}>
                <h4
                  style={{
                    width: "80%",
                    textAlign: "left",
                    borderBottom: "2px solid rgb(168, 165, 165)",
                    padding: "5px 0px",
                  }}
                >
                  FREIGHT MODE
                </h4>
                <Form style={{ width: "100%" }}>
                  {["radio"].map((type) => (
                    <div className="d-flex p-2 " key={`${type}`}>
                      <Form.Check
                        // inline
                        label="By Sea"
                        name="Freight Mode"
                        value="By Sea"
                        onChange={handleChange}
                        type={type}
                        className="me-2 text-secondary "
                        id={`${type}-1`}
                      />
                      <Form.Check
                        // inline
                        label="By Air"
                        name="Freight Mode"
                        value="By Air"
                        onChange={handleChange}
                        type={type}
                        className="ms-2  text-secondary"
                        id={`${type}-2`}
                      />
                    </div>
                  ))}
                </Form>
              </Row>

              {/* ROW 2 */}
              <Row className="mb-3">
                <h4
                  style={{
                    width: "80%",
                    textAlign: "left",
                    borderBottom: "2px solid rgb(168, 165, 165)",
                    padding: "5px 0px",
                  }}
                >
                  LOCATION DETAILS
                </h4>
                <div
                  className="freight-quotes-location-details"
                  style={{
                    width: "80%",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px 0px",
                    justifyContent: "space-between",
                    border: "1px solid transparent",
                  }}
                >
                  <div
                    style={{ width: "45%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      From (Origin)<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group controlId="formInput">
                      <Form.Control
                        type="text"
                        name="FromDestintion"
                        value={formData.FromDestintion || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "45%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Container Size<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="Container Size"
                      onChange={handleChange}
                    >
                      <option>20'</option>
                      <option value="40'">40'</option>
                      <option value="60'">60'</option>
                      <option value="LCL">LCL</option>
                      <option value="Other on details">Other on details</option>
                    </Form.Select>
                  </div>
                  <div
                    style={{ width: "45%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      To (Destination)<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group controlId="formInput">
                      <Form.Control
                        type="text"
                        name="ToDestintion"
                        value={formData.ToDestintion || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Row>

              {/* ROW 3 */}
              <Row>
                <h4
                  style={{
                    width: "80%",
                    textAlign: "left",
                    borderBottom: "2px solid rgb(168, 165, 165)",
                    padding: "5px 0px",
                  }}
                >
                  ITEM DETAILS
                </h4>
                <div style={{ width: "80%", border: "1px solid transparent" }}>
                  <div
                    style={{ width: "100%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Quantity<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group
                      style={{
                        width: "100%",
                        border: "1px solid transparent",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      controlId="formInput"
                    >
                      <Form.Control
                        placeholder="Quantity"
                        type="text"
                        name="Quantity"
                        value={formData.Quantity || ""}
                        onChange={handleChange}
                      />
                      <Form.Select
                        style={{ width: "30%" }}
                        aria-label="Default select example"
                        name="Quantity1"
                        onChange={handleChange}
                      >
                        <option value="CNTR">CNTR</option>
                        <option value="BOX">BOX</option>
                        <option value="EA">EA</option>
                        <option value="PCS">PCS</option>
                        <option value="DRUM">DRUM</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "100%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Weight<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group
                      style={{
                        width: "100%",
                        border: "1px solid transparent",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      controlId="formInput"
                    >
                      <Form.Control
                        placeholder="Weight"
                        type="text"
                        name="Weight"
                        value={formData.Weight || ""}
                        onChange={handleChange}
                      />
                      <Form.Select
                        style={{ width: "30%" }}
                        aria-label="Default select example"
                        name="Weight1"
                        onChange={handleChange}
                      >
                        <option value="M/T">M/T</option>
                        <option value="KG">KG</option>
                        <option value="LBS">LBS</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "100%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Volume<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group
                      style={{
                        width: "100%",
                        border: "1px solid transparent",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      controlId="formInput"
                    >
                      <Form.Control
                        placeholder="Volume"
                        type="text"
                        name="Volume"
                        value={formData.Volume || ""}
                        onChange={handleChange}
                      />
                      <Form.Select
                        style={{ width: "30%" }}
                        aria-label="Default select example"
                        name="Volume1"
                        onChange={handleChange}
                      >
                        <option value="CBM">CBM</option>
                        <option value="CBF">CBF</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </Row>

              {/* ROW 4 */}
            </Col>

            {/* COLUMN 2 */}
            <Col className="p-3" style={{ border: "1px solid transparent" }}>
              {/* ROW 1 */}
              <Row className="mb-3" style={{ border: "1px solid transparent" }}>
                <h4
                  style={{
                    width: "80%",
                    textAlign: "left",
                    borderBottom: "2px solid rgb(168, 165, 165)",
                    padding: "5px 0px",
                  }}
                >
                  SHIPMENT TYPE
                </h4>
                <Form style={{ width: "80%" }}>
                  {["radio"].map((type) => (
                    <div className="d-flex p-2" key={`${type}`}>
                      <Form.Check
                        // inline
                        className=" me-2 text-secondary"
                        label="From India"
                        name="Shipment Type"
                        value="From India"
                        onChange={handleChange}
                        type={type}
                        id={`${type}-1`}
                      />
                      <Form.Check
                        // inline
                        className=" ms-2 text-secondary"
                        label="To India"
                        name="Shipment Type"
                        value="To India"
                        onChange={handleChange}
                        type={type}
                        id={`${type}-2`}
                      />
                    </div>
                  ))}
                </Form>
              </Row>

              {/* ROW 2 */}
              <Row className="mb-3">
                <h4
                  style={{
                    width: "80%",
                    textAlign: "left",
                    borderBottom: "2px solid rgb(168, 165, 165)",
                    padding: "5px 0px",
                  }}
                >
                  OTHER DETAILS
                </h4>
                <div
                  className="freight-quotes-other-details"
                  style={{
                    width: "80%",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px 0px",
                    justifyContent: "space-between",
                    border: "1px solid transparent",
                  }}
                >
                  <div
                    style={{ width: "45%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Shipment date<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group controlId="formInput">
                      <Form.Control
                        type="date"
                        name="shipmentDate"
                        value={formData.shipmentDate || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "45%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      From (Origin)<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="From (Origin)"
                      onChange={handleChange}
                    >
                      <option>Cargo Status</option>
                      <option value="1">General</option>
                      <option value="Hazardous">Hazardous</option>
                      <option value="Reefer">Reefer</option>
                      <option value="Over Dimension">Over Dimension</option>
                      <option value="Open Top">Open Top</option>
                      <option value="More Details">More Details</option>
                    </Form.Select>
                  </div>
                  <div
                    style={{ width: "55%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Commodity Details<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group controlId="formInput">
                      <Form.Control
                        type="text"
                        name="CommodityDetails"
                        value={formData.CommodityDetails || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </div>
              </Row>

              {/* ROW 3 */}
              <Row>
                <h4
                  style={{
                    width: "80%",
                    textAlign: "left",
                    borderBottom: "2px solid rgb(168, 165, 165)",
                    padding: "5px 0px",
                  }}
                >
                  PAYMENT DETAILS
                </h4>
                <div style={{ width: "80%", border: "1px solid transparent" }}>
                  <div
                    style={{ width: "100%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Last Day of Quotation
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group
                      style={{
                        width: "100%",
                        border: "1px solid transparent",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      controlId="formInput"
                    >
                      <Form.Control
                        placeholder="Last day of quotation"
                        type="date"
                        name="LastDayofQuotation"
                        value={formData.LastDayofQuotation || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "100%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Status Details<span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Group
                      style={{
                        width: "100%",
                        border: "1px solid transparent",
                        display: "flex",
                        flexDirection: "row",
                      }}
                      controlId="formInput"
                    >
                      <Form.Control
                        placeholder="Status details"
                        type="text"
                        name="StatusDetails"
                        value={formData.StatusDetails || ""}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                  <div
                    style={{ width: "100%", border: "1px solid transparent" }}
                  >
                    <Form.Label style={{ width: "100%", textAlign: "left" }}>
                      Freight payment methods
                      <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <div
                      style={{
                        width: "100%",
                        border: "1px solid transparent",
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <Form style={{ width: "80%" }}>
                        {["radio"].map((type) => (
                          <div
                            key={`${type}`}
                            style={{
                              width: "100%",
                              border: "1px solid transparent",
                              display: "flex",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                            }}
                          >
                            <Form.Check
                              className="a-b-c text-secondary"
                              style={{ width: "100%", textAlign: "left" }}
                              name="Payment Method"
                              value="Freight on Board"
                              onChange={handleChange}
                              label="Freight on Board"
                              type={type}
                              id={`${type}-1`}
                            />
                            <Form.Check
                              className="a-b-c text-secondary"
                              style={{ width: "100%", textAlign: "left" }}
                              name="Payment Method"
                              value="Cost on Freight"
                              onChange={handleChange}
                              label="Cost on Freight"
                              type={type}
                              id={`${type}-2`}
                            />
                            <Form.Check
                              className="a-b-c text-secondary"
                              style={{ width: "100%", textAlign: "left" }}
                              name="Payment Method"
                              value="Cost Net Insured"
                              onChange={handleChange}
                              label="Cost Net Insured"
                              type={type}
                              id={`${type}-3`}
                            />
                            <Form.Check
                              className="a-b-c text-secondary"
                              style={{ width: "100%", textAlign: "left" }}
                              name="Payment Method"
                              value="Cash Against Delivery"
                              onChange={handleChange}
                              label="Cash Against Delivery "
                              type={type}
                              id={`${type}-4`}
                            />
                          </div>
                        ))}
                      </Form>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>

          <Row
            className="freight-quotes-aiming-transit"
            style={{
              display: "flex",
              flexWrap: "wrap",
              border: "1px solid transparent",
            }}
          >
            <Col style={{ border: "1px solid transparent" }}>
              <div style={{ width: "100%", border: "1px solid transparent" }}>
                <Form.Label style={{ width: "100%", textAlign: "left" }}>
                  Aiming Freight (Cost)<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Group
                  style={{
                    width: "100%",
                    border: "1px solid transparent",
                    display: "flex",
                    flexDirection: "row",
                  }}
                  controlId="formInput"
                >
                  <Form.Control
                    placeholder="Aiming Freight "
                    type="text"
                    name="AimingFreight"
                    value={formData.AimingFreight || ""}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col style={{ border: "1px solid transparent" }}>
              <div style={{ width: "100%", border: "1px solid transparent" }}>
                <Form.Label style={{ width: "100%", textAlign: "left" }}>
                  Transit Time Required<span style={{ color: "red" }}>*</span>
                </Form.Label>
                <Form.Group
                  style={{
                    width: "100%",
                    border: "1px solid transparent",
                    display: "flex",
                    flexDirection: "row",
                  }}
                  controlId="formInput"
                >
                  <Form.Select
                    aria-label="Default select example"
                    name="Transit Time Required"
                    onChange={handleChange}
                  >
                    <option>Number Of Days</option>
                    <option
                      // name="Transit Time Required"
                      value="1-3 Days"
                      // onChange={handleChange}
                    >
                      1-3 Days
                    </option>
                    <option
                      // name="Transit Time Required"
                      value="3-7 Days"
                      // onChange={handleChange}
                    >
                      3-7 Days
                    </option>
                    <option
                      // name="Transit Time Required"
                      value="7-15 Days"
                      // onChange={handleChange}
                    >
                      7-15 Days
                    </option>
                    <option
                      // name="Transit Time Required"
                      value="15-30 Days"
                      // onChange={handleChange}
                    >
                      15-30 Days{" "}
                    </option>
                    <option
                      // name="Transit Time Required"
                      value="30-60 Days"
                      // onChange={handleChange}
                    >
                      30-60 Days
                    </option>
                  </Form.Select>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Button
              className="my-2 "
              variant="outline-primary"
              style={{ width: "auto", margin: "auto" }}
              type="submit"
              onClick={handleSubmit}
            >
              Get Freights
            </Button>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
