import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
// import { submitForm, saveFormData } from "../../reducers/FormHomePageReducer";
import NavbarHeader from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import callback from "../images/callback.jpg";
import { addUser } from "../../reducers/FormSlice";

export const Callback = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/callback", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));

    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };

  return (
    <>
      <NavbarHeader />
      <h3 className="text-center privacy-policy my-3">Request Callback</h3>
      <div className="container my-3 w-100 d-flex">
        <div className="container-fluid  ">
          <div className="row">
            <div
              className="d-flex col-sm-12 col-md-6  h-auto"
              style={{ objectFit: "cover" }}
            >
              <img src={callback} className="img-fluid" alt="downloading..." />
            </div>
            <div
              className="col-sm-12 col-md-6 py-5 px-4"
              style={{
                // border: "1px solid red",
                margin: "auto",
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                height: "auto",
                border: "none",
              }}
            >
              <Form className="mt-2" onSubmit={handleSubmit}>
                <Form.Group className="mb-2 " controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Name
                  </Form.Label>
                  <InputGroup className="mb-4">
                    <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                    <Form.Control
                      placeholder="Username"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="name"
                      value={formData.name || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Email
                  </Form.Label>
                  <InputGroup className="mb-4">
                    <Form.Control
                      placeholder="Enter Your Email Id"
                      aria-label="Enter Your Email Id"
                      aria-describedby="basic-addon2"
                      name="email"
                      value={formData.email || ""}
                      onChange={handleChange}
                    />
                    <InputGroup.Text id="basic-addon2">
                      @gmail.com
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Phone Number
                  </Form.Label>
                  <InputGroup className="mb-4">
                    <InputGroup.Text id="basic-addon1">
                      Mobile No.
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Phone Number"
                      aria-label="Phone Number"
                      aria-describedby="basic-addon1"
                      name="phone"
                      value={formData.phone || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-4" controlId="formBasicEmail">
                  <Form.Label
                    style={{
                      display: "flex",
                      flexDirection: "left",
                      fontSize: "19px",
                      fontWeight: "500",
                    }}
                  >
                    Message
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text>With textarea</InputGroup.Text>
                    <Form.Control
                      as="textarea"
                      aria-label="With textarea"
                      name="message"
                      value={formData.message || ""}
                      onChange={handleChange}
                    />
                  </InputGroup>
                </Form.Group>
                <Button
                  className="my-1 "
                  variant="outline-primary"
                  type="submit"
                >
                  Submit
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
