import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const AllCategory = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://demo.businessworldtrade.com/api/getcategory"
        );
        setCategories(response.data);
        console.log("Categories data:", response.data);
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received. Check your network connection.");
        } else {
          // Something happened in setting up the request that triggered an error
          console.error("Error setting up the request:", error.message);
        }
      }
    };

    fetchData();
  }, []);

  const handleCategoryClick = (categoryId, categoryName) => {
    navigate(`/category/${categoryId}/${categoryName}`);
  };

  const handleSubCategoryClick = (subcategoryID, subCategoryName) => {
    navigate(`/category/${subcategoryID}`);
  };

  return (
    <>
      <Navbar />
      <div>
        {categories.map((category) => (
          <React.Fragment key={category._id}>
            <div className="ctg-agriculture">
              Home /{" "}
              <span
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => handleCategoryClick(category._id, category.name)}
              >
                {category.name}
              </span>
            </div>
            <Carousel responsive={responsive} style={{ width: "70px" }}>
              {category.children.map((subCategory) => (
                <div
                  className="ctg-agriculture-main-div"
                  key={subCategory._id}
                  onClick={() =>
                    handleSubCategoryClick(subCategory._id, subCategory.name)
                  }
                  style={{ cursor: "pointer" }} // Added cursor style
                >
                  <div className="ctg-agriculture-main-div-card">
                    {subCategory.images.length > 0 && (
                      <img
                        className="ctg-agriculture-main-div-card-img"
                        src={`https://demo.businessworldtrade.com/${subCategory.images[0].img}`}
                        alt={subCategory.name}
                      />
                    )}
                    <p
                      style={{
                        margin: "auto",
                        padding: "15px 0px 0px 0px",
                        fontWeight: "500",
                      }}
                    >
                      {subCategory.name}
                    </p>
                  </div>
                </div>
              ))}
            </Carousel>
          </React.Fragment>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default AllCategory;
