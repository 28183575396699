import axios from "axios";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
// import "react-calendar/dist/Calendar.css";

const AdminDashHome = () => {
  const [categoryCount, setCategoryCount] = useState(0); // State variable for buyers
  const [sellerCount, setSellerCount] = useState(0);
  const [buyerCount, setBuyerCount] = useState(0);
  const [totalCount, setTotalUserCount] = useState(0);
  const [otherCount, setotherCount] = useState(0);
  const [value, onChange] = useState(new Date());

  // const onChange = (date) => {
  //   setDate(date);
  // };

  function generateDateCategories(days) {
    const categories = [];
    const currentDate = new Date();
    for (let i = 0; i < days; i++) {
      const date = new Date(currentDate);
      date.setDate(date.getDate() + i);
      categories.push(formatDate(date)); // You can format the date as needed
    }
    return categories;
  }

  // Function to format a date as "YYYY-MM-DD" (you can customize the format)
  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    const fetchCategoryCount = async () => {
      try {
        const response = await axios.get(
          "https://demo.businessworldtrade.com/api/getcategory"
        );
        const categories = response.data;
        setCategoryCount(categories.length);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCategoryCount();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(
          "https://demo.businessworldtrade.com/api/Getalluser"
        );
        if (response.ok) {
          const data = await response.json();
          console.log("graph-data", data.users.length);
          const allUsers = data["User"] || [];

          console.log("All Users:", allUsers);

          const sellerCount = data.users.filter(
            (user) => user.role === "seller"
          ).length;
          const buyerCount = data.users.filter(
            (user) => user.role === "buyer"
          ).length;
          const otherCount = data.users.filter(
            (user) => user.role === "other"
          ).length;
          const totalCount = data.users.length;
          console.log(totalCount);
          setSellerCount(sellerCount);
          setBuyerCount(buyerCount);
          setotherCount(otherCount);
          setTotalUserCount(totalCount);
          // Update the state with new data
          const newState = { ...state }; // Create a copy of the state

          // Add buyerCount and sellerCount to the series data
          newState.series[0].data = newState.series[0].data.concat(buyerCount);
          newState.series[1].data = newState.series[1].data.concat(sellerCount);
          newState.series[2].data = newState.series[2].data.concat(otherCount);
          // Update the state with the new data
          setState(newState);
        } else {
          console.log("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    fetchUserData();
  }, []);

  const [state, setState] = useState({
    options: {
      chart: {
        id: "basic-bar444",
      },
      xaxis: {
        categories: generateDateCategories(7),
        // Generate date categories for one week
      },
    },
    series: [
      {
        name: "Buyer",
        data: [10, 20, 30, 40, 50, 60, 70],
      },
      {
        name: "Seller",
        data: [5, 15, 25, 35, 45, 55, 65],
      },
      {
        name: "Other",
        data: [8, 16, 24, 32, 40, 48, 56],
      },
    ],
  });

  return (
    <div className="col-12 admin-dash-cont">
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Dashboard</h1>
              </div>
              {/* /.col */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">Dashboard</li>
                </ol>
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            {/* Small boxes (Stat box) */}
            <div className="row">
              <div className="col-lg-3 col-6">
                <div className="small-box bg-info rounded">
                  <div className="inner">
                    <h3>{totalCount}</h3>
                    <p>Total Registrations</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag" />
                  </div>
                  <Link to="/TotalRegisterUser" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>
                      {buyerCount}
                      <sup style={{ fontSize: 20 }}></sup>
                    </h3>
                    <p>Total Buyers</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars" />
                  </div>
                  <Link to="/TotalBuyer" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>{sellerCount}</h3>
                    <p>Total Sellers</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <Link to="/TotalSeller" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
              </div>
              {/* ./col */}

              <div className="col-lg-3 col-6 ">
                {/* small box */}
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{categoryCount}</h3>
                    <p>Categories</p>
                  </div>
                  <div className="icon ">
                    <i className="ion ion-bag" />
                  </div>
                  <a href="/" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              {/* ./col */}
            </div>

            {/* /.row */}
            <div className="row">
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>150</h3>
                    <p>New Orders</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag" />
                  </div>
                  <a href="/" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>
                      53<sup style={{ fontSize: 20 }}>%</sup>
                    </h3>
                    <p>Bounce Rate</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-stats-bars" />
                  </div>
                  <a href="/" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>44</h3>
                    <p>Total Leads</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <a href="/" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>65</h3>
                    <p>Unique Visitors</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph" />
                  </div>
                  <a href="/" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </a>
                </div>
              </div>
              {/* ./col */}
            </div>

            {/* Main row */}
            <div className="row">
              {/* Left col */}
              <section className="col-lg-7 connectedSortable">
                {/* Custom tabs (Charts with tabs)*/}
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      <i className="fas fa-chart-pie mr-1" />
                      Registrations
                    </h3>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="tab-content p-0">
                      {/* Morris chart - Sales */}
                      <Chart
                        options={state.options}
                        series={state.series}
                        type="bar"
                        width="100%"
                      />
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </section>
              {/* /.Left col */}
              {/* right col (We are only adding the ID to make the widgets sortable)*/}
              <section className="col-lg-5 connectedSortable">
                {/* Map card */}
                {/* <div className="card bg-gradient-primary">
                  <div className="card-header border-0">
                    <h3 className="card-title">
                      <i className="fas fa-map-marker-alt mr-1" />
                      Visitors
                    </h3>
                    
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm daterange"
                        title="Date range"
                      >
                        <i className="far fa-calendar-alt" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                   
                  </div>
                  <div className="card-body">
                    <div
                      id="world-map"
                      style={{ height: 250, width: "100%" }}
                    />
                  </div>
                 
                  <div className="card-footer bg-transparent">
                    <div className="row">
                      <div className="col-4 text-center">
                        <div id="sparkline-1" />
                        <div className="text-white">Visitors</div>
                      </div>
                     
                      <div className="col-4 text-center">
                        <div id="sparkline-2" />
                        <div className="text-white">Online</div>
                      </div>
                     
                      <div className="col-4 text-center">
                        <div id="sparkline-3" />
                        <div className="text-white">Sales</div>
                      </div>
                    
                    </div>
                    
                  </div>
                </div> */}

                {/* /.card */}
                {/* Calendar */}
                <div className="card bg-gradient-success">
                  <div className="card-header border-0">
                    <h3 className="card-title">
                      <i className="far fa-calendar-alt " />
                      &nbsp; Calendar
                    </h3>
                    {/* tools card */}
                    {/* <div className="card-tools">
                      <div className="btn-group">
                        <button
                          type="button"
                          className="btn btn-success btn-sm dropdown-toggle"
                          data-toggle="dropdown"
                          data-offset={-52}
                        >
                          <i className="fas fa-bars" />
                        </button>
                        <div className="dropdown-menu" role="menu">
                          <a href="/" className="dropdown-item">
                            Add new event
                          </a>
                          <a href="/" className="dropdown-item">
                            Clear events
                          </a>
                          <div className="dropdown-divider" />
                          <a href="/" className="dropdown-item">
                            View calendar
                          </a>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        data-card-widget="collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        data-card-widget="remove"
                      >
                        <i className="fas fa-times" />
                      </button>
                    </div> */}
                    {/* /. tools */}
                  </div>
                  {/* /.card-header */}
                  <div className="card-body pt-2">
                    {/*The calendar */}
                    <div id="calendar" style={{ width: "100%" }}>
                      <Calendar onChange={onChange} value={value} />
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
              </section>
              {/* right col */}
            </div>
            {/* /.row (main row) */}
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}
    </div>
  );
};

export default AdminDashHome;
