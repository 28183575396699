import React from "react";

const Service = () => {
  return (
    <>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6"></div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        {/* /.content-header */}
        {/* Main content */}
        <section className="content">
          <div className="heading">
            <div className="">
              <h2>Benefits of our Service Packages</h2>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* /.content */}

        <div className="">
          <div className="container-fluid">
            <img
              src="images/header.png"
              style={{ width: "100%" }}
              alt="img"
            ></img>
          </div>
        </div>

        <div className="container-fluid w-100 serviceflex">
          <div className="services-css">
            <div className="box">
              <img src="images/b.png" alt="hello"></img>
              <label>Silver Package</label>
            </div>
            <div className="box">
              <img src="images/gold.png" alt="hello"></img>
              <label>Gold Package</label>
            </div>

            <div className="box">
              <img src="images/diamond.png" alt="name"></img>
              <label>Diamond Package</label>
            </div>
            <div className="box">
              <img src="images/platinum.png" alt="product"></img>
              <label>Platinum Package</label>
            </div>
            <div className="box">
              <img src="images/bronze.png" alt="prductName"></img>
              <label>Standard Package</label>
            </div>
            <div className="box">
              <img src="images/premium.png" alt="product"></img>
              <label>Premium Package</label>
            </div>
            <div className="box">
              <img src="images/sapphire.png" alt="product"></img>
              <label>Sapphire Package</label>
            </div>
            <div className="box">
              <img src="images/star.png" alt="hello"></img>
              <label>Star Package</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
