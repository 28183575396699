import React from "react";

export default function TopSupplier(props) {
  return (
    <div
      className="card"
      style={{
        width: "90%",
        height: "90%",
        borderRadius: "80px",
        margin: "auto",
        border: "2px solid grey",
      }}
    >
      <img
        style={{
          width: "80%",
          height: "87%",
          margin: "auto",
        }}
        className="product--image"
        src={props.url}
        alt="error 404"
      />
    </div>
  );
}
