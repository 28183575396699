import React, { useEffect } from "react";
import Img from "../images/tum.jpg";

const AboutMe = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="about-me mt-4">
      <h2
        style={{
          fontSize: "28px",
          fontStyle: "oblique",
        }}
      >
        About{" "}
        <span
          style={{
            color: "red",
            fontWeight: "700",
            fontSize: "30px",
            fontStyle: "oblique",
          }}
        >
          Business World Trade
        </span>
      </h2>
      <img src={Img} alt="error" />
      <div className="paragraph-slider">
        <p className=" text-muted pe-2">
          Welcome to Business World Trade, the premier B2B platform that is
          revolutionizing the way businesses trade and thrive in today's dynamic
          marketplace. Our mission is to provide a robust and seamless platform
          that connects buyers and sellers from around the globe, facilitating
          efficient and transparent transactions.
        </p>
        <p className="text-muted pe-2">
          At Business World Trade, we are dedicated to empowering businesses of
          all sizes, creating opportunities for growth, collaboration, and
          success. Our innovative solutions simplify the trading process,
          enabling efficient transactions and building strong, long-lasting
          business relationships.
        </p>
        <p className="text-muted pe-2">
          With a focus on trust, transparency, and fair trade practices, we aim
          to drive global success by fostering collaboration and facilitating
          trusted transactions.
        </p>
      </div>
    </div>
  );
};

export default AboutMe;
