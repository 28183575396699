// formSlice.js
import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    // loading: false,
    // error: null,
    // searchData: [],
  },
  reducers: {
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
  },
});

export const { addUser } = formSlice.actions;
export default formSlice.reducer;
