import React, { useEffect } from "react";
import NavbarHeader from "../../Homepage/Navbar";
import Footer from "../../Homepage/Footer";
import background from "../../images/web-design-banner.jpg";
export const Diamond = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const pstyle = {
    textAlign: "left",
    color: "grey",
    fontSize: "17px",
  };

  const spanstyle = {
    fontSize: "20px",
    fontWeight: "500",
    color: "rgb(65, 65, 65)",
  };
  return (
    <>
      <NavbarHeader />
      <>
        {/* about banner start */}
        <div
          className="container-fluid webdesigning-banner gap py-5"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: " no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="row py-5">
            <div className="col-12 text-center text-light pt-3">
              <h5>WE ENHANCING YOUR BUSINESS </h5>
            </div>
            <div className="col-12 text-center  pb-3 banner-head">
              <h1 className="text-light">DIAMOND PACKAGE</h1>
            </div>
          </div>
        </div>
        {/* about banner start */}
        {/* PACkage start */}
        <h2 className="my-5 privacy-policy text-center">
          BENEFITS OF DIAMOND PACKAGE
        </h2>
        <div
          className="container my-5"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <div className="row px-2">
            <div className="col-sm-12 col-12 pt-4 mt-2 mb-3">
              {/* <h2 className="package-head text-center">
                Benifits Of DAIMOND PACKAGE
              </h2> */}
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Professional Website
                </span>
                <br />
                Get a visually stunning and feature-rich website that represents
                your brand and captivates visitors.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Domain Registration{" "}
                </span>
                <br />
                Register a unique domain name that reflects your business
                identity and enhances your online presence.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Favicon{" "}
                </span>
                <br />
                Add a custom favicon to your website, making it easily
                recognizable and reinforcing your brand image.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Logistic Quotation{" "}
                </span>
                <br />
                Integrate logistic quotation services to provide customers with
                seamless shipping options and transparent cost calculations.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Language Converter{" "}
                </span>
                <br />
                Offer multilingual support with a language converter feature,
                allowing visitors to view your website in their preferred
                language.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  25 Product List
                </span>
                <br />
                Display up to 25 products with detailed descriptions,
                specifications, and high-quality images, enticing customers to
                make informed purchase decisions.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Mobile Version{" "}
                </span>
                <br />
                Ensure a seamless user experience across all devices with a
                mobile-responsive version of your website, reaching customers on
                smartphones and tablets.
                <br />
                With the Diamond Package, you can elevate your online presence
                and enhance customer engagement with a professional website,
                domain registration, custom favicon, logistic quotation
                integration, language converter, 25 product listings, and a
                mobile version.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Impeccable Design
                </span>
                <br />
                Impress your audience with a visually stunning and modern
                website design that reflects your brand's personality and
                values.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Enhanced User Experience{" "}
                </span>
                <br />
                Provide a seamless browsing experience to visitors on all
                devices, ensuring optimal performance and easy navigation.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Brand Consistency
                </span>
                <br />
                Establish a consistent brand image with a custom favicon that
                reinforces your brand identity across various browser tabs.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Streamlined Shipping{" "}
                </span>
                <br />
                Simplify the shipping process for customers by integrating
                logistic quotation services, allowing them to calculate shipping
                costs accurately.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Global Reach
                </span>
                <br />
                Attract international customers by offering a language converter
                feature, breaking language barriers and enhancing user
                experience.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Enhanced User Experience{" "}
                </span>
                <br />
                Provide a seamless browsing experience to visitors on all
                devices, ensuring optimal performance and easy navigation.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Extensive Product Showcase
                </span>
                <br />
                Showcase up to 25 products in a visually appealing and
                informative manner, enabling customers to explore your offerings
                in detail.
              </p>
              <p style={pstyle}>
                <span className="package-subhead" style={spanstyle}>
                  Mobile Accessibility{" "}
                </span>
                <br />
                Reach customers on the go with a mobile-responsive version of
                your website, ensuring a seamless experience on smartphones and
                tablets. The Diamond Package provides you with a comprehensive
                set of tools and features to establish a strong online presence,
                engage customers effectively, and showcase your products or
                services in an appealing manner.
              </p>
            </div>
          </div>
        </div>
        {/* PACkage end */}
      </>

      <Footer />
    </>
  );
};
