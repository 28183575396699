import React from "react";
import { useState } from "react";
import "bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";
import axios from "axios";
import Onion from "../images/homePageImg/agriculture product/onion-01.jpg";
import UradDhulli from "../images/homePageImg/agriculture product/uraddhulli.jpg";
import BasmatiRice from "../images/homePageImg/agriculture product/basmatirice.jpg";
import Seasameseeds from "../images/homePageImg/agriculture product/seasameseeds.jpg";
import Cotton from "../images/homePageImg/agriculture product/cotton.jpg";
import Tea from "../images/homePageImg/agriculture product/tea-01.jpg";
import MustardSeeds from "../images/homePageImg/agriculture product/mustardseeds.jpg";

const AgricultureProducts = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const Agriculture = [
    {
      img: Onion,
      soldBy: "Pankaj Kapoor",
      name: "Onion",
      description: "Wholeseller: Himachal Pradesh",
    },
    {
      img: UradDhulli,
      soldBy: "Manoj Dosi",
      name: "Urad Dhulli",
      description: "Wholeseller: Jaipur, Rajasthan",
    },
    {
      img: BasmatiRice,
      soldBy: "Vishal Tamoli",
      name: "Basmati Rice",
      description: "Retailer: Indore, M.P",
    },
    {
      img: Seasameseeds,
      soldBy: "Asif Shaikh",
      name: "Sesame Seed",
      description: "Wholeseller: Sangola Maharashtra",
    },
    {
      img: Cotton,
      soldBy: "Ajay Verma",
      name: "Cotton",
      description: "Wholeseller: Aligarh, U.P",
    },
    {
      img: Tea,
      soldBy: "Mangiram Goyal",
      name: "Tea",
      description: "Wholeseller: Agra",
    },
    {
      img: MustardSeeds,
      soldBy: "Harshit Mishra",
      name: "Mustard Seeds",
      description: "Wholeseller: Amethi, U.P",
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowModal(false);
  };

  const handleEnquiryChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/createInquries", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        console.log(res);
      })
      .catch((err) => console.log(err));
    handleCloseModal();
    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };
  return (
    <div>
      <h1
        className="my-3"
        style={{
          color: "#444444",
          fontWeight: "700",
        }}
      >
        Agriculture Products
      </h1>
      <Carousel responsive={responsive}>
        {Agriculture.map((product) => (
          <div
            key={product.id}
            className="card"
            style={{
              borderRadius: "20px 20px 0px 0px",
              width: "85%",
              margin: "auto",
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            }}
          >
            <img
              style={{
                width: "80%",
                height: "220px",
                borderRadius: "20px",
                margin: "auto",
              }}
              className="product--image mt-4"
              src={product.img}
              alt={product.name}
            />
            <h2 style={{ fontSize: "24px", textAlign: "center" }}>
              {product.name}
            </h2>
            <p style={{ color: "gray", textAlign: "center" }}>
              Sold by: {product.soldBy}{" "}
            </p>
            <p style={{ textAlign: "center" }}>{product.description}</p>
            <p style={{ textAlign: "center" }}>
              <button
                style={{
                  marginBottom: "20px",
                  padding: "5px 10px",
                  borderRadius: "10px",
                  borderColor: "transparent",
                  boxShadow: "rgb(14,81,118) 0px 10px 10px -10px",
                  fontSize: "15px",
                }}
                onClick={() => handleOpenModal(product)}
              >
                Get Enquiry
              </button>
            </p>
          </div>
        ))}
      </Carousel>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Get Enquiry for {selectedProduct?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Add your inquiry form or content here */}
          {/* For example, you can include an email input and a textarea */}
          <form onSubmit={handleSubmit}>
            <div className="mb-1">
              <label htmlFor="email">Name</label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleEnquiryChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email">Phone Number</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleEnquiryChange}
              />
            </div>

            <Button variant="primary" type="submit">
              Send Enquiry
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AgricultureProducts;
