import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../images/bwt-dark.png";
import { BiSearch } from "react-icons/bi";
import { FiHeadphones } from "react-icons/fi";
import ProductMenu from "../Homepage/ProductMenu";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useNavigate } from "react-router-dom";

import { useSearch } from "../../context/search";
import { useAuth, useUser } from "../../context/AuthCotext";
import axios from "axios";

const NavbarHeader = () => {
  const navigate = useNavigate();
  const { isLoggedIn, logIn, logOut } = useAuth();
  const { userRole } = useUser();
  const [values, setValues] = useSearch();
  const [count, setCount] = useState(12234567);

  useEffect(() => {
    const interval = setInterval(
      () => setCount((prevCount) => prevCount + 5),
      600000
    );
    return () => clearInterval(interval);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.get(`/api/search/${values.keyword}`);
      setValues({ ...values, results: data });
      if (values.keyword) {
        navigate(`/search/${values.keyword}`);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getDashboardLink = () => {
    if (isLoggedIn) {
      switch (userRole) {
        case "admin":
          return "/admin";
        case "seller":
          return "/sellerhome";
        default:
          return "/";
      }
    }
    return "/";
  };

  return (
    <>
      <Navbar bg="light" expand="lg" style={{ width: "100%" }}>
        <Container fluid>
          <Navbar.Brand>
            <Link to="/">
              <img
                src={logo}
                alt="error"
                style={{
                  width: "250px",
                  height: "60px",
                  marginLeft: "20px",
                }}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Form className="d-flex mx-7 search-box" onSubmit={handleSubmit}>
              <Form.Control
                type="search"
                placeholder="Search Products as Buyer/Seller"
                className="me-2"
                aria-label="Search"
                value={values.keyword}
                style={{
                  borderRadius: "30px 0px 0px 30px",
                  boxShadow: "none",
                }}
                onChange={(e) =>
                  setValues({ ...values, keyword: e.target.value })
                }
              />
              <Button
                className="mic-button"
                variant="outline-success"
                type="submit"
              >
                <i>
                  <BiSearch />
                </i>
              </Button>
            </Form>
            <div
              className="d-flex row mr-3 d-sm-none d-md-block border mb-2 rounded "
              style={{
                alignItems: "center",
                alignSelf: "center",
                justifyContent: "center",
                borderColor: "Gainsboro",
              }}
            >
              <div className="users d-flex text-center rounded fw-bold">
                Registered Users
              </div>
              <div
                className="count text-center  fw-bold"
                style={{ backgroundColor: "Gainsboro", color: "#155b77" }}
              >
                {count.toLocaleString()}
              </div>
            </div>
            <div
              className="d-flex buyer-seller"
              style={{ paddingRight: "130px" }}
            >
              <NavDropdown
                title="Buyer"
                id="navbarScrollingDropdown"
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  color: "#3d3d3d",
                  padding: "0px 20px 0px 0px",
                }}
              >
                <Dropdown.Item onClick={() => navigate("/post-by-requirement")}>
                  Post By Requirement
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/search-supplier")}>
                  Search Supplier
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/request-callback")}>
                  Request Callback
                </Dropdown.Item>
              </NavDropdown>
              <NavDropdown
                title="Seller"
                id="navbarScrollingDropdown"
                style={{
                  fontSize: "16.5px",
                  fontWeight: "600",
                  color: "#3d3d3d",
                  padding: "0px 20px 0px 0px",
                }}
              >
                <Dropdown.Item onClick={() => navigate("/search-buy-leads")}>
                  Search Buy Leads
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/freight-quotes")}>
                  Get Freight Quotes
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/logistics")}>
                  Logistics
                </Dropdown.Item>
                <Dropdown.Item onClick={() => navigate("/iso-certification")}>
                  ISO Certification
                </Dropdown.Item>
              </NavDropdown>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Navbar
        className="navbar-bottom"
        bg="light"
        expand="lg"
        style={{
          marginTop: "-20px",
          padding: "0px 30px 0px 20px",
        }}
      >
        <Container fluid>
          <Navbar.Collapse className="d-flex justify-content-between Navbar-Collapse">
            <div className="product-Menu">
              <ProductMenu />
            </div>
            <div className="Help-Center">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
              >
                <Nav.Link
                  as={Link}
                  to="/contact-us"
                  className="mx-2 right-section"
                >
                  <FiHeadphones /> Help Center
                </Nav.Link>

                {/* Conditional rendering for Logout and Dashboard links */}
                {isLoggedIn ? (
                  <>
                    <Button variant="outline-danger" onClick={logOut}>
                      Logout
                    </Button>
                    <Nav.Link
                      as={Link}
                      to={getDashboardLink()}
                      className="mx-2 right-section"
                    >
                      Dashboard
                    </Nav.Link>
                  </>
                ) : (
                  <>
                    <Link to="/login" className="mx-2 right-section">
                      Login
                    </Link>
                    <Nav.Link
                      as={Link}
                      to="/register"
                      className="mx-2 right-section"
                    >
                      Register Free
                    </Nav.Link>
                  </>
                )}
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarHeader;
