import React from "react";
import { GoComment } from "react-icons/go";
import { AiOutlineDelete } from "react-icons/ai";
import { IoCheckmarkSharp } from "react-icons/io5";

function Logistics() {
  return (
    <>
      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <div
            className="adminSubNav"
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <h3
              className=" mt-2"
              style={{
                margin: "auto",
                color: "#0e5176",
                fontWeight: "bold",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              Logistics Inquiries
            </h3>
          </div>
          <hr />

          <div className="table-section">
            <div className="tablenav">
              <div className="sorting ml-2">
                <label>
                  Show{" "}
                  <select
                    name="example_length"
                    aria-controls="example"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div className="tableSearchbar mb-3 mr-2">
                <form className="d-flex " role="search">
                  <input
                    className="form-control me-2"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                  />
                  <button className="btn btn-outline-primary" type="submit">
                    Search
                  </button>
                </form>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr className="text-center">
                    <th scope="col">S.NO</th>
                    <th scope="col">NAME</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">MOBILE</th>
                    <th scope="col">LOAD TYPE</th>
                    <th scope="col">SHIPMENT PRICE</th>
                    <th scope="col">PICKUP DATE</th>
                    <th scope="col">PICKUP PINCODE</th>
                    <th scope="col">DELIVERY CODE</th>
                    <th scope="col">COMMENT</th>
                    <th scope="col">DATE/TIME</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center">
                    <th scope="row">1</th>
                    <td>Ramesh</td>
                    <td>Ramesh@gmail.com</td>
                    <td>9876543210</td>
                    <td>Buyer</td>
                    <td>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </td>
                    <td>Contact Us</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>N/A</td>
                    <td>17-01-2023</td>
                    <td>
                      <div className="buttonsEnq ">
                        {/* <button
                        type="button"
                        className="btn btn-success btn-sm p-0"
                        title="Comment"
                      > */}
                        <GoComment
                          size={15}
                          className="btn btn-success btn-sm me-2 p-0"
                          title="Comment"
                        />
                        {/* </button> */}
                        {/* <button
                        type="button"
                        className="btn btn-warning btn-sm p-0"
                        title="Verify"
                      > */}
                        <IoCheckmarkSharp
                          size={15}
                          className="btn btn-warning btn-sm me-2 p-0"
                          title="Verify"
                        />
                        {/* </button> */}
                        {/* <button
                        type="button"
                        className="btn btn-danger btn-sm p-0"
                        title="Delete"
                      > */}
                        <AiOutlineDelete
                          size={15}
                          className="btn btn-danger btn-sm p-0"
                          title="Delete"
                        />
                        {/* </button> */}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Logistics;
