import React, { useState } from "react";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { IoIosSend } from "react-icons/io";
import { IoCheckmarkSharp, IoAdd } from "react-icons/io5";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const AdminAddProduct = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product Requirement Enquiry</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form
            className="row"
            action="/"
            method="POST"
            id="popupInquiryForm"
            data-gtm-form-interact-id="0"
          >
            <input type="hidden" name="_token" value="hidden-token" />
            <div className="form-group col-sm-6 modal-form">
              <input
                type="text"
                className="form-control shadow form-modal modal-input"
                placeholder="Product Name"
                name="user_name"
                id="user_name"
              />
            </div>
            <div className="form-group col-sm-6 modal-form">
              <input
                type="text"
                className="form-control shadow form-modal modal-input"
                placeholder="Buyer Name"
                name="user_name"
                id="user_name"
              />
            </div>
            <div className="form-group col-sm-6 modal-form">
              <input
                className="form-control shadow form-modal modal-input"
                type="Email"
                placeholder="Enter your Email"
                required
              />
            </div>
            <div className="form-group col-sm-6 modal-form">
              <input
                type="tel"
                className="form-control shadow modal-input"
                placeholder="Contact No."
                id="phone"
                name="phone"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              />
            </div>

            <div className="form-group modal-form-group col-sm-12">
              <textarea
                rows="4"
                className="form-control shadow modal-input"
                name="requirements"
                id="requirements"
                placeholder="Product Description"
              ></textarea>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ backgroundColor: "#0e5176" }} onClick={handleClose}>
            Add Product
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-12 admin-dash-cont">
        {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          <div
            className="adminSubNav"
            style={{
              display: "flex",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <h3
              className=" mt-2"
              style={{
                margin: "auto",
                color: "#0e5176",
                fontWeight: "bold",
                // display: "flex",
                // justifyContent: "center",
              }}
            >
              Add Product
            </h3>
          </div>
          <hr />

          <div className=" table-section">
            <div className="tablenav">
              <div className="sorting ">
                {/* <div className="buttonsEnqq mx-auto">
                      <button type="button" className="btn btn-success">
                        Export to Excel
                      </button>
                      <button
                        type="button"
                        className="btn btn-success ml-2"
                        onClick={handleShow}
                      >
                        <IoAdd /> Add Inquiry
                      </button>
                    </div> */}
                {/* <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn btn-outline-primary " type="button">
                    Export to Excel
                  </button>
                  <button
                    class="btn btn-outline-primary"
                    type="button"
                    onClick={handleShow}
                  >
                    Add Inquiry
                  </button>
                </div> */}
              </div>
              <div className="tableSearchbar mb-3 mr-2">
                {/* <form className="d-flex "> */}
                <button
                  className="btn btn-outline-primary"
                  type="submit"
                  onClick={handleShow}
                >
                  Add Product
                </button>
                {/* </form> */}
              </div>
            </div>
            <div className="table-responsive">
              <table className=" table  table-hover  h-auto">
                <thead className="text-center">
                  <tr>
                    <th scope="col" className=" ms-5">
                      S.NO
                    </th>
                    <th scope="col">NAME</th>
                    <th scope="col">EMAIL</th>
                    <th scope="col">MOBILE NO.</th>
                    <th scope="col">PRODUCT NAME</th>
                    <th scope="col">REQUIREMENT</th>
                    <th scope="col">DATE/TIME</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="text-center px-2">
                    <th scope="row">1</th>
                    <td>Ramesh</td>
                    <td>Ramesh@gmail.com</td>
                    <td>9876543210</td>
                    <td>
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    </td>
                    <td>N/A</td>
                    <td>17-01-2023</td>
                    <td>
                      <div className="buttonsEnq ">
                        <AiOutlineEdit
                          size={20}
                          className="btn btn-primary btn-sm p-0 me-1 "
                          title="Edit"
                        />
                        <AiOutlineDelete
                          size={20}
                          className="btn btn-danger btn-sm p-0 me-1"
                          title="Delete"
                        />
                        <IoCheckmarkSharp
                          size={20}
                          className="btn btn-warning btn-sm p-0 me-1"
                          title="Verify"
                        />
                        <IoIosSend
                          size={20}
                          className="btn btn-success btn-sm p-0"
                          title="Send Seller Lead"
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminAddProduct;
