import React, { useEffect } from "react";
import NavbarHeader from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import BusinessLoanBanner from "../images/businessloan.jpg";
// import background from "../images/Backe.jpeg";
export const BussinessLoan = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <NavbarHeader />
      {/* about banner start */}
      <div
        className="container-fluid contactus-banner gap py-5 mb-4 "
        style={{
          backgroundImage: `url(${BusinessLoanBanner})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="row py-5">
          <div className="col-12 text-center text-light pt-3">
            <h5 style={{ fontSize: "30px", fontWeight: "bold" }}>
              Business Loan
            </h5>
          </div>
          <div className="col-12 text-center text-light pb-3 banner-head">
            <h1
              style={{
                fontFamily: "Poppins, sans-serif",
                fontWeight: "800 ",
                color: "white",
              }}
            >
              {" "}
              Empowering Entrepreneurs for Growth and Success{" "}
            </h1>
          </div>
        </div>
      </div>
      {/* about banner start */}
      {/* <h3 className="text-center privacy-policy my-3">
        Business Loans:
        <br /> Empowering Entrepreneurs for Growth and Success
      </h3> */}
      {/* <img src={loan} style={{width:"100%",paddingBottom:'20px'}}/> */}
      <div
        className="privacy-policy-content"
        // style={{
        //   backgroundImage: `url(${background})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        //   opacity: "0.9",
        // }}
      >
        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Introduction:
          </span>
          <br></br>In today's dynamic business world, access to timely financial
          resources is crucial for the growth and expansion of enterprises. As
          an esteemed company known as "Business World Trade," we understand the
          significance of capital infusion for businesses to seize opportunities
          and overcome challenges. That's why we proudly <br></br>offer tailored
          business loan solutions designed to fuel entrepreneurial dreams and
          drive economic progress.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Why Choose Business World Trade for Your Business Loan Needs
          </span>
          <br></br>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Expertise in Business Financing:
          </span>{" "}
          With years of experience in the industry, Business World Trade has
          earned a reputation as a trusted financial partner for businesses of
          all sizes. Our team of experts possesses comprehensive knowledge of
          various industries and understands the unique challenges entrepreneurs
          face.
          <p></p>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Customized Loan Solutions:
          </span>{" "}
          We believe that every business is unique, and their financing
          requirements vary. At Business World Trade, we offer personalized loan
          solutions tailored to suit your specific needs, ensuring that you
          receive the funds necessary to propel your business forward. Quick and
          Efficient Application Process: We value your time and understand the
          urgency of your business needs. Our streamlined loan application
          process ensures a hassle-free experience, with fast approvals and
          minimal documentation requirements. Competitive Interest Rates and
          Flexible Repayment Terms: We strive to make business loans affordable
          for our clients. Our interest rates are competitive, and we offer
          flexible repayment terms, allowing you to focus on growing your
          business without financial stress.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Competitive Interest Rates and Flexible Repayment Terms:
          </span>
          <br></br>
          We strive to make business loans affordable for our clients. Our
          interest rates are competitive, and we offer flexible repayment terms,
          allowing you to focus on growing your business without financial
          stress.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Business Loan Products Offered
          </span>
          <br></br>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Working Capital Loans:
          </span>{" "}
          These loans are designed to help businesses manage their day-to-day
          operations, maintain a healthy cash flow, and meet their short-term
          financial requirements. Whether it's funding inventory purchases,
          paying salaries, or covering operational expenses, our working capital
          loans provide the necessary financial support.
          <p></p>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Equipment Financing:
          </span>{" "}
          Upgrading or purchasing new equipment is often essential for
          businesses to enhance productivity and competitiveness. Business World
          Trade offers equipment financing options that enable you to acquire
          the machinery, tools, or technology needed to drive your business
          forward.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Expansion and Growth Capital:
          </span>
          <br></br>
          When opportunities for expansion arise, businesses need adequate
          capital to seize them. Our expansion and growth capital loans provide
          the financial backing required to scale your operations, enter new
          markets, launch new products, or invest in research and development.
        </p>
        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "20px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Eligibility Criteria and Application Process:
          </span>
          <br></br>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Eligibility:{" "}
          </span>{" "}
          Business World Trade extends its loan facilities to a wide range of
          businesses. We consider factors such as business performance, credit
          history, collateral, and future prospects while assessing loan
          applications. Our aim is to support as many deserving entrepreneurs as
          possible.
          <p></p>
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Application Process:
          </span>{" "}
          Applying for a business loan with Business World Trade is simple. You
          can either visit our website or reach out to our dedicated customer
          service team. Our representatives will guide you through the
          application process, helping you gather the necessary documents and
          assisting with any queries you may have.
        </p>

        <p
          style={{
            textAlign: "left",
            color: "grey",
            fontSize: "17px",
          }}
        >
          <span
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "rgb(65, 65, 65)",
            }}
          >
            Conclusion:
          </span>
          <br></br>
          At Business World Trade, we are committed to fostering
          entrepreneurship and supporting the growth aspirations of businesses.
          Our business loans provide the necessary financial backing, empowering
          entrepreneurs to thrive and achieve their goals. Whether you require
          working capital, equipment financing, or expansion capital, we have
          the expertise and resources to meet your business loan needs. Partner
          with us today and unlock the potential of your business.
        </p>
      </div>
      <Footer />
    </>
  );
};
