import React, { useState, useEffect, useContext } from "react";
import Navbar from "../Homepage/Navbar";
import Footer from "../Homepage/Footer";
import { BsFillGridFill } from "react-icons/bs";
import { GiHamburgerMenu, GiCutDiamond } from "react-icons/gi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../index.css";
import gold from ".././images/gold icon-01.jpg";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { addUser } from "../../reducers/FormSlice";

const SearchProductpage = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [currentDesign, setCurrentDesign] = useState("grid");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const [productData, setProductData] = useState(null);
  const [productName, setproductName] = useState(null);
  const [profile, setprofile] = useState(null);
  const { searchQuery } = useParams();
  const [userProfileId, setUserProfileId] = useState({}); // State to store contact profile data
  const [businessName, setbusinessName] = useState({});
  // const { userProfileId, setUserProfileId } = useContext(UserProfileProvider);

  const handleEnquiryChange = (e, props) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://demo.businessworldtrade.com/api/createInquries", formData)
      .then((res) => {
        dispatch(addUser(res.data));
        // console.log("enquiry data", res);
      })
      .catch((err) => console.log(err));
    handleCloseModal();

    // Reset the form after submission if needed
    setFormData({});
    console.log(formData);
  };

  useEffect(() => {
    const searchData = async () => {
      try {
        const response = await axios.get(
          `https://demo.businessworldtrade.com/api/search/${searchQuery}`
        );
        setProductData(response.data);
        setproductName(response.data.results[0].name);
        setUserProfileId(response.data.results[0].userId);
        // setUserProfileId(response.data.results[0].contactProfile);
        // setbusinessName(response.data.results[0].businessName);
        console.log("hellooooo ", response.data);
        // console.log("userId", userProfileId);
        // console.log("hellooooo id ", response.data.results[0].contactProfile);
        // console.log("userProfileId ", response.data.results[0].userId);
        // console.log("hellooooo ", response.data.results[0].businessName);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    searchData();
  }, [searchQuery]);

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await axios.get(
          `https://demo.businessworldtrade.com/api/GetContactProfile`
        );
        setprofile(response.data);
        // console.log(response.data);
        // console.log("hello world ", response.data);
      } catch (error) {
        console.error("Error fetching product data:", error);
      }
    };

    getProfile();
  }, []);

  const navigate = useNavigate();

  const getMatchingProfile = () => {
    if (productData && profile) {
      const matchingProfile = profile.FetchData.find(
        (profileData) => profileData.userId === productData.results[0].userId
      );
      return matchingProfile;
    }
    return null;
  };

  const matchingProfile = getMatchingProfile();

  const handleMouseEnter = () => {
    //grid-design
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    //grid-design
    setIsHovering(false);
  };

  const responsive = {
    // Define your responsive settings here
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // Number of slides to scroll when clicking arrows
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const [expandedCardId, setExpandedCardId] = useState(null);

  const handleCardMouseEnter = (productId) => {
    setExpandedCardId(productId);
  };

  const handleCardMouseLeave = () => {
    setExpandedCardId(null);
  };

  const handleClick = (product, matchingProfile) => {
    navigate(`/product-det/${product._id}`, {
      state: { product, matchingProfile },
    });
  };

  const handleOpenModal = (product) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleButtonClick = () => {
    // console.log("productData", productData);
    navigate(`/catalog/${productData.results[0].userId}`, {
      state: { productData: productData },
    });
  };

  return (
    <>
      <Navbar />

      <div className="ctg-agriculture w-100">
        Home /{" "}
        <span style={{ color: "red", cursor: "pointer" }}>{productName}</span>
      </div>
      <div className="mt-4">
        <div className="container w-75 ">
          <div className="m-2">
            <div className="row justify-content-end">
              <div className="col-6 col-md-3 d-flex justify-content-end">
                <button
                  className="border text-center btn me-2"
                  onClick={() => setCurrentDesign("grid")}
                >
                  <BsFillGridFill size={25} />
                </button>
                <button
                  className="border text-center btn"
                  onClick={() => setCurrentDesign("menu")}
                >
                  <GiHamburgerMenu size={25} />
                </button>
              </div>
            </div>
            <br />

            {/****************** Grid Design *****************/}

            <div className="row  ">
              {currentDesign === "grid" && (
                <div>
                  {productData?.results && productData.results.length > 0 ? (
                    productData.results.map((product) => (
                      <div className="col-md-12 d-md-flex flex-direction-column   shadow p-3 mb-5 bg-body rounded grid-design">
                        <div className="col-md-4 col-sm-12">
                          <div className="row ">
                            <div className="col-12 d-md-none col-sm-4">
                              {/* Content here */}
                              {matchingProfile && (
                                <div
                                  className="text-white fw-bold"
                                  style={{
                                    backgroundImage:
                                      "linear-gradient(to top, #30cfd0 0%, #330867 100%)",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    padding: "5px",
                                    fontSize: "18px",
                                    lineHeight: "2",
                                  }}
                                >
                                  {matchingProfile.packageType}
                                </div>
                              )}
                            </div>
                            <div className="col-md-1 d-none d-md-block pe-1 ">
                              {/* Content here */}
                              {matchingProfile && (
                                <div
                                  className="text-white fw-bold "
                                  style={{
                                    backgroundImage:
                                      "linear-gradient(to top, #30cfd0 0%, #330867 100%)",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    writingMode: "vertical-lr", // This sets the vertical writing mode
                                    textOrientation: "downward", // This ensures the text is not rotated
                                    padding: "5px", // Add some padding to improve visibility
                                    fontSize: "18px", // Adjust the font size as needed
                                    lineHeight: "2", // Adjust the line height as needed
                                    transform: "rotate(180deg)",
                                    height: "38vh",
                                  }}
                                >
                                  {matchingProfile.packageType}
                                </div>
                              )}
                            </div>
                            {/* <div className="col-md-2"></div> */}
                            <div className="col-md-10 col-sm-8  ">
                              <Carousel
                                responsive={responsive}
                                keyBoardControl={false}
                                containerClass="carousel-container"
                              >
                                {product.productPictures &&
                                product.productPictures.length > 0 ? (
                                  product.productPictures.map(
                                    (picture, index) => (
                                      <div
                                        className="my-3 ms-4  ps-3 container-row-column-two-div "
                                        key={picture._id}
                                      >
                                        <img
                                          style={{
                                            height: "35vh",
                                            width: "70vh",
                                          }}
                                          src={`https://demo.businessworldtrade.com/${picture.img}`}
                                          alt={`Product ${index}`}
                                          className="img-fluid carousel-img"
                                        />
                                      </div>
                                    )
                                  )
                                ) : (
                                  <div className="my-3 mx-3 container-row-column-two-div">
                                    <img
                                      style={{
                                        height: "35vh",
                                        width: "70vh",
                                      }}
                                      src={"#"}
                                      alt=""
                                      className="img-fluid carousel-img"
                                    />
                                  </div>
                                )}
                              </Carousel>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5 col-sm-12">
                          <div
                            className="fs-5 fw-bold ms-4 mt-2"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            key={product._id}
                            onClick={() =>
                              handleClick(product, matchingProfile)
                            }
                          >
                            {product.name}
                          </div>

                          <hr style={{ border: "1px solid" }} />
                          <div className="container">
                            <div className="row">
                              <div className="col-6">
                                <div className="ms-4">
                                  <div className="d-flex flex-column">
                                    <p>
                                      <span
                                        style={{
                                          color: "grey",
                                          marginRight: "5px",
                                          display: "inline",
                                        }}
                                      >
                                        Price:
                                      </span>{" "}
                                      {product.price}
                                    </p>
                                    <p>
                                      <span
                                        style={{
                                          color: "grey",
                                          marginRight: "5px",
                                          display: "inline",
                                        }}
                                      >
                                        Quantity:
                                      </span>{" "}
                                      {product.quantity}
                                    </p>
                                    <p>
                                      <span
                                        style={{
                                          color: "grey",
                                          marginRight: "5px",
                                          display: "inline",
                                        }}
                                      >
                                        Min. Order Quantity:
                                      </span>{" "}
                                      {product.minOrder}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div className="col-6">
                                <div className="ms-4">
                                  <div className="d-flex flex-column">
                                    <p>
                                      <span
                                        style={{
                                          color: "grey",
                                          marginRight: "5px",
                                          display: "inline",
                                        }}
                                      >
                                        Business Type:
                                      </span>{" "}
                                      {product.businessType}
                                    </p>
                                    <p>
                                      <span
                                        style={{
                                          color: "grey",
                                          marginRight: "5px",
                                          display: "inline",
                                        }}
                                      >
                                        Location:
                                      </span>{" "}
                                      {product.location}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <hr style={{ border: "1px solid" }} />
                          <div>
                            <p
                              className="fs-6 fw-bold ms-4 mt-2 text-warning"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "5px",
                                position: "relative",
                                cursor: "pointer",
                                color: "red",
                              }}
                              onMouseEnter={handleMouseEnter}
                              onMouseLeave={handleMouseLeave}
                              // key={catalogId}
                            >
                              {/* <Link
                                to={{
                                  pathname: `/catalog/${userProfileId}`,
                                  state: { productData: productData },
                                }}
                              > */}
                              <button onClick={handleButtonClick}>
                                {product.businessName}
                              </button>
                              {/* </Link> */}
                              <span
                                className="ms-2"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <GiCutDiamond style={{ marginRight: "7px" }} />{" "}
                                <GiCutDiamond style={{ marginRight: "7px" }} />{" "}
                                <GiCutDiamond style={{ marginRight: "7px" }} />
                              </span>
                            </p>
                            {isHovering && (
                              <div
                                className="model-tooltip"
                                style={{
                                  position: "absolute",
                                  top: "90%",
                                  left: "50%",
                                  transform: "translateX(-50%)",
                                  // backgroundColor: "#f9f9f9",
                                  padding: "10px",
                                  // border: "1px solid #ccc",
                                  // borderRadius: "5px",
                                  zIndex: 1,
                                  // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                }}
                              >
                                <div
                                  class="card border-light mb-3"
                                  style={{ maxWidth: "18rem" }}
                                >
                                  <div
                                    class="card-header bg-gradient-light border-light fw-bold text-center align-items-center"
                                    style={{ height: "8vh" }}
                                  >
                                    {product.businessName}
                                  </div>
                                  <div class="card-body text-dark">
                                    {matchingProfile && (
                                      <p class="card-text">
                                        <div className="row ">
                                          {" "}
                                          <p className="mb-0 d-flex align-items-center text-secondary">
                                            {/* <GiCutDiamond style={{ color: "red" }} /> */}
                                            <img
                                              src={gold}
                                              alt="gold"
                                              style={{ height: "5vh" }}
                                            />
                                            {matchingProfile.packageType}
                                            <span className="text-danger">
                                              {" "}
                                              since{matchingProfile.YearofEst}
                                            </span>
                                          </p>
                                          &nbsp;
                                          <p className="mb-0 d-flex align-items-center text-secondary">
                                            <img
                                              src={gold}
                                              alt="gold"
                                              style={{ height: "5vh" }}
                                            />
                                            &nbsp; Audited Member&nbsp;
                                          </p>
                                        </div>
                                      </p>
                                    )}
                                  </div>
                                  <div class="card-footer ">
                                    <p className="fw-bold">Business Type </p>
                                    {/* {matchingProfile.BusinesType} */}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-3 d-none d-md-block">
                          <div
                            className="row  rounded mt-5 me-1"
                            style={{ border: "1px solid silver" }}
                          >
                            <div className="mb-3">
                              <p className="pt-2 fs-5 fw-bold">
                                Contact Supplier
                              </p>
                              <div className="row align-items-center">
                                <div className="col-5 ps-3">
                                  <img
                                    style={{ height: "10vh", width: "30vh" }}
                                    src="http://cdn.shopify.com/s/files/1/0086/0795/7054/articles/Cat_s_Mind_x630.jpg?v=1624444348"
                                    alt="error"
                                    className="img-fluid"
                                  />
                                </div>
                                {matchingProfile && (
                                  <div className="col-7">
                                    <p className="mb-0 fs-5">
                                      {matchingProfile.firstname}
                                    </p>
                                    <p
                                      className="mt-0 fs-6 fw-bold"
                                      style={{ color: "grey" }}
                                    >
                                      {matchingProfile.BusinessType}
                                    </p>
                                  </div>
                                )}

                                <div className="col-12 d-flex justify-content-center mt-3">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-danger w-75"
                                    onClick={() => handleOpenModal(product._id)}
                                  >
                                    Contact Now
                                  </button>
                                </div>

                                <Modal
                                  show={showModal}
                                  onHide={handleCloseModal}
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>
                                      Get Enquiry for {product?.name}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    {/* Add your inquiry form or content here */}
                                    {/* For example, you can include an email input and a textarea */}
                                    <form onSubmit={handleSubmit}>
                                      <div className="mb-1">
                                        <label htmlFor="email">Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="name"
                                          name="name"
                                          value={formData.name}
                                          onChange={handleEnquiryChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <label htmlFor="email">
                                          Email address
                                        </label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          id="email"
                                          name="email"
                                          value={formData.email}
                                          onChange={handleEnquiryChange}
                                        />
                                      </div>
                                      <div className="mb-3">
                                        <label htmlFor="email">
                                          Phone Number
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="phone"
                                          name="phone"
                                          value={formData.phone}
                                          onChange={handleEnquiryChange}
                                        />
                                      </div>

                                      <Button variant="primary" type="submit">
                                        Send Enquiry
                                      </Button>
                                    </form>
                                  </Modal.Body>
                                </Modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No products found.</p>
                  )}
                </div>
              )}

              {/********************** Menu Design *********************/}

              {currentDesign === "menu" && (
                <div className="row">
                  {productData?.results && productData.results.length > 0 ? (
                    productData.results.map((product) => (
                      <div className="col-md-3 " key={product._id}>
                        <div
                          className={`card ${
                            expandedCardId === product._id ? "expanded" : ""
                          }`}
                          style={{
                            transition: "height 0.3s",
                            position: "relative",
                            overflow: "hidden",
                          }}
                          onMouseEnter={() => handleCardMouseEnter(product._id)}
                          onMouseLeave={handleCardMouseLeave}
                        >
                          <Carousel
                            responsive={responsive}
                            keyBoardControl={false}
                            containerClass="carousel-container"
                          >
                            {product.productPictures &&
                            product.productPictures.length > 0 ? (
                              product.productPictures.map((picture, index) => (
                                <div
                                  className="my-3 mx-3 container-row-column-two-div"
                                  key={picture._id}
                                >
                                  <img
                                    style={{ height: "35vh", width: "70vh" }}
                                    src={`https://demo.businessworldtrade.com/${picture.img}`}
                                    alt={`Product ${index}`}
                                    className="img-fluid carousel-img"
                                  />
                                </div>
                              ))
                            ) : (
                              <div className="my-3 mx-3 container-row-column-two-div">
                                <img
                                  style={{ height: "35vh", width: "70vh" }}
                                  src=""
                                  alt="NoImgFound"
                                  className="img-fluid carousel-img"
                                />
                              </div>
                            )}
                          </Carousel>
                          {matchingProfile && (
                            <div className="ribbon-2 fw-bold text-white text-center">
                              {matchingProfile.packageType}
                            </div>
                          )}

                          <div className="card-body">
                            <h5 className="card-title">
                              {" "}
                              <div
                                class="card-header bg-gradient-light border-light fw-bold  fs-6 text-start align-items-center"
                                style={{ height: "auto" }}
                              >
                                {/* <GiCutDiamond style={{ marginRight: "5px" }} size={25} /> */}
                                {product.name}
                              </div>
                            </h5>
                            <p className="card-text">
                              {" "}
                              <p>
                                <span
                                  style={{
                                    color: "grey",
                                    marginRight: "5px",
                                    display: "inline",
                                  }}
                                >
                                  Min. Order:
                                </span>{" "}
                                {product.minOrder}
                              </p>
                            </p>
                            <p
                              className="fs-6 fw-bold ms-4 mt-2 text-warning"
                              style={{
                                display: "flex",
                                alignItems: "start",
                                marginTop: "5px",
                                position: "relative",
                                cursor: "pointer",
                                color: "red",
                              }}
                            >
                              {product.businessName}{" "}
                              <span
                                className="ms-2"
                                style={{ display: "flex", alignItems: "start" }}
                              >
                                <GiCutDiamond style={{ marginRight: "7px" }} />{" "}
                                <GiCutDiamond style={{ marginRight: "7px" }} />{" "}
                                <GiCutDiamond style={{ marginRight: "7px" }} />
                              </span>
                            </p>
                            <p className="card-text">
                              <small className="text-muted">
                                <div className="col-12 d-flex justify-content-center mt-3">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-danger w-75"
                                    onClick={() => handleOpenModal(product._id)}
                                  >
                                    Contact Now
                                  </button>
                                </div>
                              </small>

                              <Modal
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                show={showModal}
                                onHide={handleCloseModal}
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    Sent Enquiry For {product.name}
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <form onSubmit={handleSubmit}>
                                    <div className="mb-1">
                                      <label htmlFor="email">Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleEnquiryChange}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label htmlFor="email">
                                        Email address
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleEnquiryChange}
                                      />
                                    </div>
                                    <div className="mb-3">
                                      <label htmlFor="email">
                                        Phone Number
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="phone"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleEnquiryChange}
                                      />
                                    </div>

                                    <Button variant="primary" type="submit">
                                      Send Enquiry
                                    </Button>
                                  </form>
                                </Modal.Body>
                                {/* <Modal.Footer>
                                  <Button
                                    variant="secondary"
                                    onClick={handleCloseModal}
                                  >
                                    Close
                                  </Button>
                                  <Button variant="primary">Send</Button>
                                </Modal.Footer> */}
                              </Modal>
                            </p>
                          </div>
                          {expandedCardId === product._id && (
                            <div
                              className="card-hover-text"
                              style={{
                                padding: "10px",

                                borderRadius: "5px",
                              }}
                            >
                              {/* Add your text content to show on hover */}
                              <div className="row">
                                <div className="col-12">
                                  <div className="ms-4">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="d-flex flex-column">
                                          <p className="mb-1">
                                            <span
                                              style={{
                                                color: "grey",
                                                marginRight: "3px",
                                                display: "inline",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Price:
                                            </span>{" "}
                                            {product.price}
                                          </p>
                                          <p className="mb-1">
                                            <span
                                              style={{
                                                color: "grey",
                                                marginRight: "3px",
                                                display: "inline",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Quantity:
                                            </span>{" "}
                                            {product.quantity}
                                          </p>
                                          <p className="mb-1">
                                            <span
                                              style={{
                                                color: "grey",
                                                marginRight: "3px",
                                                display: "inline",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Min.Order Quantity:
                                            </span>{" "}
                                            {product.minOrder}
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="d-flex flex-column">
                                          <p className="mb-1">
                                            <span
                                              style={{
                                                color: "grey",
                                                marginRight: "3px",
                                                display: "inline",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Business Type:
                                            </span>{" "}
                                            {product.businessType}
                                          </p>
                                          <p className="mb-1">
                                            <span
                                              style={{
                                                color: "grey",
                                                marginRight: "3px",
                                                display: "inline",
                                                fontSize: "15px",
                                              }}
                                            >
                                              Location
                                            </span>{" "}
                                            {product.location}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No products found.</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SearchProductpage;
